import store from '~/store';

export default async function scope({router, params }) {
    let user = store.getters['auth/user'];

    for (let i = 0; i < params.length; i++){
        if(!user.scopes.includes(params[i])){
            return redirectAccordingToScope(user, router);
        }
    }

    return true;
}

function redirectAccordingToScope(user, router){
    if(user.scopes.includes('deactivated_by_user')){
        router.push({name: 'auth-activate-account'});
        return false;
    }

    if(user.scopes.includes('change_password')){
        router.push({name: 'auth-change-password'});
        return false;
    }

    if(user.scopes.includes('fill_company_details')){
        router.push({name: 'auth-company-details'});
        return false;
    }

    if(user.scopes.includes('give_registration_consent')){
        router.push({name: 'auth-consent'});
        return false;
    }

    if(user.scopes.includes('configure_two_factor')){
        router.push({name: 'auth-configure-two-factor'});
        return false;
    }

    if(user.scopes.includes('set_two_factor_token')){
        router.push({name: 'auth-token'});
        return false;
    }

    if(user.scopes.includes('configure_payment')){
        router.push({name: 'auth-configure-payment'});
        return false;
    }

    if(user.scopes.includes('no_active_payment')){
        router.push({name: 'auth-no-active-subscription'});
        return false;
    }

    if(user.scopes.includes('access_all')){
        router.push({name: 'home'});
        return false;
    }
}
