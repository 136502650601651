import Vue              from 'vue';
import VueI18n          from 'vue-i18n';
import SystemService    from "~/services/SystemService";
import axios            from 'axios';
import messages         from '~/en.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en',
    messages: {
        'en': messages
    },
});

const loadedLanguages = ['en']; // our default language that is preloaded

function setI18nLanguage (lang) {
    i18n.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);
    // localStorage.setItem('lang', lang);
    // store.commit('lang/'+types.SET_CURRENT_LANGUAGE, { currentLanguage: lang });
    return lang;
}

export async function loadLanguage(lang, changeDefault = true){
    let systemService = new SystemService();

    let messages = await systemService.getLanguageTranslations(lang);

    i18n.setLocaleMessage(lang, messages || []);
    loadedLanguages.push(lang);
    if(changeDefault){
        return setI18nLanguage(lang)
    }
}

export async function loadLanguageAsync(lang, changeDefault = true) {
    lang = lang.toLowerCase();
    // If the same language
    if (i18n.locale === lang) {
        if(changeDefault){
            return Promise.resolve(setI18nLanguage(lang))
        }

        return ;
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        if(changeDefault) {
            return Promise.resolve(setI18nLanguage(lang))
        }

        return ;
    }
    // If the language hasn't been loaded yet
    await loadLanguage(lang, changeDefault);
}

export default i18n;
