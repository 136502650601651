import store from '~/store';
import * as types from '~/store/mutation-types';

export default async function auth({ router }) {

    if (!localStorage.getItem('token')) {
        router.push({ name: 'auth-login' });
        return false;
    }

    if (!store.getters['auth/user']) {
        try {
            store.commit('auth/' + types.SET_TOKEN ,  { token: localStorage.getItem('token') });
            await store.dispatch('auth/getUser');
            await store.dispatch('echo/connect');
            return true;
        } catch (e) {
            return false;
        }
    }

    return true;
}
