<template>
    <div class="box_shadow suggested">
        <div class="d-flex justify-content-between align-items-center">
            <div class="w-100 d-flex align-items-center justify-content-center" v-if="headerText">
                <div class="col-md-7 p-0 d-flex align-items-center " :class="{'col-md-12' : !showTab}">
                    <h3 class="pageHeader " v-text-ellipsis :title="notTranslate ? headerText : (headerText)">{{ notTranslate ? headerText : (headerText) }}</h3>
                    <slot/>
                </div>
                <div class="px-0 col-md-5 pl-3" v-if="showTab">
                    <div class="text-right">
                        <nav class="d-inline-block">
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <span v-for="(navItem, index) in navLink" :key="index" class="nav-item nav-link my-1"
                                      :class="{'active show': navItem.active }" @click="$emit('set-tab', index)">
                                    {{!navItem.aditionalUrl ? navItem.item : ''}}
                                    <router-link :to="{name: navItem.aditionalUrl}" v-if="navItem.aditionalUrl" >
                                          {{navItem.item}}
                                    </router-link>
                                    <span class="additional-info" v-if="navItem.additionalInfo">{{ navItem.additionalInfo }}</span>
                                </span>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="w-100" v-if="customHeader">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageHeader',
        props: [
            'customHeader',
            'headerText',
            'notTranslate',
            'showTab',
            'navLink',
        ],
    }
</script>


<style scoped lang="scss">
    @import "@/abstracts/_variables";
    @import "@/abstracts/_mixins";

    .suggested {
        margin-bottom: 24px;
    }

    .nav.nav-tabs {
        border-bottom: 0;

        .nav-item.nav-link {
            color: rgba(82, 79, 99, 0.7);
            border: 1px solid rgba(82, 79, 99, 0.2);
            border-radius: 4px 0 0 4px;
            cursor: pointer;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding: 8px 16px;

            &.active {
                border-color: $secondColor;
                color: $secondColor!important;
            }
            .additional-info {
                color: inherit;
                margin-left: 10px;
            }

            &:last-of-type {
                border-radius: 0 4px 4px 0;
            }

            @include media(1030) {
                width: 100%;
                justify-content: center;
                text-align: center
            }
            @include media(992) {
                font-size: 14px;
                display: flex;
                align-items: center;
            }
        }
    }
</style>
