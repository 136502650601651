// Load middleware modules dynamically.
// const middlewares = resolveMiddleware(
//     require.context('~/middleware', false, /.*\.js$/)
// )
//
// function resolveMiddleware (requireContext) {
//     return requireContext.keys()
//         .map(file =>
//             [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
//         )
//         .reduce((guards, [name, guard]) => (
//             { ...guards, [name]: guard.default }
//         ), {})
// }
//
// export default middlewares


const req = require.context('~/middleware', false, /.*\.js$/);

req.keys().forEach((key) => {
    if(key.search('index') === -1){
        const componentName = key.replace(/(^.\/)|(\.js$)/g, '');
        module.exports[componentName] = req(key).default;
    }
});
