import store from '~/store'

const roles = {
    1: 'super_admin',
    2: 'admin',
    3: 'translator',
    4: 'franchise_admin',
    5: 'franchise_super_admin',
    6: 'franchise_translator',
    7: 'subscription_admin',
    8: 'subscription_super_admin',
    9: 'subscription_translator',
    10: 'company_admin',
    11: 'company_super_admin',
    12: 'company_translator',
    13: 'user',
};

export default async function role( {router, params}) {
    let type = String(store.getters['auth/user'].type);
    let role = String(store.getters['auth/user'].role);
    let filteredRoles = [];
    for (let i = 1; i < params.length; i++ ){
        if(roles.hasOwnProperty(params[i])){
            filteredRoles.push(roles[params[i]]);
        }
    }

    if(params[0] !== type || !filteredRoles.includes(role)){
        router.push({ name: type.replace('_', '-') + '-home' });
        return false;
    }

    return true;
}
