import HttpService from "./HttpService";

export default class UserService extends HttpService{

    /**
     * Get Users
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    allUsers(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('user/all', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(partners => partners.data).catch(err => err)
    }

    /**
     * Get user by id
     *
     * @param id
     * @returns {Promise<*>}
     */
     getUserById(id){
        let data = {
            id: id
        };

        return new  Promise( async (resolve, reject) => {
            try {
                let user = await this.getUser(data);
                resolve(user)
            } catch(error) {
                reject(error)
            }
        }).then(user => user).catch(err => err);
    }

    /**
     * Get authenticated user
     *
     * @returns {Promise<*>}
     */
    getAuthUser(){
        return new Promise(async (resolve, reject) => {
            try {
                let user = await this.get('get-auth-user', {}, {});
                resolve(user)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

    /**
     * Get user
     *
     * @param data
     * @returns {Promise<any>}
     */
    getUser(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('get-user', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

    /**
     * Get users list by company id
     *
     * @param id
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getUsersListByCompanyId(id, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('user/list-by-company-id/' + id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

    /**
     * Log out all users
     *
     * @param data
     * @returns {Promise<any>}
     */
    logOutAll(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('user-log-out-all', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

    /**
     * Log out all users
     *
     * @param params
     * @param headers
     * @returns {Promise<any>}
     */
    deactivate(params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('user/deactivate', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

    /**
     * Decline registration and communication consent
     *
     * @param params
     * @param headers
     * @returns {Promise<any>}
     */
    declineConsent(params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('user/consent/decline', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => [user.data, null]).catch(err => [null, err])
    }

    /**
     * Give registration and communication consent
     *
     * @param params
     * @param headers
     * @returns {Promise<any>}
     */
    giveConsent(params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/consent/give', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => [user.data, null]).catch(err => [null, err])
    }

    /**
     * Allow log in all users
     *
     * @param data
     * @returns {Promise<any>}
     */
    allowLogin(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('user-allow-log-in', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

    /**
     * Delete not verified email
     *
     * @param params
     * @param headers
     * @returns {Promise<*>}
     */
    deleteAccountNotVerifiedEmail( params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('user/delete/not-verified-email', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => [user.data, null]).catch(err => [null, err])
    }

    /**
     *
     * @param language
     * @param headers
     * @returns {Promise<any | never>}
     */
    setAccountLanguage(language, headers = {}){
        let params = {
            'language': language
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('set-account-language', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

    /**
     * Check whether email is free or not
     *
     *
     * @param params
     * @param headers
     * @returns {Promise<*>}
     */
    checkEmailIsFree(params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('email-is-free', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

    /**
     * Verify email
     *
     * @param token
     * @param headers
     * @returns {Promise<any | never>}
     */
    verifyEmail(params = {},  headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/email/reset', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

    /**
     *
     * @param headers
     * @returns {Promise<any | never>}
     */
    checkPassword(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/check-password', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

    /**
     * Change user password
     *
     * @param headers
     * @returns {Promise<any | never>}
     */
    changeProfilePassword(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/update/profile/password', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => [user.data, null]).catch(err => [null, err])
    }

    /**
     * Change user email
     *
     * @param headers
     * @returns {Promise<any | never>}
     */
    updateEmail(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/update-email', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

    /**
     * Change user email
     *
     * @param userId
     * @param params
     * @param headers
     * @returns {Promise<any | never>}
     */
    updateAccountEmail(userId, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/update/email/' + userId, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => [user.data, null]).catch(err => [null, err])
    }

    /**
     * Change account email
     *
     * @param userId
     * @param params
     * @param headers
     * @returns {Promise<any | never>}
     */
    updateAccountPassword(userId, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/update/password/' + userId, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => [user.data, null]).catch(err => [null, err])
    }

    /**
     * Update user image
     *
     * @param userId
     * @param params
     * @param headers
     * @returns {Promise<*>}
     */
    uploadAccountProfileImage(userId, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/update/profile-image/' + userId, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => [user.data, null]).catch(err => [null, err])
    }

    /**
     * Delete user image
     *
     * @param userId
     * @param params
     * @param headers
     * @returns {Promise<*>}
     */
    deleteAccountProfileImage(userId, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/delete/profile-image/' + userId);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

      /**
     * Update user account details
     *
     * @param accountId
     * @param headers
     * @returns {Promise<any | never>}
     */
    uploadPartnerProfileImage(accountId, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('partner/profile-image/' + accountId, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }

    /**
     * Update user account details
     *
     * @param userId
     * @param params
     * @param headers
     * @returns {Promise<any | never>}
     */
    saveAccountDetails(userId, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/update/account-details/' + userId, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => [user.data, null]).catch(err => [null, err])
    }

    /**
     * Change profile email
     *
     * @param data
     * @returns {Promise<any>}
     */
    uploadProfileImage(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('user/upload-profile-image', params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => result.data).catch(err => err)
    }

    /**
     * Update profile details
     *
     * @param data
     * @returns {Promise<any>}
     */
    updateProfile(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('user/update-profile', params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => result.data).catch(err => err)
    }

    /**
     * Toggle users status
     *
     * @param accountId
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    toggleStatus(accountId, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('user/toggle-status/' + accountId, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(result => [result.data, null]).catch(err =>  [null, err])
    }

    /**
     * Delete users
     *
     * @param id
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    delete(id, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get( 'user/delete/' + id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err =>  [null, err])
    }

    /**
     * Activet user
     *
     * @param id
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    activate(id, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get( 'user/activate/' + id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err =>  [null, err])
    }

 /**
     * Save users data
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    save(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('user/new', params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Update logged in user account details
     *
     * @param headers
     * @returns {Promise<any | never>}
     */
    saveProfileAccountDetails(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/update/profile/account-details', params, headers);
                resolve(data)
            } catch (error) {
                reject(error)
            }

        }).then(user => [user.data, null]).catch(err => [null, err])

    }


    /**
     * Update logged in user email
     *
     * @param headers
     * @returns {Promise<any | never>}
     */
    updateProfileEmail(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/update/profile/email', params, headers);
                resolve(data)
            } catch (error) {
                reject(error)
            }

        }).then(user => [user.data, null]).catch(err => [null, err])

    }
    /**
     * Get list of users for login as
     *
     * @param headers
     * @returns {Promise<any | never>}
     */
    getUsersForLoginAs(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('user/get/login-as', params, headers);
                resolve(data)
            } catch (error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)

    }

    /**
     * Get users deactivation reason
     *
     * @param headers
     * @returns {Promise<any | never>}
     */
    getDeactivationReason(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('user/deactivation-reason/get', params, headers);
                resolve(data)
            } catch (error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)

    }

    /**
     * Change Password
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    changePassword(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('user/password/change-one-time-password', params, headers);
                resolve(data)
            } catch (error) {
                reject(error)
            }

        }).then(categories => [categories.data, null]).catch(err => [null, err])
    }

    /**
     * Cancel account deactivation
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    cancelDeactivation(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('user/deactivation/cancel', params, headers);
                resolve(data)
            } catch (error) {
                reject(error)
            }

        }).then(categories => [categories.data, null]).catch(err => [null, err])
    }

    getNewAccessToken(params = {}, headers = {} ){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('user/generate-new-tokens', params, headers);
                resolve(data)
            } catch (error) {
                reject(error)
            }

        }).then(response => response.data).catch(err => err)
    }

    getProfileImage(url, params = {}, headers = {} ){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get(url, params, { responseType: 'arraybuffer' });
                resolve(data)
            } catch (error) {
                reject(error)
            }

        }).then(data => data).catch(err => err)
    }
}
