import Vue from "vue";

export const bus = new Vue();

var fullPageLoad = function (showOrHide) {
    bus.$emit('showOrHideFullPageLoader', showOrHide);
};

var fullPageLoadPrimary = function (showOrHide) {
    bus.$emit('showOrHideFullPagePrimaryLoader', showOrHide);
};

var withoutSidebarLoader = function (showOrHide) {
    bus.$emit('showOrHideWithoutSidebarLoader', showOrHide);
};

var settingsLoader = function (showOrHide) {
    bus.$emit('showOrHideSettingsLoader', showOrHide);
};

var login = function (showOrHide) {
    bus.$emit('showOrHideLoginLoader', showOrHide);
};

var tableLoader = function (id, showValue) {
    let loader = `<span class="load-icon m-auto">
                        <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.3429 6.26914C34.6493 8.94641 31.9141 10.5551 29.2363 9.8609C26.559 9.16669 24.9503 6.43268 25.6434 3.75318C26.3388 1.07647 29.0722 -0.532788 31.7506 0.161423C34.4284 0.855633 36.0377 3.59131 35.3429 6.26914Z" fill="#27AEDE"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0299 48.0486C21.6684 49.4409 20.2471 50.2764 18.8543 49.9165C17.4614 49.5549 16.6253 48.1342 16.9863 46.7403C17.3474 45.3468 18.7686 44.5124 20.1615 44.8729C21.5543 45.2328 22.3904 46.6551 22.0299 48.0486Z" fill="#27AEDE"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.76312 17.0233C6.79959 17.5517 8.02168 19.631 7.49435 21.6658C6.96702 23.7017 4.88884 24.9232 2.85293 24.3959C0.817585 23.8686 -0.405071 21.7904 0.122818 19.7545C0.650151 17.7202 2.72833 16.4976 4.76312 17.0233Z" fill="#27AEDE"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5436 30.3378C47.294 30.532 47.7445 31.2968 47.5493 32.0483C47.3552 32.7982 46.5898 33.2476 45.8399 33.0529C45.0901 32.8588 44.6401 32.0928 44.8342 31.3424C45.0294 30.5926 45.7943 30.1437 46.5436 30.3378Z" fill="#27AEDE"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.6036 3.87568C24.2839 6.32767 22.8443 8.8681 20.3923 9.54673C17.9392 10.2248 15.3999 8.78688 14.7207 6.33267C14.0421 3.87957 15.4806 1.34137 17.9337 0.662733C20.3857 -0.0164582 22.9261 1.42147 23.6036 3.87568Z" fill="#27AEDE"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.9623 46.7138C33.2866 47.8869 32.5991 49.1012 31.426 49.4266C30.2522 49.7509 29.0379 49.0639 28.7131 47.8897C28.3888 46.7165 29.0763 45.5022 30.2495 45.1779C31.4232 44.8531 32.6375 45.54 32.9623 46.7138Z" fill="#27AEDE"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.99342 28.7586C4.80626 28.2558 6.68308 29.3204 7.18482 31.1339C7.68657 32.9473 6.623 34.8235 4.81016 35.3247C2.9962 35.8276 1.11994 34.764 0.618195 32.9511C0.117006 31.1361 1.17946 29.2598 2.99342 28.7586Z" fill="#27AEDE"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3452 7.85104C14.0018 9.47976 14.0234 12.1459 12.3936 13.8019C10.7643 15.459 8.09985 15.4801 6.4422 13.8503C4.78567 12.2216 4.76397 9.55542 6.39436 7.89943C8.0242 6.24179 10.6881 6.21954 12.3452 7.85104Z" fill="#27AEDE"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M41.8713 40.2699C42.5811 40.968 42.5906 42.1105 41.8919 42.8192C41.1932 43.5295 40.0512 43.5395 39.3415 42.8414C38.6311 42.1422 38.6222 40.9997 39.3209 40.2904C40.0195 39.5807 41.161 39.5718 41.8713 40.2699Z" fill="#27AEDE"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50369 39.7007C8.66794 38.5176 10.5715 38.5014 11.7541 39.6662C12.9372 40.8311 12.9534 42.7329 11.7891 43.9177C10.6254 45.1009 8.7219 45.117 7.53874 43.9511C6.35502 42.7874 6.33944 40.8839 7.50369 39.7007Z" fill="#27AEDE"/>
                         </svg>
                    </span>`;
    if (showValue) {
        const div = document.createElement('div');
        document.getElementById(id).classList.add("loaderBlock");

        div.className = 'loading';

        div.id = 'loadBox';

        div.innerHTML = loader;

        if (!document.getElementById('loadBox')) {
            document.getElementById(id).appendChild(div);
        }

    } else if(document.getElementById('loadBox')){
        document.getElementById('loadBox').remove();
        document.getElementById(id).classList.remove("loaderBlock");
    }
};

var buttonLoader = function (id, value) {
    bus.$emit('buttonLoader', value);

    let loader = `<div class="w-100 d-flex justify-content-center align-items-center">
                        <div class="snippet" data-title=".dot-pulse">
                            <div class="stage">
                                <div class="dot-pulse"></div>
                            </div>
                        </div>
                    </div>`
    if (value) {
        document.getElementById(id).classList.add("position-relative");

        var node = document.createElement("div");

        node.id = 'loadingBtn';

        node.innerHTML = loader;
        node.className = 'loadingBtn';

        document.getElementById(id).appendChild(node);

        let overlay = document.createElement("div");

    } else {
        if (document.getElementById('loadingBtn')) {
            document.getElementById('loadingBtn').remove();
        }
    }

};

var cancelButtonLoader = function (id, value) {
    bus.$emit('buttonLoader', value);

    let loader = `<div class="w-100 d-flex justify-content-center align-items-center">
                        <div class="snippet" data-title=".dot-pulse">
                            <div class="stage">
                                <div class="dot-pulse"></div>
                            </div>
                        </div>
                    </div>`
    if (value) {
        document.getElementById(id).classList.add("position-relative");

        var node = document.createElement("div");

        node.id = 'loadingBtn';

        node.innerHTML = loader;
        node.className = 'cancelLoadingBtn';

        document.getElementById(id).appendChild(node);

        let overlay = document.createElement("div");

    } else {
        document.getElementById('loadingBtn').remove();
    }
};

var killAll = function () {
    Vue.prototype.$loader.fullPageLoad = () => false;
    Vue.prototype.$loader.fullPageLoadPrimary = () => false;
    Vue.prototype.$loader.withoutSidebarLoader = () => false;
    Vue.prototype.$loader.login = () => false;
    Vue.prototype.$loader.buttonLoader = () => false;
    Vue.prototype.$loader.settingsLoader = () => false;
    Vue.prototype.$loader.cancelButtonLoader = () => false;
    Vue.prototype.$loader.tableLoader = () => false;
}

const loader = {
    install(Vue, options) {
        Vue.prototype.$loader = {};
        Vue.prototype.$loader.fullPageLoad = fullPageLoad;
        Vue.prototype.$loader.fullPageLoadPrimary = fullPageLoadPrimary;
        Vue.prototype.$loader.settingsLoader = settingsLoader;
        Vue.prototype.$loader.withoutSidebarLoader = withoutSidebarLoader;
        Vue.prototype.$loader.login = login;
        Vue.prototype.$loader.buttonLoader = buttonLoader;
        Vue.prototype.$loader.cancelButtonLoader = cancelButtonLoader;
        Vue.prototype.$loader.tableLoader = tableLoader;
        Vue.prototype.$loader.killAll = killAll;
    },
};

export default loader;


