<template>
    <header>
        <div class="user-burger">
            <div class="burger-icon" @click="openMobileMenu">
                <span class="burger-icon__item"></span>
                <span class="burger-icon__item"></span>
                <span class="burger-icon__item"></span>
            </div>
            <div :class="{'active_style':userInfo}" class="user-tools">
                <div class="user-info">
                    <img :class="{'avatars_border':user.profileImage}" :src="profileImageFile" class="user-info__img" alt="">
                    <div class="info-dpr">
                    </div>
                </div>
                <div class="notification">
                    <div class="notification-dpr w-100">
                        <div class="dropdown b-dropdown show btn-group position-relative w-100"
                             v-click-outside="() => userInfo = false">
                            <button class="btn dropdown-toggle btn-white dropdown-toggle-no-caret p-0 justify-content-between"
                                    @click="() => userInfo = !userInfo">
                                <span style="color: #524F63; max-width: 435px;" v-text-ellipsis>{{user.first_name + ' ' + user.last_name}}</span>
                                <span :class="{'transform':userInfo}" v-html="svg('arrow_blue')"/>
                            </button>
                            <ul class=" dropdown-menu show position-absolute" v-if="userInfo">
                                <li>
                                    <p class="profile__type mb-0 dropdown-item">{{ $t('profile_type') }}:
                                        <span>{{ userRole }}</span>
                                    </p>
                                </li>
                                <hr>
                                <li @click="() => userInfo = false">
                                    <router-link :to="{ name: user.type.replace('_', '-') + '-profile-edit'}"
                                                 class="mb-2 pr-0 dropdown-item">
                                    <span v-html="svg('navbarSettingsIcon')" class="mr-1">
                                    </span><span>{{ $t('settings') }}</span>
                                    </router-link>
                                </li>
                                <li>
                                    <a class="mb-3 py-1 pr-0 cursor-pointer dropdown-item">
                                        <div @click="logout()">
                                            <span v-html="svg('navbarLogOutIcon')" class="mx-1"></span>
                                            <span class="logOut-text">{{ $t('log_out') }}</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-tools">
            <div v-if="user.type !== 'admin' && companyLogo" class="company__logo">
                <img :src="companyLogo" alt="">
            </div>
            <div v-else>
                <img :src="'/images/company_logo_icon.svg'">
            </div>

            <div class="right-tools__language dropdown" v-click-outside="() => this.showLanguages = false">
                <div class="d-flex align-items-center right-tools__content" data-toggle="dropdown" @click="toggleShowLanguages">
                    <div class="languageIcon">
                        <span v-html="svg('navbarLanguageIcon')"></span>
                    </div>
                    <p class="langCode">{{ user.language.iso3.charAt(0).toUpperCase() + user.language.iso3.slice(1)
                        }}</p>
                    <span :class="{'transform':showLanguages}" v-html="svg('arrow_blue')"/>
                </div>


                <div class=" switchLnaguage" :class="{'d-block':showLanguages}">
                    <a class="dropdown-item change_language d-block"
                       v-text-ellipsis
                       :title="availableLanguage.full_name.charAt(0).toUpperCase() + availableLanguage.full_name.slice(1)"
                       id="setLang"
                       :class="{'active': availableLanguage.name == currentLanguage }"
                       v-for="availableLanguage in availableLanguages"
                       @click="setLanguage(availableLanguage.name)">
                        {{ availableLanguage.full_name.charAt(0).toUpperCase() + availableLanguage.full_name.slice(1) }}
                    </a>
                </div>
            </div>

            <Notifications/>

            <!-- todo add FAQ icon bellow -->

            <!--            <div v-if="['user', 'company_admin'].includes(user.type)" class="help__icon">-->
            <!--                <a href="/contact-us" target="_blank">-->
            <!--                    <span v-html="svg('navbarHelpIcon')"></span>-->
            <!--                </a>-->
            <!--            </div>-->

        </div>
    </header>
</template>

<script>
    import Notifications from '~/components/Notifications';
    import UserService from "~/services/UserService";
    import AuthService from "~/services/AuthService";
    import {loadLanguageAsync} from '~/setup/i18n-setup';
    import * as mutationTypes from '~/store/mutation-types';
    import {mapGetters} from "vuex";
    import ClickOutside from 'vue-click-outside';
    import ImageService from "~/services/ImageService";

    export default {
        name: "Navbar",

        data() {
            return {
                ImageService: new ImageService(),
                UserService: new UserService(),
                AuthService: new AuthService(),
                showSettings: false,
                showLanguages: false,
                showLogOutAll: false,
                fullscreen: false,
                agreeTerms: false,
                showTermsError: false,
                mobileMenu: false,
                userInfo: false,
                selectedFlag: '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                    '<path d="M-14.666 0H45.494V30.08H-14.666V0Z" fill="#012169"/>\n' +
                    '<path d="M-14.666 0V3.3605L38.7671 30.08H45.494V26.7195L-7.93914 0H-14.666ZM45.494 0V3.3605L-7.93914 30.08H-14.666V26.7195L38.7671 0H45.494Z" fill="white"/>\n' +
                    '<path d="M10.4026 0V30.08H20.4254V0H10.4026ZM-14.666 10.0286V20.0514H45.494V10.0286H-14.666Z" fill="white"/>\n' +
                    '<path d="M-14.666 12.032V18.048H45.494V12.032H-14.666ZM12.406 0V30.08H18.422V0H12.406ZM-14.666 30.08L5.38535 20.0514H9.87385L-10.1834 30.08H-14.666ZM-14.666 0L5.38535 10.0286H0.90273L-14.666 2.24425V0ZM20.96 10.0286L41.0113 0H45.494L25.4426 10.0286H20.96ZM45.494 30.08L25.4426 20.0514H29.9252L45.494 27.8357V30.08Z" fill="#C8102E"/>\n' +
                    '</svg>\n',
            }
        },
        props: [
            ''
        ],

        computed: {
            ...mapGetters({
                user: 'auth/user',
                currentLanguage: 'lang/currentLanguage',
                availableLanguages: 'lang/availableLanguages',
                logos: 'logo/logos',
                companyLogo: 'image/companyLogo'
            }),

            profileImageFile: function () {
                if (this.user.profileImage) {
                    let rand = Math.floor(Math.random() * 100);

                    return '/storage/profile-image/' + this.user.id + '/' + this.user.profileImage + '?version=' + rand
                }
                return "/images/user-icon.jpg";
            },

            homeUrl: function () {
                return this.user.type.replace('_', '-') + '-home'
            },

            userRole: function () {
                switch (this.user.role) {
                    case 'admin':
                        return this.$t('admin');
                        break;
                    case 'super_admin':
                        return this.$t('superadmin');
                        break;
                    case 'franchise_super_admin':
                    case 'franchise_admin':
                        return this.$t('franchise_partner_admin');
                        break;
                    case 'subscription_admin':
                    case 'subscription_super_admin':
                        return this.$t('subscription_partner_admin');
                        break;
                    case 'company_super_admin':
                        return this.$t('company_superadmin');
                        break;
                    case 'company_admin':
                        return this.$t('company_admin');
                        break;
                    case 'translator':
                    case 'franchise_translator':
                    case 'subscription_translator':
                    case 'company_translator':
                        return this.$t('translator');
                        break;
                    case 'user':
                        return this.$t('user');
                        break;
                }
            },
        },

        methods: {
            toggleShowSettings: function () {
                this.showSettings = !this.showSettings
            },

            toggleShowLanguages: function () {
                this.showLanguages = !this.showLanguages
            },

            toggleFullScreen: function () {
                this.$fullscreen.toggle(document.getElementById('app'), {
                    wrap: false,
                    callback: () => this.fullscreen = fullscreen
                })
            },

            logout: async function () {
                this.userInfo = false;

                this.$loader.fullPageLoadPrimary(true);

                let [result, error] = await this.AuthService.logout();

                this.$loader.fullPageLoadPrimary(false);

                if (result) {
                    await this.$store.dispatch('auth/logout');
                    this.$toast.clear();
                    this.$router.push({name: 'auth-login'});
                } else {
                    this.$toast.error(this.$t('something_went_wrong'));
                }
            },

            setLanguage: async function (lang) {
                await this.UserService.setAccountLanguage(lang);
                window.location.reload();
            },

            showLogOutAllModal: function () {
                this.showSettings = false;
                this.agreeTerms = false;
                this.showLogOutAll = false;
            },

            logOutAll: async function (e) {
                if (!this.agreeTerms) {
                    this.showTermsError = true;
                    return;
                }

                let result = await this.UserService.logOutAll();
                if (result) {
                    this.$refs['close-modal'].click();
                    this.user.allowLogin = false;
                    this.$store.commit('auth/' + mutationTypes.SET_USER, {user: this.user});
                    this.$toast.success(this.$t('logged_out_success'));
                }
            },

            allowLogInAll: async function (e) {
                let result = await this.UserService.allowLogin();
                if (result) {
                    this.user.allowLogin = true;
                    this.$store.commit('auth/' + mutationTypes.SET_USER, {user: this.user});
                    this.$toast.success(this.$t('allowed_log_in_success'));
                }
            },

            openMobileMenu: function () {
                this.$emit('mobile-menu');
            }
        },

        components: {
            Notifications
        },

        directives: {
            ClickOutside
        }
    }
</script>
<style scoped lang="scss">
    @import "@/abstracts/_variables";
    @import "@/abstracts/_mixins";

    .logOut {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;

        &-text {
            color: #524F63;
            font-weight: 500;
            font-size: 14px;
            cursor: pointer;
        }
    }

    .content header {
        .user-burger {
            .user-tools {
                .notification-dpr {
                    position: relative;

                    .dropdown-menu {
                        position: absolute !important;
                        left: auto !important;
                        right: -31px !important;
                        transform: inherit !important;
                        top: 35px !important;

                        @include media(1300) {
                            left: 0 !important;
                            max-width: 400px;
                            &:after, &:before {
                                right: auto;
                                left: 4%;
                            }
                        }
                    }
                }

                .transform {
                    transform: rotate(180deg);
                    display: flex;
                }
            }
            .active_style {
                border: 1px solid rgba(64, 146, 196, 0.7);
            }
        }

        .right-tools {
            &__language {
                .languageIcon {

                }

                .langCode {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    margin: 0 20px 0 10px;
                }

                .switchLnaguage {
                    width: 140px;
                    background: #FFFFFF;
                    box-shadow: 0px 1px 10px rgb(64 146 196 / 16%);
                    border-radius: 4px;
                    border: none;
                    padding: 15px 0;
                    margin-top: -15px;
                    margin-left: -15px;
                    display: none;
                    position: absolute;
                    top: 88px;
                    z-index: 99;

                    &::before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 9px solid rgba(148, 148, 148, 0.05);
                        position: absolute;
                        top: -10px;
                        right: 22px;
                    }

                    &::after {
                        content: '';
                        width: 10px;
                        height: 10px;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-bottom: 8px solid #fff;
                        position: absolute;
                        top: -10px;
                        right: 22px;
                    }
                    a{
                        color: $mainColor;
                    }
                    .dropdown-item.active {
                        background: rgba(64, 146, 196, 0.1);
                        border-left: 5px solid #4092C4;
                        text-decoration: none!important;
                    }
                }

                .transform {
                    transform: rotate(180deg);
                    display: flex;
                }

                .change_language {
                    display: flex;
                    align-items: center;
                    padding: 8px 15px !important;
                    border-left: 5px solid #fff;

                    .menuFlag {
                        width: 24px;
                        height: 24px;
                        margin-right: 7px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

</style>
