import store from '~/store';
import * as types from '~/store/mutation-types';

export default async function lang() {

    if (!store.getters['lang/availableLanguages']) {
        try {
            await store.dispatch('lang/getAvailableLanguages');
        } catch (e) {
            return false;
        }
    }

    if (!localStorage.getItem('token')) {
        let langName = localStorage.getItem('lang');
        if(!langName){
           langName = navigator.language.replace(/-[a-zA-Z]+/i, '');
        }

        store.commit('lang/'+types.SET_CURRENT_LANGUAGE, { currentLanguage: langName });
    }

    return true;
}
