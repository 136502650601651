import * as types from '~/store/mutation-types';
import LanguageService from "~/services/LanguageService";
import {loadLanguageAsync} from '~/setup/i18n-setup';

// state
export const state = {
    availableLanguages: null,
    currentLanguage: null,
};

// getters
export const getters = {
    availableLanguages: state => state.availableLanguages,
    currentLanguage: state => state.currentLanguage
};

// mutations
export const mutations = {
    [types.SET_AVAILABLE_LANGUAGES] : (state, data) => { state.availableLanguages = data.availableLanguages},
    [types.SET_CURRENT_LANGUAGE] : async (state, data) => { state.currentLanguage = data.currentLanguage; await loadLanguageAsync(state.currentLanguage); }
};

// actions
export const actions = {
    async getAvailableLanguages ({commit}) {
        try {
            let languageService = new LanguageService();
            const availableLanguages = await languageService.getAvailableLanguages();
            commit(types.SET_AVAILABLE_LANGUAGES, { availableLanguages: availableLanguages })
        }catch (e) {
            console.log('error')
        }
    },
}
