import store from '~/store'

const accessLevels = [
     'access_all',
     'view_only',
     'no_prefilled_records',
];

export default async function accessLevel( {router, params}) {
    let user = store.getters['auth/user'];

    if(!params.includes(user.access_level)){
        router.push({name: 'home'});
        return false;
    }

    return true;
}
