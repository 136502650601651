import HttpService from "./HttpService";

export default class AuthService extends HttpService {


    /**
     * Registration
     *
     * @param params
     * @param headers
     */
    registration(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('auth/register', params, headers, {}, false);
                resolve(result)
            } catch (error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }


    /**
     * Registration Invited
     *
     * @param params
     * @param headers
     */
    acceptInvitation(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('auth/invitation/accept', params, headers, {}, false);
                resolve(result)
            } catch (error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }


    /**
     * Registration Request
     *
     * @param params
     * @param headers
     */
    registrationRequest(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('auth/registration/request', params, headers, {}, false);
                resolve(result)
            } catch (error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Check Domain
     *
     * @param params
     * @param headers
     */
    checkDomain(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('auth/check/email', params, headers, {}, false);
                resolve(result)
            } catch (error) {
                reject(error)
            }

        }).then(result => result.data).catch(err => err)
    }

    /**
     * Login
     *
     * @param params
     * @param headers
     */
    login(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('auth/login', params, headers, {}, false);
                resolve(result)
            } catch (error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Logout
     *
     * @param params
     * @param headers
     */
    logout(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('auth/logout', params, headers);
                resolve(result)
            } catch (error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Send reset email
     *
     * @param params
     * @param headers
     */
    sendResetEmail(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('auth/password/email', params, headers, {}, false);
                resolve(result)
            } catch (error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Reset password
     *
     * @param params
     * @param headers
     */
    resetPassword(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('auth/password/reset', params, headers, {}, false);
                resolve(result)
            } catch (error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Get all categories
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getCategories(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('get-categories', params, headers);
                resolve(data)
            } catch (error) {
                reject(error)
            }

        }).then(categories => categories.data).catch(err => err)
    }

    /**
     * get Invited User data
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getInvitedUser(params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('invitation/get', params, headers, {}, false);
                resolve(data)
            } catch (error) {
                reject(error)
            }

        }).then(categories => categories.data).catch(err => err)
    }

    /**
     * Simulate user account
     *
     * @param token
     * @param params
     * @param headers
     * @returns {Promise<*>}
     */
    loginAs(token, params = {}, headers = {}) {

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('auth/login_as/login/' + token, params, headers, {}, false);
                resolve(data)
            } catch (error) {
                reject(error)
            }

        }).then(categories => categories.data).catch(err => err)
    }

    checkIsEmailRegistered(params = {}, headers = {} ){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('auth/email-registered', params, headers);
                resolve(data)
            } catch (error) {
                reject(error)
            }

        }).then(categories => categories.data).catch(err => err)
    }
}
