<template>
    <div class="table_pagination w-100">
        <div v-if="!showRow" class="page-input filter-per-page d-flex">
            <label for="showing" class="per-page mr-2">{{ $t('show_rows') }}</label>

            <input
                id="per_page"
                class="pagination-input"
                v-model="perPage"
                @input="filterValue"
                @change="setPerPage"
                type="text"
            />
        </div>
        <div v-if="count > 1" class="pagination m-0">
            <paginate
                @update-data="data = $event"
                v-model="currentPage"
                :pageCount="count"
                :click-handler="onPageChange"
                :prev-text="prevStatus"
                :next-text="nextStatus"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                next-class="next-class"
                active-class="active"
            >
            </paginate>
            <div class="page-input filter-per-page w-auto ml-2" v-if="!withoutInput">
                <label for="goTo" class="per-page mr-2">{{$t('go_to')}}:</label>
                <input
                    id="goTo"
                    class="pagination-input"
                    v-model="pageNum"
                    @input="filterValue"
                    @keyup.enter="setCurrentPage"
                    type="text"
                    :placeholder="pageNum"/>
            </div>
        </div>
    </div>
</template>

<script>
    import svg from "~/svg";
    import Paginate from 'vuejs-paginate'

    export default {
        name: "Pagination",

        props: [
            'data',
            'withoutInput',
            'showRow'
        ],

        created() {
            if (this.data.current_page > this.count) {
                this.$parent.onPageChange(this.count);
            }
        },

        components: {
            'paginate': Paginate
        },

        computed: {
            currentPageModel: function () {
                return parseInt(this.currentPage);
            },

            prevStatus: function () {
                return this.prevActive ? this.prevTextActive : this.prevText
            },

            nextStatus: function () {
                return this.nextActive ? this.nextTextActive : this.nextText
            }
        },

        data() {
            return {
                count: this.data.last_page,
                currentPage: parseInt(this.data.current_page),
                prevActive: this.data.current_page != 1,
                nextActive: this.data.current_page != this.data.last_page,
                pageNum: null,
                perPage: this.data.per_page,

                nextText: "<div class='page_pre'><span>" + svg('nextText') + "</span></div>",
                nextTextActive: "<div class='page_pre active'><span>" + svg('nextText') + "</span></div>",

                prevText: "<div class='page_pre'><span>" + svg('prevText') + "</span></div>",
                prevTextActive: "<div class='page_pre active'><span>" + svg('prevText') + "</span></div>",
            }
        },

        methods: {
            onPageChange(pageNum) {
                this.prevActive = pageNum !== 1;
                this.currentPage = pageNum;
                this.nextActive = pageNum < this.count;
                this.$parent.onPageChange(pageNum);
            },

            filterValue: function ($event) {
                let value = $event.target.value;

                switch ($event.target.id) {
                    case 'goTo':
                        this.pageNum = value.replace(/[^\d]+|^0/g, '');
                        break;
                    case 'per_page':
                        this.perPage = value.replace(/[^\d]+|^0/g, '');
                        break;
                }
            },

            setPerPage: function () {
                if (this.perPage > 50) {
                    this.perPage = 50;
                }

                this.$emit('set-filter-data', 'per_page', this.perPage)
            },

            setCurrentPage: function ($event) {
                let value = $event.target.value;
                this.pageNum = value.replace(/[^\d]+|^0/g, '');

                if (!isNaN(parseInt(this.pageNum))) {
                    this.currentPage = parseInt(this.pageNum);

                    if (this.currentPage > this.count) {
                        this.pageNum = '';
                        this.onPageChange(this.count);
                        return 0;
                    }

                    this.onPageChange(this.currentPage);
                }
            }
        },

        watch: {
            currentPage: function () {
                this.prevActive = this.currentPage != 1;
                this.nextActive = this.currentPage != this.count;
            }
        }
    }
</script>
<style lang="scss">
    @import "@/abstracts/_variables";

    .table_pagination {
        padding: 20px 0;
        .page-item.active .page-link {
            background: transparent;
            color: $mainColor;
        }

        .page-item.disabled .page-link {
            background: transparent;
        }

        .filter-per-page {
            width: 50%;
            align-items: center;

            .per-page {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: rgba(82, 79, 99, 0.8);
                display: flex;
                align-items: center;
                margin-bottom: 0;
            }
        }

        .pagination {
            width: 50%;

            .disabled {
                background: transparent !important;

                .page_pre {
                    span {
                        svg {
                            opacity: 0.5;
                        }
                    }
                }
            }

            .page-item {
                a {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(82, 79, 99, 0.8);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &.active .page-link {
                    background: #27AEDE;
                    border-radius: 2px;
                    width: 30px;
                    height: 30px;
                    opacity: 1;
                    color: #fff;
                    margin: 0 8px;

                }

                &.disabled {
                    all: initial !important;
                }
            }

            .page_pre {
                span {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #F4F5F8;
                    border-radius: 2px;
                }
            }
        }
    }

</style>
