/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from 'vue';
import router from "~/routes";
import store from "~/store";
import App from "~/components/App";
import i18n from "~/setup/i18n-setup";
import Vuelidate from 'vuelidate';
import moment from 'moment';

/*---------- CUSTOM PLUGINS ----------*/
import trans from '~/plugins/transalation';
import toast from '~/plugins/toast';
import loader from '~/plugins/loader';

/*---------- BOOTSTRAP ----------*/
import { ModalPlugin, BAlert, BDropdown, BDropdownItem, BCollapse, BCard, BCardBody, BCardHeader, BModal, VBToggle, BButton} from 'bootstrap-vue'
Vue.use(ModalPlugin)
Vue.component('b-alert', BAlert)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-collapse', BCollapse)
Vue.component('b-card', BCard)
Vue.component('b-card-body', BCardBody)
Vue.component('b-card-header', BCardHeader)
Vue.component('b-card-header', BCardHeader)
Vue.component('b-modal', BModal)
Vue.component( 'b-button', BButton)
Vue.directive( 'b-toggle', VBToggle)


/*---------- TOASTMESSAGE ----------*/
import Snotify from 'vue-snotify';
import 'vue-snotify/styles/material.css';

//todo check this
import './filter.js';

/*---------- SELECT ----------*/
import { CoolSelectPlugin } from 'vue-cool-select';

import Donut        from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

require('./bootstrap');

Vue.prototype.svg = require('./svg');

import infiniteScroll from 'vue-infinite-scroll';
Vue.use(infiniteScroll);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

//import mixins
const mixins = require.context('./mixins', true, /\.js$/i);
mixins.keys().map(key => require('./mixins/' + key.split('/').pop()));

Vue.use(CoolSelectPlugin);
Vue.use(Vuelidate);
Vue.use(Snotify);
Vue.use(Donut);

Vue.use(toast);
Vue.use(loader);
Vue.use(trans);

Vue.prototype.$moment = moment;
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    router,
    store,
    i18n,
    ...App
});
