import * as types from '~/store/mutation-types';
import Echo from 'laravel-echo';
window.io = require('socket.io-client')

// state
export const state = {
    connectionInstance: null,
};

// getters
export const getters = {
    connectionInstance: state => state.connectionInstance,
};

// mutations
export const mutations = {
    [types.SET_CONNECTION] : (state, data) => { state.connectionInstance = data.connectionInstance},
};

// actions
export const actions = {

    async connect ({commit}) {

        let connection = new Echo({
            broadcaster: "socket.io",
            host: window.ENV.socketHost,
            authEndpoint: "/api/v2/broadcasting/auth",
            auth: {
                headers: {
                    /** I'm using access tokens to access  **/
                    Authorization: "Bearer " + localStorage.getItem('token')
                }
            }
        })

        commit(types.SET_CONNECTION, { connectionInstance: connection })
    },

    disconnect ({commit}, userId) {
        if(state.connectionInstance){
            state.connectionInstance.leave('active_online');
            state.connectionInstance.leave('private-user_' + userId);
            commit(types.SET_CONNECTION, { connectionInstance: null })
        }
    },
}


