import HttpService from "./HttpService";

export default class DomainService extends HttpService{

    /**
     * Get domains
     *
     * @param data
     * @returns {Promise<any>}
     */
    getDomains(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('domain/get', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(isVerified => isVerified.data).catch(err => err)
    }

    /**
     * Check verification status of company domains
     *
     * @param data
     * @returns {Promise<any>}
     */
    checkVerificationStatus(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('domain/check_verification', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Get user
     *
     * @param data
     * @returns {Promise<any>}
     */
    checkIsVerified(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('domain/is-verified', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(isVerified => isVerified.data).catch(err => err)
    }

    /**
     * Add domain
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    addDomain(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('domain/add', params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Update domain
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    updateDomain(id, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('domain/update/' + id, params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Delete domain
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    deleteDomain(id, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.get('domain/delete/' + id, params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Delete trusted domain
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    generateRecord(id, type, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('domain/generate-record/' + id + '/' + type, params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => result.data).catch(err => err)
    }

    /**
     *
     *
     * @param id
     * @param params
     * @param headers
     */
    approveInstructionsFollowed(id, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('domain/approve-instructions-followed/' + id, params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Verify domain
     *
     * @param id
     * @param params
     * @param headers
     */
    verify(id, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('domain/verify/' + id, params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Check whether domain is free or not
     *
     *
     * @param params
     * @param headers
     * @returns {Promise<*>}
     */
    checkDomainIsFree(params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('domain/is-free', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => user.data).catch(err => err)
    }
}
