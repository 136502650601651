<template>
    <div class="notifications" v-click-outside="() => modal = false">
        <div class="notifications-icon" @click="toggleShowNotifications">
            <span v-html="svg('navbarNotificationsIcon')"></span>
            <span class="num-count" v-if="notReadNotificationsCount">{{notReadNotificationsCount}}</span>
        </div>
        <Notification v-if="modal" :prefix="''" :modal="modal" @parentModalToggle="toggleShowNotifications"/>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import Notification from "./Notification";
    import {mapGetters, mapActions} from "vuex";
    import NotificationService from "~/services/NotificationService";

    export default {
        name: "Notifications",
        components: {Notification},

        data() {
            return {
                modal: false,
                notifications: [],
                NotificationService: new NotificationService(),
            }
        },

        mounted() {
            this['notifications/getUnreadNotificationsCount']();

            this.$store.getters['echo/connectionInstance'].private('user_' + this.user.id)
                .notification(async (notification) => {
                    this['notifications/getUnreadNotificationsCount']();
                });
        },

        methods: {
            ...mapActions(['notifications/getUnreadNotificationsCount']),

            toggleShowNotifications: async function () {
                this.modal = !this.modal;
                await this.NotificationService.markAsViewed();
                this['notifications/getUnreadNotificationsCount']();
            },

        },
        computed: {
            ...mapGetters({
                user: 'auth/user',
                notReadNotificationsCount: 'notifications/notifications'
            }),
        },
        directives: {
            ClickOutside
        }
    }
</script>

<style scoped lang="scss">

</style>
