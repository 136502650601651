import axios from 'axios';
import Router from 'vue-router';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

export default class HttpService {

    constructor() {
        this.interseptor = {};
    }

    /**
     * Get user
     *
     * @param data
     * @returns {Promise<any>}
     */
    get(url, params = {}, headers = {}, additionalHeaders = {}, requiresAuth = true){

        let authHeaders = {};
        if(requiresAuth){
            authHeaders = {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-Secondary-Token': localStorage.getItem('secondary_token'),
            }
        }
        headers = {...headers, ...authHeaders};
        url = '/api/v2/' + url;

        this.interseptor = axios.interceptors.response.use(
            response => response,
            error => this.handleUnauthorized(error)
        )

        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params,
                headers: headers,
                ...additionalHeaders
            })
                .then(response => resolve(response))
                .catch(error =>  reject(error));
        })
    }

    /**
     * Get user
     *
     * @param data
     * @returns {Promise<any>}
     */
     post(url, params = {}, headers = {}, additionalHeaders = {}, requiresAuth = true){

        let authHeaders = {};
        if(requiresAuth){
            authHeaders = {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-Secondary-Token': localStorage.getItem('secondary_token'),
            }
        }
        headers = {...headers, ...authHeaders};
        url = '/api/v2/' + url;

        this.interseptor = axios.interceptors.response.use(
            response => response,
            error => this.handleUnauthorized(error)
        )

        return new Promise((resolve, reject) => {
            axios.post(url, params, {
                headers: headers,
                ...additionalHeaders
            })
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    }

    /**
     * handle 401 error
     *
     * @param response
     */
    async handleUnauthorized(err){
        const originalRequest = err.config;

        if (err.response.status === 401 && !originalRequest._retry && originalRequest.url !== '/api/v2/get-new-access-token') {
            if (isRefreshing) {
                return new Promise(function(resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(function(resolve, reject) {
                axios
                    .get('/api/v2/get-new-access-token')
                    .then(({ data }) => {
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data;
                        originalRequest.headers['Authorization'] = 'Bearer ' + data;
                        processQueue(null, data);
                        localStorage.setItem('token', data);
                        resolve(axios(originalRequest));
                    })
                    .catch(err => {
                        processQueue(err, null);
                        localStorage.removeItem('token');
                        window.location.href = '/auth/login';
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }

        if (err) {
            switch (err.response.status) {
                case 404:
                    window.location.href = '/404';
                    return false;
                case 403:
                    window.location.href = '/403';
                    return false;
            }

            return Promise.reject(err);
        }

        return Promise.reject(err);
    }
}
