<template>
    <b-modal
            id="subscription-overdo"
            modal-class="limit_reached"
            :hide-footer="true"
            :hide-header="true"

        >
            <div style="margin: 15px 15px 0 0">
                    <div  @click="$bvModal.hide('subscription-overdo')" class="close_icon">
                        <img :src="'/images/smtpConfigImages/closeModal.svg'">
                    </div>
            </div>
                <div class="modal_body pt-0">
                    <span v-html="svg('warning3330')"></span>
                    <h4 class="mt-0">{{ $t('subscription_limit_reached') }}</h4>
                    <hr />
                    <h3>{{ $t('subscription_limit_reached_text') }}</h3>
                    <p v-if="['user', 'company_admin'].includes(user.role)">{{ $t('subscription_limit_reached_text_user') }}</p>
                    <p v-if="user.role === 'company_super_admin'">{{ $t('subscription_limit_reached_text_admin') }}</p>
                </div>
                <div class="modal_footer_area">
                    <button class="main-button main-button__save" @click="closeModal">{{ user.role === 'company_super_admin' ? $t('upgrade') : $t('ok')}}</button>
                </div>

    </b-modal>
</template>

<script>
    import {mapGetters} from "vuex";
    import LoginAsService from "~/services/LoginAsService";

    export default {
        name: 'SubscriptionOverdo',

        computed: {
            ...mapGetters({
                user: 'auth/user'
            }),
        },

        data() {
            return {}
        },

        mounted() {
            this.$root.$on('subscription-overdo', (param) => {
                this.$bvModal.show('subscription-overdo');
            })
        },

        methods: {
            closeModal: function () {
                this.$bvModal.hide('subscription-overdo');
                if (this.user.role === 'company_super_admin') {
                    this.$router.push({name: 'company-admin-subscription-change-the-plan'});
                }
            }
        }
    }
</script>
<style lang="scss">
    .limit_reached {
        .modal-dialog {
            max-width: 560px;
            .modal-body {
                padding: 0;
                .close_icon {
                    margin-left: auto;
                    width: 36px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    opacity: 60%;
                    cursor: pointer;
                    &:hover {
                        background: #F4F5F8;
                        opacity: 85%;
                    }
                }
                svg {
                    width: 50px;
                    height: 44px;
                    margin-bottom: 22px;
                }
                h4 {
                    letter-spacing: -0.01em;
                }
                hr {
                    width: 40px;
                    height: 2px;
                    border: none;
                    background-color: #27AEDE;
                    margin: 15px auto 30px;
                }
                .modal_body {
                    padding: 0 60px;
                    h3 {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(82, 79, 99, 0.8);
                        margin-bottom: 15px;
                    }

                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: rgba(82, 79, 99, 0.85);
                    }
                }

                .modal_footer_area {
                    margin: 30px auto 40px;

                    button {
                        min-width: 140px;
                        height: 40px;
                    }
                }
            }
        }
    }

</style>

