/*-------------- USER --------------*/
export const GET_TOKEN = 'GET_TOKEN';
export const SET_TOKEN = 'SET_TOKEN';
export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const SET_AFFECT_ON_LOGOUT = 'SET_AFFECT_ON_LOGOUT';

/*-------------- NOTIFICATIONS --------------*/
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

/*-------------- LANGUAGE --------------*/
export const SET_AVAILABLE_LANGUAGES = 'SET_AVAILABLE_LANGUAGES';
export const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';

/*-------------- LOGO --------------*/
export const SET_LOGOS = 'SET_LOGOS';

/*-------------- ECHO --------------*/
export const SET_CONNECTION = 'SET_CONNECTION';

/*-------------- IMAGE --------------*/
export const SET_PROFILE_IMAGE = 'SET_PROFILE_IMAGE';
export const SET_COMPANY_LOGO = 'SET_COMPANY_LOGO';

/*-------------- TRANSLATION --------------*/
export const SET_LOADED_LANGUAGES = 'SET_LOADED_LANGUAGES';
export const SET_MESSAGES = 'SET_MESSAGES';

/*-------------- LOGIN --------------*/
export const SET_EMAIL = 'SET_EMAIL';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_DETAILS = 'SET_DETAILS';

/*-------------- TIMEZONE --------------*/
export const SET_SERVER_TIMEZONE = 'SET_SERVER_TIMEZONE';
export const SET_BROWSER_TIMEZONE = 'SET_BROWSER_TIMEZONE';

/*-------------- SUBSCRIPTION --------------*/
export const SET_CURRENT_PLAN = 'SET_CURRENT_PLAN';
export const SET_SUBSCRIPTION_DETAILS = 'SET_SUBSCRIPTION_DETAILS';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';

/*-------------- LEGAL DOCUMENTS --------------*/
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_DOCUMENTS_EXISTS = 'SET_DOCUMENTS_EXISTS';

/*--------------EMAIL SETTINGS --------------*/
export const SET_SELECTED_HOST = 'SET_SELECTED_HOST';
