import * as types   from '~/store/mutation-types';

// state
export const state = {
    profileImage: null,
    companyLogo: null
};

// getters
export const getters = {
    profileImage: state => state.profileImage,
    companyLogo: state => state.companyLogo
};

// mutations
export const mutations = {
    [types.SET_PROFILE_IMAGE] : (state, data) => { state.profileImage = data.profileImage},
    [types.SET_COMPANY_LOGO] : (state, data) => { state.companyLogo = data.companyLogo}
};

// actions
export const actions = {
    async getImages ({commit}, user) {
        try {
            commit(types.SET_PROFILE_IMAGE, { profileImage: '/storage/profile-image/' + user.id + '/'+ user.profileImage });
            if(user.companyLogo){
                commit(types.SET_COMPANY_LOGO, { companyLogo: '/storage/images/logos/'+ user.companyLogo });
            }
        }catch (e) {
        }
    },

    setCompanyLogo({commit}, companyLogo) {
        commit(types.SET_COMPANY_LOGO, { companyLogo: companyLogo});
    }
}
