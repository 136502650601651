import * as types from '~/store/mutation-types';

// state
export const state = {
    email: null,
    password: null,
};

// getters
export const getters = {
    details: state => {
        return{
            email: state.email,
            password: state.password
        }
    }
};

// mutations
export const mutations = {
    [types.SET_EMAIL] : (state, data) => { state.email = data.email},
    [types.SET_PASSWORD] : (state, data) => { state.password = data.password},
    [types.SET_DETAILS] : (state, data) => { state.email = data.email; state.password = data.password},
};

// actions
export const actions = {
    async deleteDetails ({commit}) {
        commit(types.SET_EMAIL, { email: null });
        commit(types.SET_PASSWORD, { email: null });
    },
}
