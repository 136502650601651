<template>
 <div class="container-fluid parent_content login registration_content">
     <div class="registration ">
         <reg-header/>
         <slot/>
     </div>
     <auth-footer/>

    <!-- Payment Succeeded modal -->
     <b-modal
         id="payment-success"
         modal-class="modal_area payment-success"
         :hide-footer="true"
         no-close-on-backdrop
     >
         <div>
             <div class="modal-body pt-0">
                 <span v-html="svg('succeeded-payment')"></span>
                 <h4 class="mt-0">{{ $t('payment_succeeded') }}</h4>
                 <p>{{ $t('payment_succeeded_info') }}</p>
             </div>
             <div class="modal-footer mt-4 d-flex justify-content-center align-items-center">
                 <button class="main-button main-button__save" @click="closeModal">{{ $t('ok') }}</button>
             </div>
         </div>
     </b-modal>

    <!-- Payment Failed modal -->
     <b-modal
         id="payment-failed"
         modal-class="modal_area payment-success"
         :hide-footer="true"
         no-close-on-backdrop
     >
         <div>
             <div class="modal-body pt-0">
                 <span v-html="svg('succeeded-failed')"></span>
                 <h4 class="mt-0">{{ $t('sorry') }}</h4>
                 <p>{{ $t('failed_info') }}</p>
             </div>
             <div class="modal-footer mt-4 d-flex justify-content-center align-items-center">
                 <button class="main-button main-button__save" @click="$bvModal.hide('payment-failed')">{{ $t('ok') }}</button>
             </div>
         </div>
     </b-modal>
 </div>
</template>

<script>
    import SubscriptionService  from "~/services/SubscriptionService";
    import UserService          from "~/services/UserService";
    import AuthFooter           from "~/components/auth/Footer";
    import RegHeader            from "~/components/auth/Header";
    import * as types           from '~/store/mutation-types';
    import {mapGetters}         from "vuex";

    export default {
        name: 'Registration',

        data(){
            return{
                SubscriptionService: new SubscriptionService(),
                UserService: new UserService(),
            }
        },

        computed: {
            ...mapGetters({
                user: 'auth/user'
            }),
        },

        mounted() {
            if(this.user){
              this.$store.getters['echo/connectionInstance'].private('user_' + this.user.id)
                  .listen('.subscription.created', async (e) => {
                    if (e.subscription.stripe_status === 'active') {
                      await this.updateUserStage();
                        this.$bvModal.hide('change-plan');
                      this.$loader.buttonLoader('retry-payment', false);
                      this.$bvModal.show('payment-success');

                    } else if (e.subscription.stripe_status === 'incomplete') {
                        // this.$loader.buttonLoader(event.target.id, false);
                        // await this.fetchData();
                        this.$bvModal.hide('change-plan');
                      this.$loader.buttonLoader('retry-payment', false);
                      console.log('incomplete')
                    }
                  })
                  .listen('.subscription.updated', async (e) => {
                    if (e.subscription.stripe_status === 'active' && e.subscription.previous_status !== 'active') {
                      await this.updateUserStage();
                        this.$bvModal.hide('change-plan');
                      this.$loader.buttonLoader('retry-payment', false);
                      this.$bvModal.show('payment-success');

                    }
                  })
                  .listen('.payment.failed', async  (e) => {
                      this.$bvModal.hide('change-plan');
                    this.$loader.buttonLoader('retry-payment', false);
                    this.$bvModal.show('payment-failed');
                  })
                  .listen('.update_payment_method.success', async (e) => {
                      this.$bvModal.show('payment-details-updated');
                  });
            }
        },


        methods: {
            updateUserStage: async function () {
                let result = await this.UserService.getNewAccessToken();
                this.$store.commit('auth/' + types.SET_TOKEN, {token: result.token});
                localStorage.setItem('token', result.token);
                await this.$store.dispatch('auth/getUser');

                //this.$bvModal.show('payment-success');
            },

            closeModal: function () {
                this.$bvModal.hide('payment-success');
                this.$bvModal.hide('payment-failed');
                this.redirectAccordingToScope();
            }
        },

        components: {
            AuthFooter,
            RegHeader
        }
    }
</script>

<style lang="scss">
    @import "@/abstracts/_variables";
    @import "@/abstracts/_mixins";

    .registration {
        background: #F2F6FC;
        min-height: calc(100% - 71px);

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 50px;
            background: #FFFFFF;
            box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);

            @include media(767) {
                padding: 20px 4%;
            }
            img {
                width: 236px;
            }
        }

        .bredcrump {
            background: #FFFFFF;
            box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
            padding: 20px 60px;
            margin: 25px 0;
            display: flex;
            align-items: center;
            gap: 10px;

            &__item {
                display: flex;
                align-items: center;
                gap: 10px;

                &.active {
                    .bredcrump__item--number {
                        background: $mainColor;
                        border: 2px solid $mainColor;
                        color: #FFFFFF;
                    }

                    .bredcrump__item--text {
                        color: $mainColor;
                    }
                }

                &--number {
                    font-weight: bold;
                    font-size: 22px;
                    line-height: 28px;
                    color: rgba(82, 79, 99, 0.5);
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 0;
                    border: 2px solid rgba(82, 79, 99, 0.3);
                    box-sizing: border-box;
                    border-radius: 4px;
                }

                &--text {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: rgba(82, 79, 99, 0.5);
                    margin-bottom: 0;
                }
            }
        }
    }

    .modal_area.payment-success .modal-dialog{
        @media screen and (min-width: 768px){
            max-width: 560px;
        }
    }
    .modal_area.payment-success .modal-dialog .modal-content .modal-body {
        padding: 0;
        .modal-body{
            margin: 45px 60px 30px;
            h4{
                padding-top: 15px;
            }
            p {
                font-weight: 500;
                font-size: 18px;
                line-height: 30px;
                margin-bottom: 0;
            }
        }
        .modal-header {
            background: #fff;

            h4 {
                background: #fff;
            }
        }
        .modal-footer{
            background: #F2F6FC;
            border-radius: 6px;
            padding: 20px;
        }
        .warning_icon {
            width: 50px;
            height: 44px;
        }
    }

</style>
