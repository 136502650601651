import Vue from "vue";
import routes from './routes';
import Router from 'vue-router';
import Meta from 'vue-meta';
import middlewares from '~/middleware/index';
import VueI18n from '~/setup/i18n-setup';

Vue.use(Router);
Vue.use(Meta);
Vue.use(VueI18n);

const router = createRouter();

router.beforeEach(async (to, from, next) => {
    //set page title
    window.document.title = to.meta && to.meta.title ? `AbleToRecords - ` + VueI18n.t(to.meta.title) : `AbleToRecords`;

    //clear all messages
    Vue.prototype.$snotify.clear();

    // Start the loading bar.
    router.app.$nextTick(() => router.app.$loading.start())

    //execute middlewares
    return await callMiddlewares(to, from, next);
});

router.afterEach(async (to, from, next) => {
    await router.app.$nextTick();

    router.app.$loading.finish();
})

function scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    } else {
        return { x: 0, y: 0 }
    }
}


export default router

/**
 * Create a new router instance.
 *
 * @return {Router}
 */
function createRouter() {
    return new Router({
        scrollBehavior,
        mode: 'history',
        routes
    });
}

/**
 * Call all middlewares
 *
 * @param to
 * @param from
 * @param next
 * @returns {Promise<*>}
 */
async function callMiddlewares(to, from, next) {
    let globalMiddlewares = ['domain', 'lang', 'timezone'];

    let routeMiddlewares = [];
    if (to.meta.middleware) {
        routeMiddlewares = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
    }

    let context = {
        from,
        next,
        router,
        to,
        params: []
    };
    try {
        let allMiddlewares = [...globalMiddlewares, ...routeMiddlewares];
        allMiddlewares = createMiddlewaresObject(allMiddlewares);

        let result = await execMiddlewares(middlewares, allMiddlewares, context);

        if(result){
            // router.app.setLayout(to.meta.layout || '');
            return next();
        }

        return next(false);
    } catch (err) {
        console.error(1, err);
    }

    // router.app.setLayout(to.meta.layout || '');
    return next();
}


async function execMiddlewares(middlewares, middlewareData, context) {
    let res = '';
    for (let i = 0; i < middlewareData.length; i++){
        context.params = middlewareData[i].params;

        res = await middlewares[middlewareData[i].name]({...context});
        if(!res){
            return false;
        }
    }
    return res;
}
/**
 * get middleware name and params in object
 *
 * @param allMiddlewares
 * @returns {*}
 */
function createMiddlewaresObject(middlewares)
{
    for (let i = 0; i < middlewares.length; i++){
        let middlewareParts = middlewares[i].split(":");
        let newMiddlewareObject = {};
        newMiddlewareObject.name = middlewareParts[0];
        newMiddlewareObject.params = typeof middlewareParts[1] === "undefined" ? [] : middlewareParts[1].split(",");
        middlewares[i] = newMiddlewareObject;
    }

    return middlewares;
}
