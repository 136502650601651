 //for more options check snotify plugin
import Vue from "vue";

var defaultOptions = {
    timeout: 5000,
    showProgressBar: false,
    closeOnClick: false,
    position: "rightTop",
    animation: {enter: 'fadeIn', exit: 'fadeOut', time: 800}
}

var success =  function (message, options) {
    if(!options){
        options = defaultOptions;
    }

    Vue.prototype.$snotify.html('<div class="success"><img src="/images/success.svg" class="toast-image"><span class="toast-message">' + message + '</span></div>', options);
};

var error =  function (message, options) {
    if(!options){
        options = defaultOptions;
    }

    Vue.prototype.$snotify.html('<div class="error"><img src="/images/warning-toast.svg" class="toast-image"><span class="toast-message">' + message + '</span></div>', options);
};

var warning =  function (message, options) {
    if(!options){
        options = defaultOptions;
    }

    Vue.prototype.$snotify.html('<div class="warning"><img src="/images/warning-toast.svg" class="toast-image"><span class="toast-message">' + message + '</span></div>', options);
};

var clear =  function () {
    Vue.prototype.$snotify.clear();
};

const toast = {
    install(Vue, options) {
        Vue.prototype.$toast = {};
        Vue.prototype.$toast.success = success;
        Vue.prototype.$toast.error = error;
        Vue.prototype.$toast.warning = warning;
        Vue.prototype.$toast.clear = clear;
    },
};

export default toast;

