<template>
    <ul class="node-tree">
        <li v-if="node.orgUnitPath" class="domain_item"
            :class="{'haveChildren': (node.children && node.children.length),'showAccardion': showOrHide, 'parentNode' : (node.parentOrgUnitPath == '/'), 'cursor-pointer' : (node.parentOrgUnitPath && node.children.length)}"
            @click="showAccardion"
        >
            <p>{{ node.name ? node.name.toLowerCase() : ""}}</p>
            <label class="container1 m-0">
                <input
                    type="checkbox"
                    @change="checkboxChanged(node.orgUnitPath, node)"
                    v-model="node.checked"
                >
                <span class="checkmark"></span>
            </label>
        </li>
        <li v-if="node.children && node.children.length"
            class="ml-4"
            :class="{'childrens' : (node.parentOrgUnitPath && node.children.length), 'showOrHide': showOrHide}"
        >
            <node v-for="(child, index) in node.children"
                  :node="child"
                  :key="index"
                  v-on:add-org-unit="addOrgUnit"
                  v-on:remove-org-unit="removeOrgUnit"
            />
        </li>
    </ul>
</template>

<script>
    export default {
        name: "node",
        props: [
            'node',
        ],

        data() {
            return {
                showOrHide: false
            }
        },


        methods: {
            checkboxChanged: function (value, data) {
                if (data.checked) {
                    this.$emit('add-org-unit', value, data);
                } else {
                    this.$emit('remove-org-unit', value, data);
                }
            },

            addOrgUnit: function (value, data) {
                this.$emit('add-org-unit', value, data);
            },

            removeOrgUnit: function (value, data) {
                this.$emit('remove-org-unit', value, data);
            },

            showAccardion: function () {
                this.showOrHide = !this.showOrHide
            }
        },
    };
</script>

<style lang="scss">
    @import "@/abstracts/_variables";
    @import "@/abstracts/_mixins";

    .node-tree {
        list-style-type: none;
        padding: 0;


    .childrens {
        /*visibility: hidden;*/
        /*height: 0;*/
        /*transition: height 9s linear 9s;*/
        display: none;
    }

    .showOrHide {
        /*visibility: visible;*/
        /*height: auto;*/
        /*transition: height 9s linear 9s;*/
        display: block;
    }

        .domain_item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(82, 79, 99, 0.2);
            padding: 10px;
            position: relative;

            &.parentNode {
                cursor: pointer;

                p {
                    color: $secondColor;
                }
            }

            &.haveChildren {
                &.showAccardion {
                    &:before {
                        border-top: 6px solid $secondColor;
                        border-left: 6px solid transparent;
                        left: -6px;
                    }
                }

                &:before {
                    content: "";
                    position: absolute;
                    border-left: 6px solid $secondColor;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    border-top: 6px solid transparent;
                    top: 18px;
                    left: -2px;
                }
            }


            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #524F63;
                margin-bottom: 0;
            }

            .checkmark {
                top: -9px !important;
            }
        }
    }
</style>
