<template>
  <div class="cookie-banner-component">
    <template v-if="show_cookie_settings">
      <div class="modal-backdrop"></div>
      <div class="modal-sub-wrapper">
        <div class="modal-header">
          <div class="modal-header-text"> {{$t('privacy_preference_centre')}}</div>
          <button @click="closeCookieSettings()" class="modal-button-close-btn">×</button>
        </div>

        <div class="modal-body">
          <div class="tab-button-wrapper">
            <button v-for="tab in tabs" :key="tab.name"
                    @click="selectTab(tab.name)"
                    :class="{'active-button': selected_tab === tab.name}">
              {{ tab.text }}
            </button>
          </div>
          <div class="tab-content-wrapper">
            <!-- Tab 1 -->
            <div v-show="selected_tab === 'privacy_statement'" class="tab-content">
                {{$t('we_are_legaltech_saas_company')}}
                {{$t('all_our_saas_app')}}
             For your information, we published our <a :href=links.imprintUrl target="_blank">Imprint</a>, our <a :href=links.privacyUrl target="_blank">Privacy Policy</a>, which includes
              information on all first and second
              party cookies, our <a :href=links.transparencyDocUrl target="_blank">Transparency Document</a> that contains all required information in
              accordance
              with Art. 13,
              14
              GDPR, the <a :href=links.sassSubscriptionUrl target="_blank">SaaS Subscription and Data Processing Agreement</a> we conclude with all our clients,
              our
              <a :href=links.userAgreementUrl target="_blank">User Agreement</a>,
              the <a :href=links.termsAndConditionsUrl target="_blank">GDPR Terms and Conditions for Processors</a>,
              <a :href=links.listOfProcessors target="_blank">List of Processors</a> and our
              <a :href=links.technicalAndOrgMeasuresUrl target="_blank">Technical and Organizational Measures.</a>

              {{$t('we_want_to_be_transparent')}}
              {{$t('if_you_have_any_question')}}
              {{$t('best_regards')}},

              {{$t('security_team')}}
              {{$t('legaltech_company')}}
            </div>
            <!-- Tab 2 -->
            <div v-show="selected_tab === 'strictly_necessary_cookies'" class="tab-content">
              <p>{{$t('cookies_listed_in')}}</p>
              <p>{{$t('cookies_used')}}</p>

              <p v-for="cookie in strictly_necessary_cookies" :key="cookie" class="used_cookie">
                {{ cookie }}
              </p>
            </div>
            <!-- Tab 3 -->
            <div v-show="selected_tab === 'personalization_cookies'" class="tab-content">
              <p>{{$t('cookies_listed_in_are')}}</p>
              <label id="modal-tab-personalisation-switch" class="csm-switch">
                <input id="modal-tab-personalisation-input"
                       class="csm-input-checkbox"
                       v-model="modal_tab_personalisation"
                       type="checkbox">
                <span id="modal-tab-personalisation-slider" class="csm-switch-slider"></span>
              </label>
              <p>{{$t('cookies_used')}}</p>

              <p v-for="cookie in personalization_cookies" :key="cookie" class="used_cookie">
                  {{ cookie }}
              </p>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button @click="rejectAll()" class="modal-button-reject">{{ $t('reject') }}</button>
          <button @click="acceptAll()" class="modal-button-accept">{{$t('save_settings')}}</button>
        </div>
      </div>
    </template>

    <div @click="toggleCookieSettings()" class="cookie-toggle-button-container">
      <div class="filter-button">
                <span class="cookie_svg_icon">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
                      fill="#4092C4"></path>
                    <path
                      d="M16.0002 26.9994C13.0166 26.9994 10.2245 25.8316 8.13733 23.7087C6.06665 21.6032 4.95294 18.8203 5.00152 15.8724C5.00519 15.6561 5.08586 15.4471 5.22885 15.2839C5.35168 15.1437 5.52126 15.0346 5.70183 14.9887C6.51306 14.7843 7.1987 14.2371 7.58552 13.4873C7.7991 13.073 8.2895 12.8851 8.72581 13.0501C10.7497 13.8182 12.6783 12.2737 12.6783 10.3277L12.6609 10.1645L12.6408 10.0114C12.6133 9.75842 12.693 9.50543 12.8598 9.31294C13.0267 9.12136 13.2659 9.00678 13.5207 8.99762C14.8343 8.9527 15.9617 8.03332 16.2624 6.76194C16.4943 5.78206 17.5026 5.178 18.5118 5.40991C23.5093 6.5722 26.9999 10.95 26.9999 16.0566C26.9999 22.0908 22.0656 26.9994 16.0002 26.9994ZM6.84579 16.5333C6.95946 18.7497 7.86876 20.8222 9.44446 22.4236C11.1842 24.1927 13.5125 25.1661 16.0002 25.1661C21.0546 25.1661 25.1666 21.0798 25.1666 16.0566C25.1666 11.8071 22.259 8.16348 18.0966 7.19551L18.0352 7.20743C17.6263 8.9582 16.2276 10.3075 14.496 10.709C14.3008 13.1546 12.2494 15.0832 9.75611 15.0832C9.44171 15.0832 9.12547 15.0492 8.80923 14.9823C8.30324 15.6652 7.62493 16.2005 6.84579 16.5333Z"
                      fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                    <path
                      d="M18.2929 14.1659C19.0523 14.1659 19.6679 13.5503 19.6679 12.791C19.6679 12.0316 19.0523 11.416 18.2929 11.416C17.5336 11.416 16.918 12.0316 16.918 12.791C16.918 13.5503 17.5336 14.1659 18.2929 14.1659Z"
                      fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                    <path
                      d="M11.8789 19.6669C12.6382 19.6669 13.2538 19.0513 13.2538 18.2919C13.2538 17.5326 12.6382 16.917 11.8789 16.917C11.1195 16.917 10.5039 17.5326 10.5039 18.2919C10.5039 19.0513 11.1195 19.6669 11.8789 19.6669Z"
                      fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                    <path
                      d="M19.207 21.4999C19.9663 21.4999 20.5819 20.8843 20.5819 20.125C20.5819 19.3656 19.9663 18.75 19.207 18.75C18.4476 18.75 17.832 19.3656 17.832 20.125C17.832 20.8843 18.4476 21.4999 19.207 21.4999Z"
                      fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                    <path
                      d="M15.9987 16.9153C16.5049 16.9153 16.9153 16.5049 16.9153 15.9987C16.9153 15.4924 16.5049 15.082 15.9987 15.082C15.4924 15.082 15.082 15.4924 15.082 15.9987C15.082 16.5049 15.4924 16.9153 15.9987 16.9153Z"
                      fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                    <path
                      d="M14.1666 22.4173C14.6729 22.4173 15.0833 22.0069 15.0833 21.5006C15.0833 20.9944 14.6729 20.584 14.1666 20.584C13.6604 20.584 13.25 20.9944 13.25 21.5006C13.25 22.0069 13.6604 22.4173 14.1666 22.4173Z"
                      fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                    <path
                      d="M13.2487 6.83327C13.7549 6.83327 14.1653 6.42288 14.1653 5.91664C14.1653 5.41039 13.7549 5 13.2487 5C12.7424 5 12.332 5.41039 12.332 5.91664C12.332 6.42288 12.7424 6.83327 13.2487 6.83327Z"
                      fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                    <path
                      d="M9.12105 11.4179C9.88041 11.4179 10.496 10.8023 10.496 10.0429C10.496 9.28356 9.88041 8.66797 9.12105 8.66797C8.36168 8.66797 7.74609 9.28356 7.74609 10.0429C7.74609 10.8023 8.36168 11.4179 9.12105 11.4179Z"
                      fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                    <path
                      d="M21.5026 16.9163C22.0088 16.9163 22.4192 16.5059 22.4192 15.9996C22.4192 15.4934 22.0088 15.083 21.5026 15.083C20.9963 15.083 20.5859 15.4934 20.5859 15.9996C20.5859 16.5059 20.9963 16.9163 21.5026 16.9163Z"
                      fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                    </svg>
                </span>
        <div class="headSlide">
          <p>{{ $t('manage_cookie') }}</p>
        </div>
      </div>
    </div>


    <div v-if="show_cookie_bottom_banner" class="cookie-banner-container">
      <div class="banner-text-wrapper">
        <div class="banner-text-content">
          <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z" fill="#4092C4"></path>
                        <path d="M16.0002 26.9994C13.0166 26.9994 10.2245 25.8316 8.13733 23.7087C6.06665 21.6032 4.95294 18.8203 5.00152 15.8724C5.00519 15.6561 5.08586 15.4471 5.22885 15.2839C5.35168 15.1437 5.52126 15.0346 5.70183 14.9887C6.51306 14.7843 7.1987 14.2371 7.58552 13.4873C7.7991 13.073 8.2895 12.8851 8.72581 13.0501C10.7497 13.8182 12.6783 12.2737 12.6783 10.3277L12.6609 10.1645L12.6408 10.0114C12.6133 9.75842 12.693 9.50543 12.8598 9.31294C13.0267 9.12136 13.2659 9.00678 13.5207 8.99762C14.8343 8.9527 15.9617 8.03332 16.2624 6.76194C16.4943 5.78206 17.5026 5.178 18.5118 5.40991C23.5093 6.5722 26.9999 10.95 26.9999 16.0566C26.9999 22.0908 22.0656 26.9994 16.0002 26.9994ZM6.84579 16.5333C6.95946 18.7497 7.86876 20.8222 9.44446 22.4236C11.1842 24.1927 13.5125 25.1661 16.0002 25.1661C21.0546 25.1661 25.1666 21.0798 25.1666 16.0566C25.1666 11.8071 22.259 8.16348 18.0966 7.19551L18.0352 7.20743C17.6263 8.9582 16.2276 10.3075 14.496 10.709C14.3008 13.1546 12.2494 15.0832 9.75611 15.0832C9.44171 15.0832 9.12547 15.0492 8.80923 14.9823C8.30324 15.6652 7.62493 16.2005 6.84579 16.5333Z" fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                        <path d="M18.2929 14.1659C19.0523 14.1659 19.6679 13.5503 19.6679 12.791C19.6679 12.0316 19.0523 11.416 18.2929 11.416C17.5336 11.416 16.918 12.0316 16.918 12.791C16.918 13.5503 17.5336 14.1659 18.2929 14.1659Z" fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                        <path d="M11.8789 19.6669C12.6382 19.6669 13.2538 19.0513 13.2538 18.2919C13.2538 17.5326 12.6382 16.917 11.8789 16.917C11.1195 16.917 10.5039 17.5326 10.5039 18.2919C10.5039 19.0513 11.1195 19.6669 11.8789 19.6669Z" fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                        <path d="M19.207 21.4999C19.9663 21.4999 20.5819 20.8843 20.5819 20.125C20.5819 19.3656 19.9663 18.75 19.207 18.75C18.4476 18.75 17.832 19.3656 17.832 20.125C17.832 20.8843 18.4476 21.4999 19.207 21.4999Z" fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                        <path d="M15.9987 16.9153C16.5049 16.9153 16.9153 16.5049 16.9153 15.9987C16.9153 15.4924 16.5049 15.082 15.9987 15.082C15.4924 15.082 15.082 15.4924 15.082 15.9987C15.082 16.5049 15.4924 16.9153 15.9987 16.9153Z" fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                        <path d="M14.1666 22.4173C14.6729 22.4173 15.0833 22.0069 15.0833 21.5006C15.0833 20.9944 14.6729 20.584 14.1666 20.584C13.6604 20.584 13.25 20.9944 13.25 21.5006C13.25 22.0069 13.6604 22.4173 14.1666 22.4173Z" fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                        <path d="M13.2487 6.83327C13.7549 6.83327 14.1653 6.42288 14.1653 5.91664C14.1653 5.41039 13.7549 5 13.2487 5C12.7424 5 12.332 5.41039 12.332 5.91664C12.332 6.42288 12.7424 6.83327 13.2487 6.83327Z" fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                        <path d="M9.12105 11.4179C9.88041 11.4179 10.496 10.8023 10.496 10.0429C10.496 9.28356 9.88041 8.66797 9.12105 8.66797C8.36168 8.66797 7.74609 9.28356 7.74609 10.0429C7.74609 10.8023 8.36168 11.4179 9.12105 11.4179Z" fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                        <path d="M21.5026 16.9163C22.0088 16.9163 22.4192 16.5059 22.4192 15.9996C22.4192 15.4934 22.0088 15.083 21.5026 15.083C20.9963 15.083 20.5859 15.4934 20.5859 15.9996C20.5859 16.5059 20.9963 16.9163 21.5026 16.9163Z" fill="white" stroke="#4092C4" stroke-width="0.5"></path>
                        </svg>
                    </span>
          <p>{{$t('with_your_click')}}</p>
        </div>
      </div>
      <div class="banner-button-wrapper">
        <button @click="showCookieSettings()" class="banner-button-settings">{{$t('cookie_settings')}}</button>
        <button @click="rejectAll()" class="banner-button-reject">{{ $t('Reject All') }}</button>
        <button @click="acceptAll()" class="banner-button-accept">{{$t('accept_all')}}</button>
      </div>

      <div class="ccpa_crpa_checkbox_wrapper">
        <input type="checkbox">
        <span>{{ $t('not_sell_info') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getCookieByName, setCookie, deleteCookie, setLanguageCookie } from "../../services/CookieService";

export default {
  name: "TheCookieBanner",

  data() {
    return {
      show_cookie_bottom_banner: false,
      show_cookie_settings: false,
      tabs: [
        {name: 'privacy_statement', text: 'Privacy Statement'},
        {name: 'strictly_necessary_cookies', text: 'Strictly Necessary Cookies'},
        {name: 'personalization_cookies', text: 'Personalization Cookies'}
      ],
      selected_tab: 'privacy_statement',

      strictly_necessary_cookies: [
        'AbleToRecords',
        'refresh_token',
       ],

      personalization_cookies: ['Application_Language'],

      modal_tab_personalisation: false,
    }
  },

  computed: {
    links() {
      return {
        privacyUrl: privacyUrl,
        imprintUrl: imprintUrl,
        transparencyDocUrl: transparencyDocUrl,
        sassSubscriptionUrl: sassSubscriptionUrl,
        userAgreementUrl: userAgreementUrl,
        termsAndConditionsUrl: termsAndConditionsUrl,
        technicalAndOrgMeasuresUrl: technicalAndOrgMeasuresUrl,
        listOfProcessors: listOfProcessors
      }
    }
  },

  created() {
     this.checkPersonalizationCookies();
  },

  methods: {
    toggleCookieSettings() {
      this.show_cookie_settings = !this.show_cookie_settings;
    },

    closeCookieSettings() {
      this.show_cookie_settings = false;
    },

    showCookieSettings() {
      this.show_cookie_settings = true;
    },

    selectTab(tab_name) {
      if (this.selected_tab !== tab_name) {
        this.selected_tab = tab_name;
      }
    },

    checkPersonalizationCookies() {
      let consent = getCookieByName('AbleToTrainCookieConsent', true);
      if(!consent){
        deleteCookie('Application_Language');
        this.show_cookie_bottom_banner = true;
        return;
      }
      if (consent.action === 'reject') {
        return deleteCookie('Application_Language');
      }

      else if (consent.action === 'accept') {
        if (Object.keys(consent.user_consent).length) {
          //if some cookies are disabled
          if (consent.user_consent['modal-tab-personalisation']) {
            setLanguageCookie('Application_Language');
            this.modal_tab_personalisation = true;
          } else {
            deleteCookie('Application_Language');
          }
        } else {
          //if all cookies are enabled
          setLanguageCookie('Application_Language');
        }
      }
    },

    rejectAll() {
      //todo understand what we need to change
      let value = {action: "reject", user_consent: {'modal-tab-personalisation': this.modal_tab_personalisation}}
      setCookie('AbleToTrainCookieConsent', JSON.stringify(value));
      this.closeCookieSettingsAndBanner();
    },

    acceptAll() {
      //todo understand what we need to change
      let value = {action: "accept", user_consent: {'modal-tab-personalisation': this.modal_tab_personalisation}}
      setCookie('AbleToTrainCookieConsent', JSON.stringify(value));
      this.closeCookieSettingsAndBanner();
    },

    closeCookieSettingsAndBanner() {
      this.show_cookie_settings = false;
      this.show_cookie_bottom_banner = false;
    }
  }
}
</script>

<style scoped>
.cookie-banner-component {
  box-sizing: border-box;
}

.modal-backdrop {
  height: 100%;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
}

.modal-sub-wrapper {
  background: #F2F6FC;
  min-height: 500px;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -25%);
  position: fixed;
  width: 880px;
  font-size: 13px;
  height: 650px;
  z-index: 100000;
  -webkit-animation: fadein 500ms;
  animation: fadein 500ms;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
}

.modal-header {
  border-radius: 6px 6px 0 0;
  position: relative;
  padding: 30px 10px;
  background: #27AEDE;
  color: #fff;
  display: flex;
  justify-content: center;
}

.modal-header-text {
  display: inline-flex;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.modal-button-close-btn {
  position: absolute;
  background: transparent;
  color: #fff;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  top: 15px;
  right: 15px;
  font-size: 30px;
  line-height: 24px;
  border-radius: 4px;
}

.modal-button-close-btn:hover {
  box-shadow: 0 4px 4px rgb(0 0 0 / 15%);
}


.modal-body {
  overflow: hidden;
  position: relative;
  padding: 30px 30px 0;
  display: flex;
}

.tab-button-wrapper {
  display: flex;
  flex-direction: column;
}

.tab-button-wrapper button {
  padding: 10px;
  width: 232px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #fff;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  color: #524F63;
  margin-bottom: 8px;
}

.tab-button-wrapper button.active-button {
  border-color: #27AEDE;
  outline: none;
  cursor: default;
  pointer-events: none;
}

.tab-content-wrapper {
  width: calc(100% - 232px);
  overflow-y: auto;
  margin-left: 30px;
  padding: 30px 20px;
  display: flex;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  color: #706F75;
  background: #FFFFFF;
}

.tab-content-wrapper .tab-content {
  padding: 0 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  color: #706F75;
}

.tab-content a {
  color: var(--main-color);
  text-decoration: none;
}


.modal-footer {
  padding: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: auto;
}

.modal-button-reject, .modal-button-accept {
  height: 40px;
  padding: 5px 30px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.modal-button-reject:hover, .modal-button-accept:hover {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
}

.modal-button-reject {
  background: #FFFFFF;
  border: 1px solid rgba(82, 79, 99, 0.2);
  color: rgba(82, 79, 99, 0.4);
}

.modal-button-accept {
  margin-left: 15px;
  color: #fff;
  background: #27AEDE;
  border: none;
}


.cookie-toggle-button-container {
  position: fixed;
  right: 48px;
  bottom: 20px;
  height: auto;
  color: #524F63;
  border: none;
  cursor: pointer;
}

.cookie-toggle-button-container .filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F2F6FC;
  border-radius: 40px;
  box-shadow: 0px 2px 2px rgb(64 146 196 / 15%);
}

.cookie-toggle-button-container .filter-button span.cookie_svg_icon {
  transition: all 1.5s;
  transform: rotate(0deg);
}

.cookie-toggle-button-container .filter-button .headSlide {
  transition: all 1.5s;
  max-width: 0;
  overflow: hidden;
}

.cookie-toggle-button-container .filter-button .headSlide p {
  margin-bottom: 0;
  padding: 0 12px;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
}

.cookie-toggle-button-container .filter-button:hover span.cookie_svg_icon {
  transform: rotate(360deg);
}

.cookie-toggle-button-container .filter-button:hover .headSlide {
  width: auto;
  max-width: 200px;
  text-align: center;
}

p.used_cookie {
  font-size: 14px;
  line-height: 24px;
}

/* Toggle Checkbox */
.csm-switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
  float:right;
}
.csm-switch input{
  display:none;
}
.csm-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.csm-switch-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 17px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
.csm-input-checkbox:checked + .csm-switch-slider {
  background-color: #27AEDE;
}
.csm-input-checkbox:focus + .csm-switch-slider {
  box-shadow: 0 0 1px #27AEDE;
}
.csm-input-checkbox:checked + .csm-switch-slider:before {
  transform: translateX(13px);
}


.cookie-banner-container{
  background: #4092C4;
  color: #fff;
  padding: 20px 24px;
  width: 100%;
  z-index: 3000;
  position: fixed;
  bottom: 0;
  left: 0;
  -webkit-animation: moveup 800ms;
  animation: moveup 800ms;
  box-shadow: 10px 0 8px rgba(0, 0, 0, 0.1);
}

.ccpa_crpa_checkbox_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0 0 5px;
}

.ccpa_crpa_checkbox_wrapper input {
  width: 20px;
  height: 20px;
}

/* Banner Text Wrapper */
.banner-text-wrapper{
  display: inline-flex;
  width: 60%;
  text-align: left;
  vertical-align: middle;
}
.banner-text-content{
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.banner-text-content span {
  margin-right: 10px;
}

.banner-text-content p {
  margin-bottom: 0;
  font-size: unset;
  line-height: unset;
  color: #fff;
}

/* Banner button Wrapper */
.banner-button-wrapper {
  display: inline-flex;
  justify-content: flex-end;
  width: calc(40% - 8px);
  vertical-align: middle;
}
.banner-button-accept, .banner-button-reject {
  margin-left: 10px;
  border: 1px solid #FFFFFF;
  color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  padding: 6px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.banner-button-settings{
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background: transparent;
  color: #fff;
}

@media (max-width: 990px) {
  .modal-body {
    flex-direction: column;
    padding: 20px 20px 0;
  }
  .tab-button-wrapper {
    flex-direction: row;
    margin-bottom: 16px;
    justify-content: space-between;
  }
  .tab-button-wrapper button {
    width: 30%;
    padding: 5px;
  }
  .tab-content-wrapper{
    width: 100%;
    margin-left: 0;
  }
  .modal-sub-wrapper {
    width: 95%;
  }

  .banner-text-wrapper {
    width: 100%;
  }

  .banner-button-wrapper {
    display: flex;
    width: 93%;
    flex-direction: column;
    padding: 20px;
  }

  .banner-button-wrapper button {
    width: 50%;
    margin: 0 auto 15px;
  }
}
</style>
