<template>
    <div class="activate_subscription"
         v-if="user.role === 'company_super_admin' && subscription.active_subscription && subscription.active_subscription.cancel_at && !hideSubInfo">
        <img src="/images/attention_1.svg">
        <p v-html="$t('activate_subscription_As_you_requested').replace(':plan_name', $t(subscription.active_subscription.plan)) .replace(':end_date', formatDate(subscription.active_subscription.end_date) ) "></p>
        <button @click="reactivate('acivateModal')">{{$t('reactivate')}}</button>
        <div class="closeSubInfo" @click="hideSubscriptionInfo()">
            <img :src="'/images/smtpConfigImages/closeModal.svg'">
        </div>

        <!-- Activate Subscription modal -->
        <b-modal
            id="acivateModal"
            ref="acivateModal"
            modal-class="modal_area sub__modal subscription_modals"
            :hide-footer="true"
            hide-header
        >
            <div>
                <close-modal :smallIcon="true" @hide-modal="() => $refs['acivateModal'].hide()"/>
                <div class="modal_body">
                    <div class="modal_sub_body">
                        <span class="header__icon" v-html="svg('activateDeactivatemodal')"></span>
                        <h2>{{$t('reactivate_your_subscription')}}</h2>
                    </div>
                    <h3>{{ $t('are_you_sure_reactivate_subscription') }}</h3>
                    <p>{{$t('once_activated_your_subscription_will_be_automatically')}}</p>
                </div>
            </div>
            <div class="link_modal_footer">
                <div class="footer_buttons">
                    <button type="button" class="back_button"
                            @click="() => $refs['acivateModal'].hide()">{{ $t('cancel') }}
                    </button>
                    <button type="button" class="next_button" id="activateLoad"
                            @click="reactivateSubscription">{{ $t('activate') }}
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

    import SubscriptionService from "~/services/SubscriptionService";
    import {mapActions, mapGetters} from "vuex";
    import CloseModal from "./CloseModal";

    export default {
        name: "SubscriptionInfo",
        components: {CloseModal},
        data() {
            return {
                SubscriptionService: new SubscriptionService(),
                hideSubInfo: false,
            }
        },

        computed: {
            ...mapGetters({
                subscription: "subscription/subscription",
                user: 'auth/user'
            }),
        },

        created() {
            this.hideSubInfo = localStorage.getItem('hideSubInfo') ? true : false;
            this.getSubscriptionData();
            this.$emit('sub-info', this.hideSubInfo)
        },
        methods: {
            ...mapActions({
                setSubscription: 'subscription/setSubscription'
            }),

            getSubscriptionData: async function () {
                let result = await this.SubscriptionService.getCompanySubscription();
                this.setSubscription(result.company);
            },
            formatDate: function (data) {
                return this.$moment(this.convertTimezone(data)).format('DD/MM/YY')
            },

            reactivate() {
                this.$refs.acivateModal.show()
            },

            reactivateSubscription: async function (event) {
                event.preventDefault();

                this.$loader.buttonLoader(event.target.id, true);

                let [result, error] = await this.SubscriptionService.reactivateSubscription();

                this.$loader.buttonLoader(event.target.id, false);

                if (result && result == 1) {
                    await this.getSubscriptionData();
                    this.$toast.success(this.$t('subscription_reactivated'));
                } else {
                    this.$toast.error(this.$t('something_went_wrong'));
                }

                this.$nextTick(() => {
                    this.$bvModal.hide('acivateModal');
                })
            },

            hideSubscriptionInfo() {
                this.hideSubInfo = true;
                localStorage.setItem('hideSubInfo', true);
            }
        }
    }
</script>

<style lang="scss">
    .activate_subscription {
        position: relative;
        background: #F6F0E5;
        border-radius: 4px;
        padding: 20px 60px 20px 30px;
        display: flex;
        align-items: center;
        margin: 0 60px 24px 0;
        width: 100%;

        button {
            height: 40px;
            border-radius: 4px;
            padding: 0 26.5px;
            margin-left: auto;
            background: rgba(109, 110, 113, 0.8);
            border: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;

            &:hover {
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
            }
        }

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            color: rgba(82, 79, 99, 0.85);
            margin: 0 0 0 30px;
            padding-right: 40px;
        }

        .closeSubInfo {
            opacity: 60%;
            position: absolute;
            width: 32px;
            height: 32px;
            cursor: pointer;
            right: 4px;
            top: 4px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 16px;
                height: 16px;
            }

            &:hover {
                opacity: 85%;
            }
        }
    }

    .subscription_modals {
        .modal-body {
            padding: 0;

            .modal_body {
                padding: 0 65px;
                text-align: left;

                .modal_sub_body {
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;

                    h2 {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        letter-spacing: -0.01em;
                        color: #524F63;
                        margin: 0;
                    }

                    .header__icon {
                        background: rgba(61, 188, 95, 0.1);
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 15px 0 0 !important;

                        svg {
                            path {
                                fill: #3DBC5F;
                            }
                        }
                    }
                }

                h3 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #524F63;
                    margin: 0;
                }

                p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(82, 79, 99, 0.8);
                    margin: 0;
                }
            }
        }

        .close_icon {
            width: 32px;
            height: 32px;
            margin-left: auto;

            img {
                width: 16px;
                height: 16px;
            }
        }

        .link_modal_footer {
            margin-top: 45px;
            padding: 0 45px;
            justify-content: flex-end;
            min-height: 80px;
            background: #F2F6FC;
            display: flex;
            align-items: center;
            border-radius: 0 0 6px 6px;

            .footer_buttons {
                display: flex;

                button {
                    min-width: 140px;
                    width: unset;
                    justify-content: center;
                    height: 40px;
                    border-radius: 4px;
                    font-weight: 500;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    padding: 0 15px;
                }

                .next_button {
                    background-color: #3DBC5F;
                    border: none;
                    color: #FFFFFF;

                    &:hover {
                        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
                    }
                }

                .back_button {
                    background: #FFFFFF;
                    border: 1px solid rgba(82, 79, 99, 0.3);
                    color: rgba(82, 79, 99, 0.7);
                    margin-right: 20px;

                    &:hover {
                        border: 1px solid rgba(82, 79, 99, 0.4);
                        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
                    }
                }
            }
        }
    }

</style>
