export function getCookies() {
  let cookies = {};
  let all_cookies = document.cookie;
  let cookies_arr = all_cookies.split(';')
  cookies_arr.forEach(cookie => {
    let [key, value] = cookie.split('=');
    key = key.trim();
    // value = value.trim();
    cookies[key] = value;
  })
  return cookies;
}

export function getCookieByName(name, parse_json = false) {
  let cookies = getCookies();
  let cookie = cookies[name];
  if(cookie && parse_json){
    cookie = JSON.parse(cookie);
  }
  return cookie;
}

export function setLanguageCookie(name) {
  const params = new URLSearchParams(window.location.search);
  let lang = 'en';
  if (params.has('lang')) {
    lang = params.get('lang');
  }
  setCookie(name, lang, 365, '/');
}


export function cookieExists(name){
  return document.cookie.split(';').some(c => {
    return c.trim().startsWith(name + '=');
  });
}

export function deleteCookie( name, path, domain ) {
  if(!cookieExists(name)) return;
  document.cookie = `${name}=''; path='/'; expires='Thu, 01 Jan 1970 00:00:01 GMT';`
}

export function setCookie(name, value, days_to_expires = 365, path = '/') {
  let expires_days_to_ms = days_to_expires * 24 * 60 * 60 * 1000;
  let expire_date = new Date(new Date().getTime() + expires_days_to_ms);
  document.cookie = `${name}=${value}; path=${path}; expires=${expire_date};`;
}
