import HttpService from "./HttpService";

export default class LanguageService extends HttpService{

    /**
     * Get all available languages
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getAvailableLanguages(params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('available-languages', params, headers, {}, true);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(availableLanguages => availableLanguages.data).catch(err => err)
    }

    /**
     * Get all languages
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getAllLanguages(params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('all-languages', params, headers, {}, true);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(availableLanguages => availableLanguages.data).catch(err => err)
    }
}
