import HttpService from "./HttpService";

export default class NotificationService extends HttpService{

    /**
     * Get user
     *
     * @param data
     * @returns {Promise<any>}
     */
    getNotifications(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('get-notifications', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(notifications => notifications.data).catch(err => err)
    }

    /**
     * Get user
     *
     * @param data
     * @returns {Promise<any>}
     */
    getNotificationById(id, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('get-notification/' + id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(notification => notification.data).catch(err => err)
    }
    /**
     * Remove notification
     *
     * @param data
     * @returns {Promise<any>}
     */
    removeNotification( id, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('delete-notification/' + id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(notifications => notifications.data).catch(err => err)
    }
    /**
     * Get notifications count by status
     *
     * @param data
     * @returns {Promise<any>}
     */
    getNotificationsCountByStatus(  params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('get-notifications-count/', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(notifications => notifications.data).catch(err => err)
    }
    /**
     * Mark notifications viewed
     *
     * @param data
     * @returns {Promise<any>}
     */
    markAsViewed(params={}, headers= {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('mark-viewed-notifications/',params,headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(notifications => notifications.data).catch(err => err)
    }
    /**
     * Mark notifications read
     *
     * @param data
     * @returns {Promise<any>}
     */
    markAsRead(id, params={}, headers= {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('mark-read-notification/'+ id,params,headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(notifications => notifications.data).catch(err => err)
    }
}
