import Vue from "vue";
import store from '~/store';

export default Vue.mixin({
    methods: {
        generateApiUrl: function(path) {
            switch (this.$store.getters['auth/user'].type) {
                case 'admin':
                    return '/admin/' + path;
                case 'company_admin':
                    return '/company-admin/' + path;
                case 'user':
                    return '/user/' + path;
            }
        },

        asset: function (relativePath) {
            return '/public/' + relativePath;
        },

        languageName: function (languageId) {
            let language = this.availableLanguages.find(function (availableLanguage) {
                return availableLanguage.id == languageId;
            }, this);

            if(language){
                return language.name.toUpperCase();
            }

            return '';
        },

        languageFullName: function (languageId) {
            let language = this.availableLanguages.find(function (availableLanguage) {
                return availableLanguage.id == languageId;
            }, this);

            if(language){
                return language.full_name;
            }

            return '';
        },

        emit (eventName, value) {
            return new Promise((resolve, reject) => {
                this.$emit(eventName, value);
                this.$nextTick(resolve)
            })
        },

        matchCheckboxes(format, fieldFirstLangVal, fieldSecondLangVal){
            if(format.options){
                for(let i = 0; i < format.options.length; i++){
                    let field = fieldFirstLangVal.options.find(function (item) {
                        return item.label == format.options[i].label
                    });

                    if(field){
                        format.options[i].isChecked = field.isChecked;

                        if(format.options[i].ownWords){
                            format.options[i].primaryLanguageValue = field.value;

                            if(fieldSecondLangVal){
                                let field = fieldSecondLangVal.options.find(function (item) {
                                    return item.label == format.options[i].label
                                });
                                format.options[i].secondLanguageValue = field.value;
                            }
                        }
                    }
                }
            }

            if(format.customOptions){
                for(let i = 0; i < fieldFirstLangVal.customOptions.length; i++){

                    let obj = {
                        primaryLanguageValue: fieldFirstLangVal.customOptions[i],
                        secondLanguageValue: ''
                    };

                    if(fieldSecondLangVal){
                        obj.secondLanguageValue = fieldSecondLangVal.customOptions[i];
                    }

                    format.customOptions.push(obj);
                }
            }

            return format;
        },

        deepCopy(obj) {
            let result = obj;
            if (Array.isArray(obj)) {
                result = [];
                for (let i = 0; i < obj.length; ++i) {
                    result.push(this.deepCopy(obj[i]));
                }
            } else if (typeof obj === 'object' && obj !== null) {
                result = {};
                for (const key in obj) {
                    if (!obj.hasOwnProperty(key)) { continue }
                    result[key] = this.deepCopy(obj[key]);
                }
            }
            return result;
        },

        scrollToTop() {
            window.scrollTo(0,0);
        },

        sortArrayByProperty(property) {
            var sortOrder = 1;

            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }

            return function (a,b) {
                if(sortOrder == -1){
                    return b[property].localeCompare(a[property]);
                }else{
                    return a[property].localeCompare(b[property]);
                }
            }
        },

        redirectAccordingToScope(userOldScopes = [])
        {
            let user = this.$store.getters['auth/user'];

            if(user.scopes.includes('change_password')){
                this.$router.push({name: 'auth-change-password'})
                return;
            }

            if(user.scopes.includes('deactivated_by_user')){
                this.$router.push({name: 'auth-activate-account'})
                return;
            }

            if(user.scopes.includes('fill_company_details')){
                this.$router.push({name: 'auth-company-details'})
                return;
            }

            if(user.scopes.includes('give_registration_consent')){
                this.$router.push({name: 'auth-consent'})
                return;
            }

            if(user.scopes.includes('configure_two_factor')){
                this.$router.push({name: 'auth-configure-two-factor'})
                return;
            }

            if(user.scopes.includes('set_two_factor_token')){
                this.$router.push({name: 'auth-token'})
                return;
            }

            if(user.scopes.includes('configure_payment')){
                this.$router.push({name: 'auth-configure-payment'});
                return;
            }

            if(user.scopes.includes('no_active_payment')){
                this.$router.push({name: 'auth-no-active-subscription'});
                return;
            }

            // if user scopes is the same (access_all), redirect to settings
            if(user.role === 'company_super_admin' && userOldScopes.length > 0 && userOldScopes.includes('access_all') && user.scopes.includes('access_all')){
                this.$router.push({name: 'company-admin-profile-edit-subscription-and-billing'})
                return;
            }

            if(user.scopes.includes('access_all')){
                this.$router.push({name: 'home'})
                return;
            }
        },

        convertTimezone(date, format = 'YYYY-MM-DD HH:mm:ss')
        {
            let serverTimeZone = store.getters['timezone/serverTimezone'];

            let converted = new Date(date + ' ' + serverTimeZone);
            date = this.$moment(converted).format(format);

            return date;
        },
        allowOnlyNumbers() {
            let key = event.key;
            let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            let actionKeys = ['Backspace', 'Delete', 'Tab', 'Control', 'Alt', 'Meta', 'Enter', 'ArrowLeft', 'ArrowRight'];
            let controlKeyFriends = ['a', 'c', 'v', 'x', 'z']; //this for select all, copy, past, cut and ctrlZ actions
            // console.log(key);
            if (!numbers.includes(key) && !actionKeys.includes(key) && !(event.ctrlKey && controlKeyFriends.includes(key))) {
                event.preventDefault();
                return false;
            }
        }
    }
});

/**
 *
 * if element has display: flex
 * create span tag inside of element and
 * add directive(v-text-ellipsis) on that span
 *
 */

Vue.directive('text-ellipsis', {
    inserted: function (el) {
        el.classList.add('text-ellipsis');
        if (el.offsetWidth < el.scrollWidth) {
            el.title = el.textContent;
        }
    }
})
