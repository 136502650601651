import HttpService from "./HttpService";

export default class SubscriptionService extends HttpService {


    /**
     * Get Subscription Plans
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getPlans( params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('subscription/get-plans', params, headers,{}, false);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(data => data.data).catch(err => err)
    }

    /**
     * Get Subscription Plan Details
     *
     * @param plan
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getPlanDetails(plan, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('subscription/get-plan-details/' + plan, params, headers,{}, false);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(data => data.data).catch(err => err)
    }

    /**
     * Get company current subscription
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getCompanySubscription(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('company/subscription', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(data => data.data).catch(err => err)
    }

    /**
     * Cancel company subscription
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    cancelCompanySubscription(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('company/subscription/cancel', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(data => [data.data, null]).catch(err => [null, err])
    }

    /**
     * Activate company subscription
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    reactivateSubscription(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('company/subscription/reactivate', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(data => [data.data, null]).catch(err => [null, err])
    }

    /**
     * Change current plan
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    changePlan(params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('company/subscription/change-plan', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(data => [data.data, null]).catch(err => [null, err.response])
    }

    /**
     * Delete plan
     *
     * @param planId
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    deleteUpComingPlan(planId, params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('company/subscription/delete/' + planId, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(data => [data.data, null]).catch(err => [null, err])
    }

    deleteCompany(companyId, params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('company/delete/' + companyId, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(data => [data.data, null]).catch(err => [null, err])
    }

    /**
     * Change current plan
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getPaymentToken(params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('company/payment/get-token', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(data => [data.data, null]).catch(err => [null, err])
    }

    /**
     * Retry payment
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    retryPayment(params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('company/payment/retry', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(data => [data.data, null]).catch(err => [null, err.response])
    }


    /**
     * Set allow activate true
     *
     * @param planId
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    setAllowActivateTrue(planId, params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('company/subscription/set-allow-activate/' + planId, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(data => data.data).catch(err => err)
    }

    deleteCard(company_id, params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post("company/delete_card/"+ company_id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }
        }).then(data => [data.data, null]).catch(err => [null, err.response])
    }

}
