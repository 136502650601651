import store from '~/store';
import DomainService from '~/services/DomainService';

export default async function domain() {

    let domainService = new DomainService();
    const domain = window.location.hostname;
    const mainDomain = window.ENV.domain;

    if(!store.getters['legalDocs/documentsExists']){
        await store.dispatch('legalDocs/getDocuments');
    }


    if(domain === mainDomain){
        await store.dispatch('logo/getLogos');
        return true;
    }

    let isVerified = await domainService.checkIsVerified({domain: domain} );

    if(isVerified){
        await store.dispatch('logo/getLogos');
        return true;
    }

    window.location = 'https:://' + mainDomain;
    return false;
}
