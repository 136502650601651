import UserService from "~/services/UserService";
import * as types  from '~/store/mutation-types';
import store       from "~/store";

// state
export const state = {
    user: null,
    token: null,
    affectOnLogout: true,
};

// getters
export const getters = {
    user: state => state.user,
    token: state => state.token,
    affectOnLogout: state => state.affectOnLogout,
};

// mutations
export const mutations = {
    [types.SET_USER] : (state, data) => { state.user = data.user},
    [types.SET_TOKEN] : (state, data) => { state.token = data.token},
    [types.SET_AFFECT_ON_LOGOUT] : (state, data) => { state.affectOnLogout = data.affectOnLogout},
};

// actions
export const actions = {
    async getUser ({commit}) {
        //todo handle error case
        try {
            let userModel = new UserService();
            const user = await userModel.getAuthUser();
            switch (user.role){
                case 'company_admin':
                case 'company_super_admin':
                case 'company_translator':
                    user.type = 'company_admin';
                    break;
                case 'user':
                    user.type = 'user';
                    break;
                default:
                    user.type = 'admin';
                    break;
            }

            commit(types.SET_USER, { user: user });
            store.dispatch('image/getImages', user);
            store.dispatch('legalDocs/getDocuments', user.language.name);
            await store.commit('lang/' + types.SET_CURRENT_LANGUAGE ,  { currentLanguage: user.language.name })
            localStorage.setItem('lang', user.language.name);

            if(user.subscription){
                store.commit('subscription/' + types.SET_CURRENT_PLAN ,  { currentPlan: user.subscription.plan })
                store.commit('subscription/' + types.SET_SUBSCRIPTION_DETAILS ,  { subscriptionDetails: user.subscription })
            }
        }catch (e) {
            console.log('failed to get user');
        }
    },

    async logout ({commit}) {
        store.dispatch('echo/disconnect', state.user.id);
        store.dispatch('subscription/clearSubscriptionData');
        store.dispatch('notifications/clearNotificationsData');
        localStorage.removeItem('token');
        localStorage.removeItem('secondary_token');
        state.user = null;
        state.token = null;
    },
}


