import store            from '~/store';
import * as types       from '~/store/mutation-types';
import SystemService    from "~/services/SystemService";
import i18n             from  "~/setup/i18n-setup"

var loadLangFile = async function (lang){
    lang = lang.toString().toLowerCase();

    if(store.getters['trans/loadedLangs'].includes(lang)){
        return true;
    }
    let systemService = new SystemService();

    let mess = await systemService.getLanguageTranslations(lang);

    store.commit('trans/' + types.SET_LOADED_LANGUAGES, { loadedLang: lang });
    store.commit('trans/' + types.SET_MESSAGES, { lang: lang, messages: mess });

    return true;
};

var getKeyTrans = function (key, locale){
    if(!locale){
        console.log(key);
    }

    locale = locale.toLowerCase();


    if(i18n.messages[locale] && i18n.messages[locale][key]){
        return i18n.messages[locale][key];
    }

    if(i18n.messages['en'] && i18n.messages['en'][key]){
        return i18n.messages['en'][key]
    }

    return  key;
};

const trans = {
    async  install(Vue, options) {
        Vue.prototype.$trans = getKeyTrans;
        Vue.prototype.$trans.loadLangFile = loadLangFile;
    },
};

export default trans;

