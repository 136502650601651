<template>
    <div class="pdf-container">
        <div class="pdf-header">
            <div>
                {{ this.page }} / {{ this.numPages }}
            </div>
            <div id="buttons" class="ui grey three item inverted bottom fixed menu transition hidden">
                <a class="item item-minus" @click="scale -= scale > 0.2 ? 0.1 : 0">
                    <i class="fa fa-minus"></i>
                </a>
                <a class="ui active">
                    {{ formattedZoom }} %
                </a>
                <a class="item item-plus" @click="scale += scale < 2 ? 0.1 : 0">
                    <i class="fa fa-plus"></i>
                </a>
            </div>
        </div>
        <div id="pdfvuer" class="pdf-file-wrapper">
            <div class="pdf-file-wrapper_child">
                <pdf class="pdf-file" :src="pdfData" v-for="i in numPages" :key="i" :id="i" :page="i"
                     :scale.sync="scale" style="width:100%;margin:20px auto;">
                    <template slot="loading">
                        loading content here...
                    </template>
                </pdf>
            </div>
        </div>
    </div>
</template>

<script>
    import pdfvuer from 'pdfvuer'

    export default {
        name: "pdfViewer",

        components: {
            pdf: pdfvuer
        },

        props: [
            'source'
        ],

        data(){
            return {
                page: 1,
                numPages: 1,
                pdfData: undefined,
                errors: [],
                scale: 1
            }
        },

        created(){
            console.log(this.source);

            this.loadPdf();
        },

        computed: {
            formattedZoom () {
                return Number.parseInt(this.scale * 100);
            },
        },

        methods: {
            async loadPdf(){
                let self = this;

                self.pdfData = pdfvuer.createLoadingTask(self.source);
                self.pdfData.then(pdf => {
                    self.numPages = pdf.numPages;
                    document.getElementById('pdfvuer').onscroll = function(evt) {
                        const pageNumber = getCurrentPageNumber(evt);
                        self.page = pageNumber;
                    }

                    // Get the offset position of the navbar
                    var sticky = document.getElementById('buttons').offsetTop;


                    function getCurrentPageNumber(evt) {
                        const container = evt.target;
                        const contChildren = container.getElementsByClassName('page');
                        const contHalfHeight = getContainerHalfHeight(container);
                        const contTopDistance = container.getBoundingClientRect().top;

                        for(let i = contChildren.length - 1, j = 0; i >= 0; --i, ++j) {
                            const elProps = contChildren[i].getBoundingClientRect();
                            const pageBottom = elProps.bottom - contTopDistance;
                            if (pageBottom < contHalfHeight) {
                                return contChildren.length - j + 1;
                            }
                        }
                        return 1;
                    }

                    function getContainerHalfHeight(container) {
                        const headerHeigth = 54;
                        const contHeight = container.getBoundingClientRect().height - headerHeigth;
                        return contHeight / 2;
                    }

                });
            },

            findPos(obj) {
                return obj.offsetTop;
            }
        },
    }
</script>

<style lang="scss">
    .pdf-container {
        #viewerContainer {
            position: relative !important;
            display: flex;
            justify-content: center;
        }
    }

</style>


<style scoped lang="scss">
    .pdf-container{
        position: relative;
        width: 100%;
        background-color: #525659;
        max-height: 100vh;
        display: flex;
        justify-content: center;

        .pdf-file-wrapper{
            width: 100%;
            margin-top: 60px;
            overflow: scroll;


            .pdf-file{
                margin: 0 auto;
                max-width: 90%;
            }
        }


        .pdf-header{
            display: flex;
            justify-content: space-between;
            padding: 15px 30px;
            position:absolute;
            top:0;
            left: 0;
            width: 100%;
            background-color: #323639;
            color: #ffffff;
        }

        .item{
            &:hover{
                cursor:pointer;
            }

            &-minus{
                display: inline-block;
                margin-right: 5px;
                margin-top: 3px;
            }

            &-plus{
                display: inline-block;
                margin-left: 5px;
            }
        }
    }
</style>
