import HttpService from "./HttpService";

export default class ImageService extends HttpService {


    /**
     * Get Profile Image
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getProfileImage(userId, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('file/profile-image/' + userId, params, headers,
                    { responseType: 'arraybuffer' });
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(image => image.data).catch(err => err)
    }

    /**
     * Get Company Logo
     *
     * @param companyId
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getCompanyLogo(companyId, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('file/company-logo/' + companyId, params, headers, { responseType: 'arraybuffer' });
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(image => image.data).catch(err => err)
    }
}
