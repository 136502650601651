import * as types from '~/store/mutation-types';

// state
export const state = {
     docItems: {
    'privacy_policy': '',
    'transparency_doc': '',
    'tech_and_org_measures': '',
    'user_policy': '',
    'saas_subscription': '',
    'imprint': '',
    'dpia': '/FINAL_ABLE_TO_RECORDS_Data Protection Impact Assessment.pdf',
},
     documentsExists: false
};

// getters
export const getters = {
    docItems: state => state.docItems,
    documentsExists: state => state.documentsExists,
};

// mutations
export const mutations = {
    [types.SET_DOCUMENTS] : (state, data) => { state.docItems = data.docItems},
    [types.SET_DOCUMENTS_EXISTS] : (state, data) => { state.documentsExists = data.documentsExists},
};

// actions
export const actions = {

    async getDocuments ({commit}, lang) {
        let docs = JSON.parse(localStorage.getItem('docUrls'));

        commit(types.SET_DOCUMENTS, { docItems: docs });
        commit(types.SET_DOCUMENTS_EXISTS, { documentsExists: true });
    },
}


