<template>
    <div class="sidebar" :class="{'overflow' : submenuValue,  'show': mobileIsOpened}">
        <div class="branding">
            <router-link class="navbar-brand" :to="{name: homeUrl}">
                <img src="/images/sidebar-logo.svg" class="branding__logo" alt="logo">
                <img src="/images/AbleToRecords_Logo_small_Horizonta.svg" class="branding__logo--mob" alt="logo">
            </router-link>
            <span @click="closeMenu" v-html="svg('sidebarCloseIcon','close-icon')"></span>
        </div>

        <div class="navigation" v-if="user.type === 'user'">
            <nav ref="sidebarMenu" :class="{'scrolled' : scrolled}">
                <ul class="main-menu">
                    <li class="main-menu__item"
                        :class="{'active': $route.matched.some(({ name }) => name === 'user-home')}">
                        <router-link :to="{name: 'user-home'}">
                            <div class="">
                                <span class="default" v-html="svg('sidebarHomeIcon')"></span>
                                <span class="hover" v-html="svg('sidebarHomeIconHover')"></span>
                            </div>
                            <span>{{ $t('home') }}</span>
                        </router-link>
                    </li>
                  <li class="main-menu__item"
                      :class="{'active': $route.matched.some(({ name }) => name && name.includes('user-directory') && !(['user-directory-export', 'user-directory-import'].includes(name)))}">
                    <a class="cursor-pointer" @click="openSubmenu('records')">
                      <div>
                        <span class="default" v-html="svg('sidebarRecordsIcon')"></span>
                        <span class="hover" v-html="svg('sidebarRecordsIconHover')"></span>
                      </div>
                      <span>{{ $t('records') }}</span>
                      <span class="sidebarArrow" :class="{'activeArrow' : (submenuValue === 'records')}"
                            v-html="svg('sidebarArrow')"></span>
                    </a>
                    <ul class="submenu" :class="{'activeSubmenu' : (submenuValue === 'records')}">
                      <li class="main-menu__item">
                        <router-link :to="{name: 'user-directory-all'}">
                          <span>{{ $t('my_records') }}</span>
                        </router-link>
                      </li>
                      <li class="main-menu__item" v-if="user.access_level === 'access_all'">
                        <router-link :to="{name: 'user-prefilled-record-all'}">
                          <span>{{ $t('prefilled_records') }}</span>
                        </router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <hr class="sidebar_line">
                <li class="main-menu__item" v-if="user.company.show_downloads || user.company.show_policies"
                    :class="{'active': $route.matched.some(({ name }) => name && (name.includes('user-document') ))}">
                    <a class="cursor-pointer" @click="openSubmenu('documents')">
                        <div>
                            <span class="default" v-html="svg('sidebarUsersIcon')"></span>
                            <span class="hover" v-html="svg('sidebarUserIconHover')"></span>
                        </div>
                        <span>{{ $t('documents') }}</span>
                        <span class="sidebarArrow" :class="{'activeArrow' : (submenuValue === 'documents')}"
                              v-html="svg('sidebarArrow')"></span>
                    </a>
                    <ul class="submenu" :class="{'activeSubmenu' : (submenuValue === 'documents')}">
                        <li class="main-menu__item" v-if="user.company.show_downloads">
                            <router-link :to="{name: 'user-document-download-all'}">
                                <span>{{ $t('downloads') }}</span>
                            </router-link>
                        </li>
                        <li class="main-menu__item" v-if="user.company.show_policies">
                            <router-link :to="{name: 'user-document-policy-all'}">
                                <span>{{ $t('policies') }}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </nav>
        </div>

        <div class="navigation" v-if="user.type === 'company_admin'">
            <nav ref="sidebarMenu" :class="{'scrolled' : scrolled}">
                <ul class="main-menu">
                    <li class="main-menu__item"
                        :class="{'active': $route.matched.some(({ name }) => name === 'company-admin-home')}">
                        <router-link :to="{name: 'company-admin-home'}">
                            <div class="">
                                <span class="default" v-html="svg('sidebarHomeIcon')"></span>
                                <span class="hover" v-html="svg('sidebarHomeIconHover')"></span>
                            </div>
                            <span>{{ $t('home') }}</span>
                        </router-link>
                    </li>
                    <li class="main-menu__item" v-if="['company_admin', 'company_super_admin'].includes(user.role)"
                        :class="{'active':  $route.matched.some(({ name }) => name && (name.includes('company-admin-directory') || name.includes('company-admin-prefilled-record')))}">
                        <a class="cursor-pointer" @click="openSubmenu('records')">
                            <div>
                                <span class="default" v-html="svg('sidebarRecordsIcon')"></span>
                                <span class="hover" v-html="svg('sidebarRecordsIconHover')"></span>
                            </div>
                            <span>{{ $t('records') }}</span>
                            <span class="sidebarArrow" :class="{'activeArrow' : (submenuValue === 'records')}"
                                  v-html="svg('sidebarArrow')"></span>
                        </a>
                        <ul class="submenu" :class="{'activeSubmenu' : (submenuValue === 'records')}">
                            <li class="main-menu__item">
                                <router-link :to="{name: 'company-admin-directory-all'}">
                                    <span>{{ $t('my_records') }}</span>
                                </router-link>
                            </li>
                            <li class="main-menu__item">
                                <router-link :to="{name: 'company-admin-prefilled-record-all'}">
                                    <span>{{ $t('prefilled_records') }}</span>
                                </router-link>
                            </li>
<!--                            <li class="main-menu__item">-->
<!--                                <router-link :to="{name: 'company-admin-directory-export-import'}">-->
<!--                                    <span>{{ $t('excel_export_import') }}</span>-->
<!--                                </router-link>-->
<!--                            </li>-->
                            <li class="main-menu__item">
                                <router-link :to="{name: 'company-admin-record-suggested-all'}">
                                    <span>{{ $t('suggested_records') }}</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
<!--                    <li class="main-menu__item" v-if="['company_admin', 'company_super_admin'].includes(user.role)"-->
<!--                        :class="{'active': $route.matched.some(({ name }) => name && name.includes('company-admin-record')) && !$route.name.includes('company-admin-record-prefilled-translation')}">-->
<!--                        <router-link :to="{name: 'company-admin-record-prefilled-all'}">-->
<!--                            <div>-->
<!--                                <span v-html="svg('sidebarPrefilledRecords')"></span>-->
<!--                            </div>-->
<!--                            <span>{{ $t('prefilled_records') }}</span>-->
<!--                        </router-link>-->
<!--                    </li>-->

                    <li class="main-menu__item" v-if="['company_admin', 'company_super_admin'].includes(user.role)"
                        :class="{'active': $route.matched.some(({ name }) => name && (name.includes('company-admin-registration-requests') || name.includes('company-admin-user') || name.includes('company-admin-invitation')))}">
                        <a class="cursor-pointer" @click="openSubmenu('users')">
                            <div>
                                <span class="default" v-html="svg('sidebarUsersIcon')"></span>
                                <span class="hover" v-html="svg('sidebarUserIconHover')"></span>
                            </div>
                            <span>{{ $t('users') }}</span>
                            <span class="sidebarArrow" :class="{'activeArrow' : (submenuValue === 'users')}"
                                  v-html="svg('sidebarArrow')"></span>
                        </a>
                        <ul class="submenu" :class="{'activeSubmenu' : (submenuValue === 'users')}">
                            <li class="main-menu__item">
                                <router-link :to="{name: 'company-admin-user-all', params: {userRole: 'user'}}">
                                    <span>{{ $t('active') }}</span>
                                </router-link>
                            </li>
                            <li class="main-menu__item">
                                <router-link :to="{name: 'company-admin-user-inactive'}">
                                    <span>{{ $t('inactive') }}</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="main-menu__item" v-if="['company_admin', 'company_super_admin'].includes(user.role)"
                        :class="{'active': $route.matched.some(({ name }) => name && name.includes('company-admin-locations'))}">
                        <router-link :to="{name: 'company-admin-locations-all'}">
                            <div>
                                <span class="default" v-html="svg('sidebarLocationsIcon')"></span>
                                <span class="hover" v-html="svg('sidebarLocationIconHover')"></span>
                            </div>
                            <span>{{ $t('locations') }}</span>
                        </router-link>
                    </li>
<!--                    <li class="main-menu__item" v-if="['company_admin', 'company_super_admin'].includes(user.role)"-->
<!--                        :class="{'active': $route.matched.some(({ name }) => name && name.includes('company-admin-dpo'))}">-->
<!--                        <router-link :to="{name: 'company-admin-dpo-all'}">-->
<!--                            <div>-->
<!--                                <span v-html="svg('sidebarDposIcon')"></span>-->
<!--                            </div>-->
<!--                            <span>{{ $t('dpos') }}</span>-->
<!--                        </router-link>-->
<!--                    </li>-->
                    <li class="main-menu__item" v-if="['company_admin', 'company_super_admin'].includes(user.role)"
                        :class="{'active': $route.matched.some(({ name }) => name && name.includes('company-admin-persons'))}">
                        <router-link :to="{name: 'company-admin-persons-all'}">
                            <div>
                                <span class="default" v-html="svg('sidebarPersonsIcon')"></span>
                                <span class="hover" v-html="svg('sidebarPersonsIconHover')"></span>
                            </div>
                            <span>{{ $t('persons') }}</span>
                        </router-link>
                    </li>
                    <li class="main-menu__item" v-if="['company_admin', 'company_super_admin'].includes(user.role)"
                        :class="{'active': $route.matched.some(({ name }) => name && name.includes('company-admin-retention-periods'))}">
                        <router-link :to="{name: 'company-admin-retention-periods-all'}">
                            <div>
                                <span class="default" v-html="svg('sidebarRetentionPeriodsIcon')"></span>
                                <span class="hover" v-html="svg('sidebarRetentionPeriodsIconHover')"></span>
                            </div>
                            <span>{{ $t('retention_periods') }}</span>
                        </router-link>
                    </li>
                    <li class="main-menu__item"
                        :class="{'active': $route.matched.some(({ name }) => name && (name.includes('company-admin-record-prefilled-translation') || name.includes('company-admin-translation')))}">
                        <a class="cursor-pointer" @click="openSubmenu('translations')">
                            <div>
                                <span class="default" v-html="svg('sidebarTranslationsIcon')"></span>
                                <span class="hover" v-html="svg('sidebarTranslationsIconHover')"></span>
                            </div>
                            <span>{{ $t('translations') }}</span>
                            <span class="sidebarArrow" :class="{'activeArrow' : (submenuValue === 'translations')}"
                                  v-html="svg('sidebarArrow')"></span>
                        </a>
                        <ul class="submenu" :class="{'activeSubmenu' : (submenuValue === 'translations')}">
                            <li class="main-menu__item">
                                <router-link :to="{name: 'company-admin-record-prefilled-translation-all'}">
                                    <span>{{ $t('prefilled_record_translations') }}</span>
                                </router-link>
                            </li>
                            <!--                            <li class="main-menu__item">-->
                            <!--                                <router-link :to="{name: 'company-admin-translation-email-templates'}">-->
                            <!--                                    <span>{{ $t('email_templates') }}</span>-->
                            <!--                                </router-link>-->
                            <!--                            </li>-->
                        </ul>
                    </li>
                    <hr class="sidebar_line">
                    <li class="main-menu__item" v-if="['company_admin', 'company_super_admin'].includes(user.role)"
                      :class="{'active': $route.matched.some(({ name }) => name && (name.includes('company-admin-document') ))}">
                    <a class="cursor-pointer" @click="openSubmenu('documents')">
                      <div>
                        <span class="default" v-html="svg('sidebarDocumentIcon')"></span>
                        <span class="hover" v-html="svg('sidebarDocumentIconHover')"></span>
                      </div>
                      <span>{{ $t('documents') }}</span>
                      <span class="sidebarArrow" :class="{'activeArrow' : (submenuValue === 'documents')}"
                            v-html="svg('sidebarArrow')"></span>
                    </a>
                    <ul class="submenu" :class="{'activeSubmenu' : (submenuValue === 'documents')}">
                      <li class="main-menu__item">
                        <router-link :to="{name: 'company-admin-document-download-all'}">
                          <span>{{ $t('downloads') }}</span>
                        </router-link>
                      </li>
                      <li class="main-menu__item">
                        <router-link :to="{name: 'company-admin-document-policy-all'}">
                          <span>{{ $t('policies') }}</span>
                        </router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
            </nav>
        </div>

        <div class="navigation" v-if="user.type === 'admin'">
            <nav ref="sidebarMenu" :class="{'scrolled' : scrolled}">
                <ul class="main-menu">
                    <li class="main-menu__item"
                        :class="{'active': $route.matched.some(({ name }) => name === 'admin-home')}">
                        <router-link :to="{name: 'admin-home'}">
                            <div>
                                <span class="default" v-html="svg('sidebarHomeIcon')"></span>
                                <span class="hover" v-html="svg('sidebarHomeIconHover')"></span>
                            </div>
                            <span>{{ $t('home') }}</span>
                        </router-link>
                    </li>
                    <li class="main-menu__item" v-if="['super_admin', 'admin'].includes(user.role)"
                        :class="{'active': $route.matched.some(({ name }) => name === 'admin-categories')}">
                        <router-link :to="{name: 'admin-categories-all'}">
                            <div>
                                <span class="default" v-html="svg('sidebarCategoriesIcon')"></span>
                                <span class="hover" v-html="svg('sidebarCategoriesIconHover')"></span>
                            </div>
                            <span>{{ $t('categories') }}</span>
                        </router-link>
                    </li>
                  <li class="main-menu__item" v-if="['super_admin', 'admin'].includes(user.role)"
                      :class="{'active':  $route.matched.some(({ name }) => name && (name.includes('admin-record')))}">
                    <a class="cursor-pointer" @click="openSubmenu('records')">
                      <div>
                        <span class="default" v-html="svg('sidebarRecordsIcon')"></span>
                        <span class="hover" v-html="svg('sidebarRecordsIconHover')"></span>
                      </div>
                      <span>{{ $t('records') }}</span>
                      <span class="sidebarArrow" :class="{'activeArrow' : (submenuValue === 'records')}"
                            v-html="svg('sidebarArrow')"></span>
                    </a>
                    <ul class="submenu" :class="{'activeSubmenu' : (submenuValue === 'records')}">
                      <li class="main-menu__item">
                        <router-link :to="{name: 'admin-prefilled-record-all'}">
                          <span>{{ $t('prefilled_records') }}</span>
                        </router-link>
                      </li>
                      <li class="main-menu__item">
                        <router-link :to="{name: 'admin-record-suggested-all'}">
                          <span>{{ $t('suggested_records') }}</span>
                        </router-link>
                      </li>
                    </ul>
                  </li>

<!--                  <li class="main-menu__item" v-if="['super_admin', 'admin'].includes(user.role)"-->
<!--                        :class="{'active': $route.matched.some(({ name }) => name && name.includes('admin-record'))}">-->
<!--                        <router-link :to="{name: 'admin-record-prefilled-all'}">-->
<!--                            <div>-->
<!--                                <span class="default" v-html="svg('sidebarRecordsIcon')"></span>-->
<!--                                <span class="hover" v-html="svg('sidebarRecordsIconHover')"></span>-->
<!--                            </div>-->
<!--                            <span>{{ $t('records') }}</span>-->
<!--                        </router-link>-->
<!--                    </li>-->
                    <li class="main-menu__item"
                        v-if="['admin', 'super_admin', 'franchise_admin', 'franchise_super_admin', 'subscription_admin', 'subscription_super_admin'].includes(user.role)"
                        :class="{'active': $route.matched.some(({ name }) => name && (name.includes('admin-user') || name.includes('admin-invitation')))}">
                        <a class="cursor-pointer" @click="openSubmenu('users')">
                            <div>
                                <span class="default" v-html="svg('sidebarUsersIcon')"></span>
                                <span class="hover" v-html="svg('sidebarUserIconHover')"></span>
                            </div>
                            <span>{{ $t('system_admins') }}</span>
                            <span class="sidebarArrow" :class="{'activeArrow' :  (submenuValue === 'users')}"
                                  v-html="svg('sidebarArrow')"></span>
                        </a>
                        <ul class="submenu" :class="{'activeSubmenu' : (submenuValue === 'users')}">
                            <li class="main-menu__item">
                                <router-link :to="{name: 'admin-user-all', params: {userRole: 'admin'}}">
                                    <span>{{ $t('active') }}</span>
                                </router-link>
                            </li>
                            <li class="main-menu__item">
                                <router-link :to="{name: 'admin-user-inactive'}">
                                    <span>{{ $t('inactive') }}</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="main-menu__item"
                        v-if="['admin', 'super_admin', 'franchise_admin', 'franchise_super_admin', 'subscription_admin', 'subscription_super_admin'].includes(user.role)"
                        :class="{'active': $route.matched.some(({ name }) => name && name.includes('admin-company'))}">
                        <router-link :to="{name: 'admin-company-all'}">
                            <div>
                                <span class="default" v-html="svg('sidebarCompaniesIcon')"></span>
                                <span class="hover" v-html="svg('sidebarCompaniesIconHover')"></span>
                            </div>
                            <span>{{ $t('companies') }}</span>
                        </router-link>
                    </li>
                    <li class="main-menu__item" v-if="['franchise_admin', 'franchise_super_admin'].includes(user.role)"
                        :class="{'active': $route.matched.some(({ name }) => name && name.includes('admin-dpo'))}">
                        <router-link :to="{name: 'admin-dpo-all'}">
                            <div>
                                <span v-html="svg('sidebarDposIcon')"></span>
                            </div>
                            <span>{{ $t('dpos') }}</span>
                        </router-link>
                    </li>
                    <li class="main-menu__item" v-if="['admin', 'super_admin',].includes(user.role)"
                        :class="{'active': $route.matched.some(({ name }) => name && name.includes('admin-risk'))}">
                        <a class="cursor-pointer" @click="openSubmenu('risk')">
                            <div>
                                <span class="default" v-html="svg('sidebarRiskIcon')"></span>
                                <span class="hover" v-html="svg('sidebarRiskIconHover')"></span>
                            </div>
                            <span class="text-capitalize">{{ $t('risks') }}</span>
                            <span class="sidebarArrow" :class="{'activeArrow' : (submenuValue === 'risk')}"
                                  v-html="svg('sidebarArrow')"></span>
                        </a>
                        <ul class="submenu" :class="{'activeSubmenu' : (submenuValue === 'risk')}">
                            <li class="main-menu__item">
                                <router-link :to="{name: 'admin-risk-categories-all'}">
                                    <span>{{ $t('risk_categories') }}</span>
                                </router-link>
                            </li>
                            <li class="main-menu__item">
                                <router-link :to="{name: 'admin-risks-all'}">
                                    <span class="text-capitalize">{{ $t('risks') }}</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="main-menu__item"
                        v-if="['admin', 'super_admin', 'translator'].includes(user.role)"
                        :class="{'active': $route.matched.some(({ name }) => name && (name.includes('admin-translation') || name.includes('admin-prefilled-record-translation-all') || name.includes('admin-record-prefilled-translation-all-records')))}">
                        <a class="cursor-pointer" @click="openSubmenu('translations')">
                            <div>
                                <span class="default" v-html="svg('sidebarTranslationsIcon')"></span>
                                <span class="hover" v-html="svg('sidebarTranslationsIconHover')"></span>
                            </div>
                            <span>{{ $t('translations') }}</span>
                            <span class="sidebarArrow" :class="{'activeArrow' : (submenuValue === 'translations')}"
                                  v-html="svg('sidebarArrow')"></span>

                        </a>
                        <ul class="submenu" :class="{'activeSubmenu' : (submenuValue === 'translations')}">
                            <li class="main-menu__item"
                                v-if="['admin', 'super_admin', 'translator'].includes(user.role)">
                                <router-link :to="{name: 'admin-translation-system-all'}">
                                    <span class="text-capitalize">{{ $t('system') }}</span>
                                </router-link>
                            </li>
                            <li class="main-menu__item"
                                v-if="['admin', 'super_admin', 'translator'].includes(user.role)">
                                <router-link :to="{name: 'admin-translation-category-all'}">
                                    <span>{{ $t('categories') }}</span>
                                </router-link>
                            </li>
                            <li class="main-menu__item"
                                v-if="['admin', 'super_admin', 'translator'].includes(user.role)">
                                <router-link :to="{name: 'admin-translation-risk-category-all'}">
                                    <span>{{ $t('risk_categories') }}</span>
                                </router-link>
                            </li>
                            <li class="main-menu__item"
                                v-if="['admin', 'super_admin', 'translator'].includes(user.role)">
                                <router-link :to="{name: 'admin-translation-risk-all'}">
                                    <span class="text-capitalize">{{ $t('risks') }}</span>
                                </router-link>
                            </li>
                            <li class="main-menu__item"
                                v-if="['admin', 'super_admin', 'translator'].includes(user.role)">
                                <router-link :to="{name: 'admin-translation-risk-reducing-measure-all'}">
                                    <span class="text-capitalize">{{ $t('risk_reducing_measures') }}</span>
                                </router-link>
                            </li>
                            <li class="main-menu__item"
                                v-if="['admin', 'super_admin', 'translator'].includes(user.role)">
                                <router-link :to="{name: 'admin-prefilled-record-translation-all'}">
                                    <span>{{ $t('prefilled_records') }}</span>
                                </router-link>
                            </li>
                            <!--                            <li class="main-menu__item">-->
                            <!--                                <router-link :to="{name: 'admin-translation-email-templates'}">-->
                            <!--                                    <span>{{ $t('email_templates') }}</span>-->
                            <!--                                </router-link>-->
                            <!--                            </li>-->
                        </ul>
                    </li>


                    <li class="main-menu__item"
                        v-if="['admin', 'super_admin', 'franchise_super_admin', 'subscription_super_admin'].includes(user.role)"
                        :class="{'active': $route.matched.some(({ name }) => name === 'admin-login-as')}">
                        <router-link :to="{name: 'admin-login-as'}">
                            <div class="">
                                <span class="default" v-html="svg('sidebarLoginAsIcon')"></span>
                                <span class="hover" v-html="svg('sidebarLoginAsIconHover')"></span>
                            </div>
                            <span>{{ $t('login_as') }}</span>
                        </router-link>
                    </li>
                </ul>
            </nav>
<!--            <div class="partner-block d-none d-xl-block" v-if="['super_admin'].includes(user.role)">-->
<!--                <div class="partner">-->
<!--                    <span v-html="svg('sidebarPartnersIcon')"></span>-->
<!--                </div>-->
<!--                <ul class="partner-list text-center">-->
<!--                    <li class="partner-list__item">-->
<!--                        <router-link-->
<!--                            :class="{'active': $route.matched.some(({ name }) => name === 'franchising-partner-all')}"-->
<!--                            :to="{name: 'franchising-partner-all'}">-->
<!--                            {{ $t('franchising_partners') }}-->
<!--                        </router-link>-->
<!--                    </li>-->
<!--                    <li class="partner-list__item">-->
<!--                        <router-link-->
<!--                            :class="{'active': $route.matched.some(({ name }) => name === 'subscription-partner-all')}"-->
<!--                            :to="{name: 'subscription-partner-all'}">-->
<!--                            {{ $t('subscription_partners') }}-->
<!--                        </router-link>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->

            <div class="partner-block d-xl-none llili" v-if="['super_admin'].includes(user.role)"
                 @click="showPartner = !showPartner; submenuValue = null">
                <div class="partner">
                    <span v-html="svg('sidebarPartnersIcon')"></span>
                </div>
                <ul class="partner-list text-center" v-if="showPartner">
                    <li class="partner-list__item">
                        <router-link
                            :class="{'active': $route.matched.some(({ name }) => name === 'franchising-partner-all')}"
                            :to="{name: 'franchising-partner-all'}">
                            {{ $t('franchising_partners') }}
                        </router-link>
                    </li>
                    <li class="partner-list__item">
                        <router-link
                            :class="{'active': $route.matched.some(({ name }) => name === 'subscription-partner-all')}"
                            :to="{name: 'subscription-partner-all'}">
                            {{ $t('subscription_partners') }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import ClickOutside from 'vue-click-outside';

    export default {
        name: "Sidebar",
        props: [
            'mobileIsOpened',
        ],

        data() {
            return {
                scrolled: false,
                submenuValue: '',
                showPartner: false,
            }
        },

        computed: {
            ...mapGetters({
                user: 'auth/user',
                logos: 'logo/logos'
            }),

            homeUrl: function () {
                if (this.user.type === 'user') {
                    return 'user-home';
                }

                if (this.user.type === 'company_admin') {
                    return 'company-admin-home';
                }

                if (this.user.type === 'admin') {
                    return 'admin-home';
                }
            },

            hostname: function () {
                return window.location.hostname
            }
        },

        updated() {
            this.isOverflowing();
        },

        methods: {
            closeMenu: function () {
                this.$emit('close-menu');
            },

            isOverflowing: function () {
                const element = this.$refs.sidebarMenu;
                if (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth) {
                    this.scrolled = true
                } else {
                    this.scrolled = false
                }
            },

            openSubmenu: function (submenuValue) {
                this.submenuValue === submenuValue ? this.submenuValue = null : this.submenuValue = submenuValue;
                this.showPartner = false;
            }
        },

        directives: {
            ClickOutside
        },
    }
</script>

<style scoped lang="scss">
    .sidebar_line {
        border: none;
        background-color: rgba(255, 255, 255, 0.3);
        height: 1px;
        margin: 30px 20px;
    }
    .sidebarArrow {
        margin-left: auto;
        -webkit-transition: -webkit-transform .8s ease-in-out;
        -ms-transition: -ms-transform .8s ease-in-out;
        transition: transform .8s ease-in-out;

        &.activeArrow {
            transform: rotate(90deg);

        }
    }
</style>
