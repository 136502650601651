<template>
    <div class="head">
        <div class="back-button">
            <router-link :to="{name: 'company-admin-user-all', params: {userRole: 'user'}}">
                <p><span v-html="svg('arrowLeft')"></span>{{ $t('back_to_the_system') }}</p>
            </router-link>
        </div>
        <div class="header">
            <h3>{{headerText}}</h3>
            <div class="user">
                <img :src="profileImageFile" alt="">
                <p>{{user.first_name}} {{user.last_name}}</p>
            </div>
        </div>
    </div>

</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'AdminHeader',

        props: [
            'headerText'
        ],

        computed: {
            ...mapGetters({
                user: 'auth/user',
            }),

            profileImageFile: function () {
                if (this.user.profileImage){
                    return  '/storage/profile-image/' + this.user.id + '/'+ this.user.profileImage;
                }

                return "/images/user-icon.jpg";
            },
        },
    }
</script>
<style scoped lang="scss">
    @import "@/abstracts/_variables";
    @import "@/abstracts/_mixins";

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $mainColor;
        box-shadow: 0 2px 9px rgba(0, 0, 0, 0.25);

        .back-button {
            max-width: 370px;
            width: 100%;
            padding: 0 3%;

            p {
                margin-bottom: 0;
                color: #FFFFFF;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;

                span {
                    margin-right: 13px;
                }
            }

            @include media(1200) {
                max-width: 300px;
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 370px);
            align-items: center;
            padding: 25px 24px;
            position: relative;

            h3 {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                color: #FFFFFF;
                margin: 0;
            }

            .user {
                background: rgba(255, 255, 255, 0.2);
                border-radius: 5px;
                padding: 5px 15px;
                display: flex;
                align-items: center;
                img {
                    width: 38px;
                    height: 38px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                p {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #FFFFFF;
                    margin: 0;
                }
            }

            &::before {
                content: "";
                position: absolute;
                left: -1px;
                width: 1.5px;
                height: 35px;
                background-color: rgba(255, 255, 255, 0.2);
                border: none;
            }

            @include media(1200) {
                width: calc(100% - 300px);
            }
        }
    }
</style>
