import * as types from '~/store/mutation-types';
import LanguageService from "~/services/LanguageService";
import DomainService from "../../services/DomainService";

// state
export const state = {
    logos: null
};

// getters
export const getters = {
    logos: state => state.logos
};

// mutations
export const mutations = {
    [types.SET_LOGOS] : (state, data) => { state.logos = data.logos}
};

// actions
export const actions = {
    async getLogos ({commit}, domain) {
        try {
            let domainService = new DomainService();
            const logos = await domainService.getLogos({ domain: domain});
            commit(types.SET_LOGOS, { logos: logos })
        }catch (e) {
            console.log('error')
        }
    },
}
