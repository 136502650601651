<template>
    <b-modal
        id="new-role-assigned"
        modal-class="modal_area"
        :hide-footer="true"
        :hide-header="true"
        no-close-on-backdrop
    >
        <div class="col-md-10 m-auto new-role-assign">
            <div class="modal-header py-0 mt-4">
                <h4 class="mt-0">{{ $t('new_role_assigned') }}</h4>
            </div>
            <div class="modal-body pt-0">
                <hr />
                <p>{{ $t('new_role_assigned_info') }}</p>
            </div>
            <div class="modal-footer mt-4 d-flex justify-content-center align-items-center">
                <button class="main-button main-button__save" @click="logOutUser">{{ $t('log_out') }}</button>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import {mapGetters} from "vuex";
    import {state} from "../../store/modules/auth";

    export default {
        name: 'NewRoleAssigned',

        computed: {
            ...mapGetters({
                user: 'auth/user'
            }),
        },

        created() {
            this.$store.getters['echo/connectionInstance'].private('user_' + this.user.id)
                .listen('.new_role_assigned', async (e) => {
                    this.$store.dispatch('echo/disconnect', this.user.id);
                    localStorage.removeItem('token');
                    localStorage.removeItem('secondary_token');
                    this.$bvModal.show('new-role-assigned');
                });
        },

        methods: {
            logOutUser: async function(){
                await this.$store.dispatch('auth/logout');
                this.$router.push('auth/login');
            }
        }
    }
</script>
<style lang="scss">
    .modal_area .modal-dialog .modal-content .modal-body {
        .new-role-assign {
            .modal-header {
                background: #FFFFFF !important;
            }
        }
    }
</style>
