<template>
    <footer class="footer_line text-center">
        <div>
            <p><a :href="$store.getters['legalDocs/docItems']['imprint']" target="_blank" rel="noopener">{{ $t('imprint') }}</a> |</p>
            <p><a :href="$store.getters['legalDocs/docItems']['privacy_policy']" target="_blank" rel="noopener">{{ $t('privacy_policy') }}</a> |</p>
            <p><a :href="$store.getters['legalDocs/docItems']['user_policy']" target="_blank" rel="noopener">{{ $t('user_policy') }}</a> |</p>
            <p class="footer-menu__item--desc" :data-info="$t('sass_subscription_desc')">
                <a :href="$store.getters['legalDocs/docItems']['saas_subscription']" target="_blank" rel="noopener">{{ $t('legal_notices') }}</a>
            </p>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "AuthFooter",

        data(){
            return {
                window: window
            }
        }
    }
</script>

<style scoped lang="scss">
    .footer_line {
        background: #F2F6FC;
        padding: 29px 0;

        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }

        a {
            color: rgba(#524F63, 0.5);
            margin: 0 20px;
        }

        p {
            margin-bottom: 0;
            font-size: 14px !important;
            line-height: 21px;
            color: rgba(#524F63, 0.5);
        }

        //margin-top: auto;
        color: #706F75;
        font-size: 20px;

        .footer-menu__item--desc {
            position: relative;

            &:hover {
                &::before {
                    content: attr(data-info);
                    min-width: 350px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
                    padding: 10px 15px;
                    border-radius: 2px;
                    font-size: 14px;
                    line-height: 21px;
                    color: rgba(82, 79, 99, 0.4);
                    text-align: left;
                    position: absolute;
                    right: 0;
                    bottom: 35px;
                }

                a {
                    &::before {
                        content: '';
                        border-top: 10px solid #dbdfe4;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        position: absolute;
                        left: 50%;
                        bottom: 25px;
                        z-index: 2;
                    }

                    &::after {
                        content: '';
                        border-top: 8px solid #ffffff;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        position: absolute;
                        left: 51%;
                        bottom: 27px;
                        z-index: 2;
                    }
                }
            }
        }
    }
</style>
