<template>
    <div class=" parent_content">
<!--        <page-header :headerText="directoryName" :notTranslate="true"/>-->
        <page-header :customHeader="true">
            <div class="w-100 flex-center">
                <h3 class="w-100 text-left">
                    <router-link :to="{name: 'company-admin-directory-all'}">{{ $t('my_records') }}</router-link>
                    <span class="header-arrow"><i class="fa fa-chevron-right"></i></span>{{directoryName}}
                </h3>
            </div>
        </page-header>
        <PdfViewer class="mt-40" v-if="showPdf" :source="src"/>
        <pdf-view ref="pdfViewer" v-if="showPdf" :source="src"/>
    </div>
</template>

<script>
    import PdfViewer        from "~/components/PdfViewer";
    import PageHeader        from "~/components/PageHeader";
    import DirectoryService  from "~/services/DirectoryService";
    import PdfView from "../../components/modals/pdfView";

    export default {
        name: 'ViewDirectory',
        components: {
            PdfView,
            PageHeader,
            PdfViewer,
        },

        props: [
            'id'
        ],

        data(){
            return{
                DirectoryService:  new DirectoryService(),
                directoryName: "",
                src: null,
                showPdf: false
            }
        },

        mounted() {
            document.querySelector('body').addEventListener("keydown",  (e) => {
                if(e.ctrlKey && e.key === 'p'){
                    e.preventDefault();
                }
            });
        },

        created(){
            this.fetchData();

        },

        methods: {
            async fetchData(){
                this.$loader.withoutSidebarLoader(true);
                console.log(this.id)

                try{
                    //get user directory data
                    let directoryData = await this.DirectoryService.getUserDirectory(this.id);
                    this.directoryName = directoryData.name;
                    this.directoryId = directoryData.directory_id;

                    //get  pdf html
                    let html = await this.DirectoryService.getDirectoryHtml(this.id);
                    let {data}= await this.DirectoryService.getPdfResult(html);
                    const blob = new Blob([data], { type: 'application/pdf' });
                    this.src = window.URL.createObjectURL(blob);

                    this.showPdf = true;
                }catch (e) {

                }

                this.$loader.withoutSidebarLoader(false);

                this.open()
            },
            open() {
                this.$refs.pdfViewer.show();
            },
        },
    }
</script>

<style lang="scss">
   .page .textLayer{
        display: none !important
    }
</style>
<style scoped lang="scss">
    #buttons {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    /* Page content */
    .content {
        padding: 16px;
    }

    .mt-40{
        margin-top: 40px;
    }
</style>
