<template>
    <div :class="{'pageFreeze' : loader}">
        <div v-if="fullPageLoadPrimary && !withoutSidebarLoader" class="loader primary-loader">
            <div class="loader-main">
                <div class="loader-table">
                    <span class="load-icon" v-html="svg('loaderIcon')"></span>
                </div>
            </div>
        </div>
        <div v-if="fullPageLoader" class="loader">
            <header class="loader-header">
                <img src="/images/AbleToRecords_Logo_Horizontal.svg" alt="logo" class="branding__logo" width="220">
                <div class="placeholder-item"></div>
            </header>
            <div class="loader-main">
                <div class="loader-sidebar">
                    <div class="placeholder-item"></div>
                    <div class="placeholder-item"></div>
                    <div class="placeholder-item"></div>
                    <div class="placeholder-item"></div>
                    <div class="placeholder-item"></div>
                    <div class="placeholder-item"></div>
                </div>
                <div class="loader-content">
                    <div class="loader-title">
                        <div class="placeholder-item"></div>
                    </div>
                    <div class="loader-filter">
                        <div class="placeholder-item"></div>
                        <div class="placeholder-item"></div>
                    </div>
                    <div class="loader-table d-flex align-items-center justify-content-center">
                        <span class="load-icon" v-html="svg('loaderIcon')"></span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="withoutSidebarLoader && !fullPageLoadPrimary" class="loader withoutSidebarLoader">
            <div class="loader-main">
                <div class="loader-content">
                    <div class="loader-title">
                        <div class="placeholder-item"></div>
                    </div>
                    <div class="loader-table">
                        <div class="px-4 ">
                            <div class="loader-filter border-bottom">
                                <div class="d-flex">
                                    <div class="placeholder-item mr-3" style="width: 90px"></div>
                                    <div class="placeholder-item" style="width: 210px"></div>
                                </div>
                                <div class="placeholder-item"></div>
                            </div>
                        </div>
                        <div class="loaderIcon">
                            <span class="load-icon" v-html="svg('loaderIcon')"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="settingsLoader" class="loader withoutSidebarLoader">
            <div class="loader-main">
                <div class="loader-content">
                    <div class="loader-table" style="display: flex; margin-top: 0; height: 100%">
                        <div class="" style="padding: 24px 0 24px 24px">
                                <div style="background-color: rgba(64, 146, 196, 0.1); width: 281px; height: 100%">
                                    <div class="" style="width: 100%; background: rgba(64, 146, 196, 0.1); height: 54px; padding: 15px 20px; margin-bottom: 32px">
                                        <div class="placeholder-item" style="width: 160px; height: 24px;"></div>
                                    </div>
                                   <div style="padding-left: 20px">
                                       <div class="placeholder-item mb-3" style="width: 160px; height: 24px"></div>
                                       <hr style="width: 20px; margin: 15px 0;">
                                       <div class="placeholder-item mb-3" style="width: 80px; height: 16px"></div>
                                       <div class="placeholder-item mb-3" style="width: 120px; height: 16px"></div>
                                       <div class="placeholder-item" style="width: 180px; height: 16px; margin-bottom: 44px"></div>

                                       <hr style="margin: 0 0 32px 0">
                                       <div class="placeholder-item mb-3" style="width: 160px; height: 24px"></div>
                                       <hr style="width: 20px; margin: 15px 0;">
                                       <div class="placeholder-item mb-3" style="width: 80px; height: 16px"></div>
                                       <div class="placeholder-item mb-3" style="width: 120px; height: 16px"></div>
                                       <div class="placeholder-item mb-3" style="width: 180px; height: 16px"></div>
                                       <div class="placeholder-item mb-3" style="width: 160px; height: 24px"></div>
                                       <div class="placeholder-item mb-3" style="width: 120px; height: 16px"></div>
                                   </div>
                                </div>

                        </div>
                        <div class="loaderIcon" style="margin-inline: auto;">
                            <span class="load-icon" v-html="svg('loaderIcon')"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="login" class="login_loader">
            <div class="loader-table" style="display: flex; margin-top: 0; height: 100%">
                <div class="loaderIcon" style="margin-inline: auto;">
                    <span class="load-icon" v-html="svg('loaderIcon')"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {bus} from '~/plugins/loader'

    export default {
        name: 'Loader',

        data() {
            return {
                fullPageLoader: false,
                fullPageLoadPrimary: false,
                loader: false,
                tableLoading: false,
                withoutSidebarLoader: false,
                settingsLoader: false,
                login: false,
            }
        },

        mounted() {
            bus.$on('showOrHideFullPageLoader', (showOrHide) => {
                this.updateFullPageLoader(showOrHide);
            });

            bus.$on('showOrHideFullPagePrimaryLoader', (showOrHide) => {
                this.updateFullPagePrimaryLoader(showOrHide);
            });

            bus.$on('showOrHideWithoutSidebarLoader', (showOrHide) => {
                this.updateWithoutSidebarLoader(showOrHide);
            });

            bus.$on('buttonLoader', (value) => {
                this.updateLoader(value);
            });

            bus.$on('showOrHideSettingsLoader', (value) => {
                this.updateSettingsLoader(value);
            });

            bus.$on('showOrHideLoginLoader', (value) => {
                this.updateLoginLoader(value);
            });

        },

        methods: {
            updateFullPageLoader(showOrHide) {
                this.fullPageLoader = showOrHide;
                this.loader = showOrHide;
            },

            updateSettingsLoader(showOrHide) {
                this.settingsLoader = showOrHide;
                this.loader = showOrHide;
            },

            updateLoginLoader(showOrHide) {
                this.login = showOrHide;
                this.loader = showOrHide;
            },

            updateFullPagePrimaryLoader(showOrHide) {
                this.fullPageLoadPrimary = showOrHide;
                this.loader = showOrHide;
            },

            updateWithoutSidebarLoader(showOrHide) {
                this.withoutSidebarLoader = showOrHide;
                this.loader = showOrHide;
            },

            updateLoader(value) {
                this.loader = value;
                if (document.getElementsByClassName('modal-open')[0]) {
                    this.loader ? document.getElementsByClassName('modal-open')[0].classList.add("event-none") :
                        document.getElementsByClassName('modal-open')[0].classList.remove("event-none");
                }
            }
        },
    }
</script>
<style lang="scss">
    @import "@/abstracts/_mixins";

    .login_loader {
        .loader-table {
            width: 50%;
            height: 100%;
            padding: 0;
            position: absolute;
            right: 0;
            z-index: 1;
        }
    }

    .loaderBlock {
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .loading {
            margin: auto;
        }

        div:not(.loading) {
            display: none
        }
    }

    .event-none {
        pointer-events: none;
    }

    .pageFreeze {
        pointer-events: none;
        overflow: hidden;

        & + .parent_content:not(.login) {
            display: none;
        }

        & + .main, & + .login {
            pointer-events: none;
        }
    }

    //full page loading
    .loader {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #f2f6fc;
        left: 0;
        top: 0;
        z-index: 9;

        &.primary-loader {
            height: 100vh;

            .loader-main {
                width: 100%;
                height: 100%;
            }

            .loader-table {
                height: 100%;
                box-shadow: none;
                border-radius: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
            }
        }

        &.withoutSidebarLoader {
            background: transparent;

            .loader-main {
                height: 100%;
                justify-content: flex-end;

                .loader-content {
                    height: calc(100% - 100px);
                    margin-top: auto;
                }
            }
        }

        &-header {
            display: flex;
            padding: 1% 2%;
            background: #fff;

            .placeholder-item {
                width: 20%;
                margin-right: auto;
                margin-top: 14px;
                margin-left: 3%;
                height: 35px;
            }
        }

        &-main {
            display: flex;
        }

        &-sidebar {
            background: #fff;
            width: 280px;
            height: 91.4vh;
            padding: 1px 0;

            .placeholder-item {
                border-radius: 2px;
                height: 25px;
                width: 80%;
                position: relative;
                overflow: hidden;
                margin: 40px auto;
            }


        }

        &-content {
            box-shadow: 0 2px 9px rgb(0 0 0 / 10%) inset;
            width: calc(100% - 280px);
            padding: 24px;
            background: #F2F6FC;
            @include media(1200) {
                width: 100%;
            }
        }

        &-title {
            width: 100%;
            height: 74px;
            background: #fff;
            display: flex;
            align-items: center;
            padding: 19px 25px;
            border-radius: 6px;
            box-shadow: 0px 2px 9px rgb(0 0 0 / 10%);

            .placeholder-item {
                width: 28%;
            }
        }

        &-filter {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 90px;

            .placeholder-item {
                width: 10%;

                &:first-child {
                    width: 15%;
                }
            }

            &.border-bottom {
                border-bottom: 1px solid rgba(64, 146, 196, 0.2) !important
            }
        }

        &-table {
            margin-top: 24px;
            width: 100%;
            height: calc(100% - 120px);
            background: #fff;
            box-shadow: 0px 2px 9px rgb(0 0 0 / 10%);
            border-radius: 6px;

            .loaderIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                height: calc(100% - 100px);
            }
        }
    }

    .placeholder-item {
        border-radius: 2px;
        height: 25px;
        width: 80%;
        position: relative;
        overflow: hidden;
        background: rgba(64, 146, 196, 0.2);
    }

    .placeholder-item::before {
        content: '';
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        background: linear-gradient(to right, transparent 0%, #ddf3fd 50%, transparent 100%);
        -webkit-animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    @keyframes load {
        from {
            left: -150px;
        }
        to {
            left: 100%;
        }
    }

    .load-icon {
        animation: rotate 1.5s linear infinite;
    }

    @keyframes rotate {
        to {
            transform: rotate(360deg);
        }
    }


    //animation dots

    .dot-pulse {
        position: relative;
        left: -9999px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #fff;
        color: #fff;
        box-shadow: 9999px 0 0 -5px #fff;
        animation: dotPulse 1.5s infinite linear;
        animation-delay: .25s;
    }

    .dot-pulse::before, .dot-pulse::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        width: 7px;
        height: 7px;
        border-radius: 5px;
        background-color: #fff;
        color: #fff;
    }

    .dot-pulse::before {
        box-shadow: 9984px 0 0 -5px #fff;
        animation: dotPulseBefore 1.5s infinite linear;
        animation-delay: 0s;
    }

    .dot-pulse::after {
        box-shadow: 10014px 0 0 -5px #fff;
        animation: dotPulseAfter 1.5s infinite linear;
        animation-delay: .5s;
    }

    @keyframes dotPulseBefore {
        0% {
            box-shadow: 9984px 0 0 -5px #fff;
        }
        30% {
            box-shadow: 9984px 0 0 2px #fff;
        }
        60%,
        100% {
            box-shadow: 9984px 0 0 -5px #fff;
        }
    }

    @keyframes dotPulse {
        0% {
            box-shadow: 9999px 0 0 -5px #fff;
        }
        30% {
            box-shadow: 9999px 0 0 2px #fff;
        }
        60%,
        100% {
            box-shadow: 9999px 0 0 -5px #fff;
        }
    }

    @keyframes dotPulseAfter {
        0% {
            box-shadow: 10014px 0 0 -5px #fff;
        }
        30% {
            box-shadow: 10014px 0 0 2px #fff;
        }
        60%,
        100% {
            box-shadow: 10014px 0 0 -5px #fff;
        }
    }

    .loading {
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        .load-icon {
            width: 50px;
            height: 50px;
            animation: rotate 1.5s linear infinite;
        }

        @keyframes rotate {
            to {
                transform: rotate(360deg);
            }
        }

        p {
            font-size: 14px;
        }
    }

    .loadingBtn {
        background: #27aede;
        height: 100%;
        top: 0;
        position: absolute;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: center;
        border-radius: 4px;
    }

    .cancelLoadingBtn {
        background: #f9fafb;
        height: 100%;
        top: 0;
        position: absolute;
        width: 100%;
        left: 0;
        display: flex;
        padding-left: 10px;

        .dot-pulse {
            position: relative;
            left: -9999px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: rgba(82, 79, 99, 0.4);
            color: rgba(82, 79, 99, 0.4);
            box-shadow: 9999px 0 0 -5px rgba(82, 79, 99, 0.4);
            animation: dotPulse1 1.5s infinite linear;
            animation-delay: .25s;
        }

        .dot-pulse::before, .dot-pulse::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            width: 7px;
            height: 7px;
            border-radius: 5px;
            background-color: rgba(82, 79, 99, 0.4);
            color: rgba(82, 79, 99, 0.4);
        }

        .dot-pulse::before {
            box-shadow: 9984px 0 0 -5px rgba(82, 79, 99, 0.4);
            animation: dotPulseBefore1 1.5s infinite linear;
            animation-delay: 0s;
        }

        .dot-pulse::after {
            box-shadow: 10014px 0 0 -5px rgba(82, 79, 99, 0.4);
            animation: dotPulseAfter1 1.5s infinite linear;
            animation-delay: .5s;
        }
    }


    @keyframes dotPulseBefore1 {
        0% {
            box-shadow: 9984px 0 0 -5px rgba(82, 79, 99, 0.4);
        }
        30% {
            box-shadow: 9984px 0 0 2px rgba(82, 79, 99, 0.4);
        }
        60%,
        100% {
            box-shadow: 9984px 0 0 -5px rgba(82, 79, 99, 0.4);
        }
    }

    @keyframes dotPulse1 {
        0% {
            box-shadow: 9999px 0 0 -5px rgba(82, 79, 99, 0.4);
        }
        30% {
            box-shadow: 9999px 0 0 2px rgba(82, 79, 99, 0.4);
        }
        60%,
        100% {
            box-shadow: 9999px 0 0 -5px rgba(82, 79, 99, 0.4);
        }
    }

    @keyframes dotPulseAfter1 {
        0% {
            box-shadow: 10014px 0 0 -5px rgba(82, 79, 99, 0.4);
        }
        30% {
            box-shadow: 10014px 0 0 2px rgba(82, 79, 99, 0.4);
        }
        60%,
        100% {
            box-shadow: 10014px 0 0 -5px rgba(82, 79, 99, 0.4);
        }
    }
</style>
