<template>
    <div style="height: 100%">
<div class="notification_content">
    <div :class="`${prefix}notifications-list`" v-if="prefix || (!prefix && modal)">
        <div v-if="modal" class="notifications-list__control header">
            <span class="see-more cursor-text">
               {{ $t('notifications') }}
           </span>
        </div>
        <ul class="notifications-list__items" id="infinite-list" v-if="notifications.length">
            <li v-for="(notification, index) in  notifications"
                :style="notification.status === 2 ? 'background: #ffffff': false">
                <div class="d-flex justify-content-between">
                    <p class="d-flex flex-column flex-grow-1 m-0 justify-content-between"
                       @click="markAsRead(notification.id, index, notification.redirectUrl)">
                        <span :class="`${prefix}main-text`">
                        {{ notification.message }}
                        </span>
                        <span class="time">
                            {{ getNotificationTime(notification) }}
                        </span>
                    </p>

                    <p class="d-flex dots-wrapper m-0 position-relative">
                        <span class="dots" @click.stop="deleteNotification(index, notification)"
                              v-html="svg('removeNots','remove')">
                        </span>
                    </p>
                </div>
            </li>
        </ul>
        <div v-if="!notifications.length && notificationRespons && modal" class="d-flex justify-content-center align-items-center p-4">
            <span v-html="svg('noNotifications')"></span>
        </div>
        <div v-if="modal" class="notifications-list__control footer">
       <router-link :to="{name: 'notifications'}" v-if="notifications.length">
                <span class="see-more" @click="closeModal">
                   {{ $t('see_more')}}
               </span>
         </router-link>
            <span class="cursor-text" v-if="!notifications.length && notificationRespons">{{ $t('no_nots') }}</span>
        </div>
    </div>

    <Pagination v-if="!modal && notifications.length" :key="data.current_page + ' ' + data.last_page" :data="data" v-on:set-filter-data="setFilterData"/>
</div>

            <div v-if="!modal && !notifications.length" class="text-center h-100 flex-center">
                <div class="no_notifications">
                    <span v-html="svg('noNotifications')"></span>
                    <p class="empty-text w-100">{{ $t('here_are_no_notifications') }}</p>
                </div>

        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import NotificationService from "~/services/NotificationService";
    import Pagination from "~/components/Pagination";


    export default {
        name: "Notification",
        props: [
            'prefix',
            'modal',
            'parentModalToggle'
        ],

        components: {
            Pagination
        },
        data() {
            return {
                data: {},
                filter: {
                    per_page: '',
                },
                notifications: [],
                notification: {},
                NotificationService: new NotificationService(),
                page: 1,
                notificationRespons: false
            }
        },

        async created() {
             let notification;
          let notifications = await this.NotificationService.getNotifications({per_page: this.modal ? 5 : 25});
            notification = notifications.data;
            this.data = notifications;
            for (let i = 0; i < notification.length; i++) {
                this.$set(notification[i], 'message', this.getNotificationMessage(notification[i]));
                this.$set(notification[i], 'redirectUrl', this.getUrl(notification[i]));
                this.$set(notification[i], 'deleteStatus', false);
            }
            this.notifications = notification;
            this.notificationRespons = true;
        },

        computed: {
            ...mapGetters({
                user: 'auth/user',
                notificationsCount: 'notifications/notifications'
            }),
        },

        mounted() {
            this['notifications/getUnreadNotificationsCount']();

            this.$store.getters['echo/connectionInstance'].private('user_' + this.user.id)
                .notification(async (notification) => {
                    notification = await this.getNotificationById(notification.id);
                    this.notifications.unshift(notification);
                    this['notifications/getUnreadNotificationsCount']();
                });

            if (!this.modal && this.modal === undefined) {
                const listElm = document.querySelector('#infinite-list');
                listElm.addEventListener('scroll', e => {
                    if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
                        this.loadMore(25);
                    }
                });
            }
        },

        methods: {

            ...mapActions(['notifications/getUnreadNotificationsCount']),

            closeModal() {
                this.$emit('parentModalToggle');
            },

            loadMore: async function (count) {
                if (!this.modal) {
                    this.page++;
                }

                let notifications = await this.NotificationService.getNotifications({
                    per_page: count || 25,
                    page: this.page
                });
                notifications = notifications.data;
                notifications.map((notification) => {
                    notification.message = this.getNotificationMessage(notification);
                    notification.redirectUrl = this.getUrl(notification);
                    notification.deleteStatus = false;
                    if (!this.modal) {
                        this.notifications.push(notification);
                    }
                });
                if (this.modal) {
                    this.notifications = notifications;
                }

            },

            getNotificationTime: function (notification) {
                return this.$moment(notification.created_at).fromNow();
            },

            getUrl: function (notification) {
                let url = null;
                console.log(notification.type);
                switch (notification.type) {
                    case 'App\\Notifications\\PARCreatedEditedNotification':
                        url = notification.data.data.type === 'create' ? {
                            name: this.user.type.replace('_', '-') + '-directory-create',
                            params: {id: 4},
                            query: {'parent-id': notification.data.data.notificationData.parent_id}
                        } : {
                            name: this.user.type.replace('_', '-') + '-directory-edit',
                            params: {id: notification.data.data.notificationData.lia_id}
                        };
                        break;
                    case 'App\\Notifications\\NewTranslationAvailable':
                        url = this.setTranslationUrl(notification.data.data, notification.id);
                        break;
                    case 'App\\Notifications\\TranslationCompleted':
                        url = this.setTranslationCompleteUrl(notification.data.data, notification.id);
                        break;
                    case 'App\\Notifications\\CheckRecords':
                        url = this.setCheckRecordUrl(notification.data.data, notification.id);
                        break;
                    case 'App\\Notifications\\RecordsCreated':
                        url = {name: this.user.type.replace('_', '-') + '-directory-all'}
                        break;
                    case 'App\\Notifications\\DomainVerification':
                        url = {
                            name: this.user.type.replace('_', '-') + '-profile-edit',
                            params: {tab: 'domain-settings'},
                        };
                        break;
                    case 'App\\Notifications\\CategoryRecordsCreated':
                        url = {name: this.user.type.replace('_', '-') + '-directory-all'};
                        break;
                    case 'App\\Notifications\\SubscriptionLimitReached':
                        url = this.setLimitReachedUrl();
                        break;
                }

                return url;
            },

            setTranslationUrl: function (notification, id) {
                switch (notification.type) {
                    case "prefilled_record":
                        return {
                            name: this.user.type.replace('_', '-') + '-record-prefilled-translation-edit',
                            params: {id: notification.notificationData.translation.id},
                            query: {'prefilled-record': notification.notificationData.prefilled_record_id}
                        }
                    case "email_templates":
                        return {
                            href: '/' + this.user.type.replace('_', '-') + '/notification/' + notification.type + '/' + id
                        };

                    case "system-translation":
                        return {
                            name: this.user.type.replace('_', '-') + '-translation-system-single',
                            params: {id: notification.notificationData.id},
                        }
                    case "category-translation":
                        return {
                            name: this.user.type.replace('_', '-') + '-translation-category-single',
                            params: {id: notification.notificationData.id},
                        }

                    case "risk-translation":
                        return {
                            name: this.user.type.replace('_', '-') + '-translation-risk-single',
                            params: {id: notification.notificationData.id},
                        }

                    case "risk-category-translation":
                        return {
                            name: this.user.type.replace('_', '-') + '-translation-risk-category-single',
                            params: {id: notification.notificationData.id},
                        }

                    case "risk-reducing-measure-translation":
                        return {
                            name: this.user.type.replace('_', '-') + '-translation-risk-reducing-measure-single',
                            params: {id: notification.notificationData.id},
                        }

                }
            },

            setTranslationCompleteUrl: function (notification, id) {
                switch (notification.notificationData.type) {
                    case "system-translation":
                        return {
                            name: this.user.type.replace('_', '-') + '-translation-system-edit',
                            params: {id: notification.notificationData.translation.id},
                        }
                    case "category-translation":
                        return {
                            name: this.user.type.replace('_', '-') + '-translation-category-edit',
                            params: {id: notification.notificationData.translation.id},
                        }

                    case "risk-translation":
                        return {
                            name: this.user.type.replace('_', '-') + '-translation-risk-edit',
                            params: {id: notification.notificationData.translation.id},
                        }

                    case "risk-category-translation":
                        return {
                            name: this.user.type.replace('_', '-') + '-translation-risk-category-edit',
                            params: {id: notification.notificationData.translation.id},
                        }

                    case "risk-reducing-measure-translation":
                        return {
                            name: this.user.type.replace('_', '-') + '-translation-risk-reducing-measure-edit',
                            params: {id: notification.notificationData.translation.id},
                        }

                    case "record-translation":
                        return {
                            name: this.user.type.replace('_', '-') + '-record-prefilled-translation-edit',
                            params: {id: notification.notificationData.translation.id},
                        }

                }
            },

            setLimitReachedUrl: function () {
                if (this.user.role !== 'company_super_admin') {
                    return {name: this.user.type.replace('_', '-') + '-directory-all'};
                } else {
                    return {name: this.user.type.replace('_', '-') + '-subscription-change-the-plan'};
                }
            },

            getNotificationMessage: function (notification) {
                let message = '';

                switch (notification.type) {
                    case 'App\\Notifications\\CategoryRecordsCreated':
                        message = this.getCategoryRecordMessage(notification.data);
                        break;

                    case 'App\\Notifications\\PARCreatedEditedNotification':
                        message = this.getPARMessage(notification.data);
                        break;

                    case 'App\\Notifications\\NewTranslationAvailable':
                        message = this.getTransMessage(notification.data);
                        break;

                    case 'App\\Notifications\\TranslationCompleted':
                        message = this.getTransCompletedMessage(notification.data);
                        break;

                    case 'App\\Notifications\\RecordsCreated':
                        message = this.getTransMessage(notification.data);
                        break;

                    case 'App\\Notifications\\CheckRecords':
                        message = this.getCheckRecordMessage(notification.data);
                        break;

                    case 'App\\Notifications\\SubscriptionRenewedNotification':
                        message = this.getSubscriptionRenewedNotification(notification.data);
                        break;

                    case 'App\\Notifications\\SubscriptionPlanSetNotification':
                        message = this.getSubscriptionPlanSetNotification(notification.data);
                        break;

                    case 'App\\Notifications\\DomainVerification':
                        message = this.getDomainVerificationMessage(notification.data);
                        break;

                    case 'App\\Notifications\\UpgradedToSuperadmin':
                        message = this.getUpgradeRoleMessage(notification.data);
                        break;

                    case 'App\\Notifications\\SubscriptionLimitReached':
                        message = this.getSubscriptionLimitReachedMessage(notification.data);
                        break;

                    case 'App\\Notifications\\PaymentFailedNotification':
                        message = this.getPaymentFailedNotification(notification.data);
                        break;

                    case 'App\\Notifications\\PaymentSucceededNotification':
                        message = this.getPaymentSucceededNotification(notification.data);
                        break;
                }

                return message;
            },

            getSubscriptionLimitReachedMessage: function (data) {
                if (data.type === "records_limit_reached") {
                    return this.$t('records_limit_reached');
                }
            },

            getDomainVerificationMessage: function (data) {
                return data.type === "success" ? this.$t("domain_verified") : this.$t("domain_verification_failed")
            },

            getCategoryRecordMessage: function (data) {
                return data.type === "success" ? this.$t("category_records_created_success") : this.$t("category_records_created_error")
            },

            getPARMessage: function (notification) {
                return notification.data.type === "create" ? this.$t("create_lia_for_par").replace(':name', notification.data.notificationData.parent_name) : this.$t("check_lia").replace(':name', notification.data.notificationData.lia_name);
            },

            getUpgradeRoleMessage: function (notification) {
                switch (notification.data.notificationData.role) {
                    case 'company_super_admin':
                        return this.$t("access_grunted_company_superadmin")
                    case 'super_admin':
                        return this.$t("access_grunted_superadmin")
                    case 'franchise_super_admin':
                        return this.$t("access_grunted_franch_superadmin")
                    case 'subscription_super_admin':
                        return this.$t("access_grunted_sub_superadmin")
                }
            },

            getTransMessage: function (notification) {
                if (notification && notification.data && notification.data.type === "prefilled_record") {
                    return this.$t('new_p_a_record_trans') + " (" + notification.data.notificationData.translation.fromName + " " + this.$t('to') + " " + notification.data.notificationData.translation.toName + ")"
                }

                if (notification && notification.type === "records_created") {
                    return this.$t('records_created_please_check')
                }

                if (notification && notification.data && notification.data.type === "email_templates") {
                    return this.$t('new_email_settings_trans');
                }

                if (notification && notification.data && notification.data.type === "testimonial-translation") {
                    return this.$t('new_testimonial_trans') + " (" + notification.data.notificationData.fromName + " " + this.$t('to') + " " + notification.data.notificationData.toName + ")";
                }

                if (notification && notification.data && notification.data.type === "risk-translation") {
                    return this.$t('new_risk_trans') + " (" + notification.data.notificationData.fromName + " " + this.$t('to') + " " + notification.data.notificationData.toName + ")";
                }

                if (notification && notification.data && notification.data.type === "risk-category-translation") {
                    return this.$t('new_risk_cat_trans') + " (" + notification.data.notificationData.fromName + " " + this.$t('to') + " " + notification.data.notificationData.toName + ")";
                }

                if (notification && notification.data && notification.data.type === "risk-reducing-measure-translation") {
                    return this.$t('new_risk_measure_trans') + " (" + notification.data.notificationData.fromName + " " + this.$t('to') + " " + notification.data.notificationData.toName + ")";
                }

                if (notification && notification.data && notification.data.type === "category-translation") {
                    return this.$t('new_cat_trans') + " (" + notification.data.notificationData.fromName + " " + this.$t('to') + " " + notification.data.notificationData.toName + ")";
                }

                if (notification && notification.data && notification.data.type === "system-translation") {
                    return this.$t('system_trans_available') + " (" + notification.data.notificationData.fromName + " " + this.$t('to') + " " + notification.data.notificationData.toName + ")";
                }
            },

            getTransCompletedMessage: function (notification) {

                if (notification && notification.data && notification.data.notificationData.type === "risk-translation") {
                    return this.$t('autotranslation_done_risk').replace(':toLang', notification.data.notificationData.to);
                }

                if (notification && notification.data && notification.data.notificationData.type === "risk-category-translation") {
                    return this.$t('autotranslation_done_risk_category').replace(':toLang', notification.data.notificationData.to);
                }

                if (notification && notification.data && notification.data.notificationData.type === "risk-reducing-measure-translation") {
                    return this.$t('autotranslation_done_risk_reducing_measure').replace(':toLang', notification.data.notificationData.to);
                }

                if (notification && notification.data && notification.data.notificationData.type === "category-translation") {
                    return this.$t('autotranslation_done_category').replace(':toLang', notification.data.notificationData.to);
                }

                if (notification && notification.data && notification.data.notificationData.type === "system-translation") {
                    return this.$t('autotranslation_done_system').replace(':toLang', notification.data.notificationData.to);
                }

                if (notification && notification.data && notification.data.notificationData.type === "record-translation") {
                    return this.$t('autotranslation_done_records').replace(':toLang', notification.data.notificationData.to);
                }
            },

            getCheckRecordMessage: function (notification) {
                if (notification && notification.data && notification.data.type === "safeguard_no_checked") {
                    return this.$t('check_record_for_safeguard')
                }

                if (notification && notification.data && notification.data.type === "processing_on_behalf_of_controller_no_check") {
                    return this.$t('processing_on_behalf_of_controller_no_check')
                }
            },

            getPaymentFailedNotification: function (notification) {
                let paymentDate = notification.data.notificationData.date;
                paymentDate = this.$moment(this.convertTimezone(paymentDate)).format('YYYY-MM-DD HH:mm');

                return this.$t('payment_failed_notification').replace(':paymentDate', paymentDate);
            },

            getPaymentSucceededNotification: function (notification) {
                let paymentDate = notification.data.notificationData.date;
                paymentDate = this.$moment(this.convertTimezone(paymentDate)).format('YYYY-MM-DD HH:mm');

                return this.$t('payment_success_notification').replace(':paymentDate', paymentDate);
            },

            getSubscriptionRenewedNotification: function (notification) {
                let startDate = notification.data.notificationData.start_date;
                let endDate = notification.data.notificationData.end_date;

                startDate = this.$moment(this.convertTimezone(startDate)).format('DD/MM/YY');
                endDate = this.$moment(this.convertTimezone(endDate)).format('DD/MM/YY');

                return this.$t('subscription_renewed_notification').replace(':startDate', startDate).replace(':endDate', endDate);
            },

            getSubscriptionPlanSetNotification: function (notification) {
                let startDate = notification.data.notificationData.start_date;
                let endDate = notification.data.notificationData.end_date;

                startDate = this.$moment(this.convertTimezone(startDate)).format('DD/MM/YY');
                endDate = this.$moment(this.convertTimezone(endDate)).format('DD/MM/YY');

                return this.$t('subscription_successfully_set').replace(':startDate', startDate).replace(':endDate', endDate);
            },

            getNotificationById: async function (id) {
                let notification = await this.NotificationService.getNotificationById(id);
                notification.message = this.getNotificationMessage(notification);
                notification.deleteStatus = false;

                return notification
            },

            deleteNotification: async function (index, notification) {
                let id = notification.id;
                this.notifications.splice(index, 1);
                return await this.NotificationService.removeNotification(id) && this.loadMore(this.modal ? 5 : 25) && this['notifications/getUnreadNotificationsCount']();
            },

            setCheckRecordUrl: function (notification) {
                return {
                    name: this.user.type.replace('_', '-') + '-directory-edit',
                    params: {id: notification.notificationData.id},
                }
            },

            markAsRead: async function (id, index, url) {
                let res = await this.NotificationService.markAsRead(id);

                if (res === 1) {
                    this.notifications[index].status = 2;
                    if (url && !url.href) {
                        this.$router.push(url).catch(err => {
                        });
                    }

                    if (url && url.href) {
                        window.location.href = url.href
                    }
                }
            },

            setFilterData: async function (key, value) {
                this.$loader.withoutSidebarLoader(true);
                this.filter[key] = value;
                if (key === 'per_page') {
                    this.filter.page = 1;
                }
                await this.getData();
                this.$loader.withoutSidebarLoader(false);
            },

            getData: async function () {
                this.data = await this.NotificationService.getNotifications(this.filter);
                this.notifications = this.data.data;
                for (let i = 0; i < this.notifications.length; i++) {
                    this.$set(this.notifications[i], 'message', this.getNotificationMessage(this.notifications[i]));
                }
            },

            onPageChange: async function (page) {
                await this.setFilterData('page', page)
            },
        },
    }
</script>

<style scoped lang="scss">
    @import "@/abstracts/_variables";

    .main-text {
        font-weight: 500;
    }

    .page-notifications-list {
        overflow-y: auto;

        ul {
            list-style-type: none;

            &.notifications-list__items {
                height: calc(100vh - 335px);
                padding: 0;
                margin: 0 15px 0 0;
            }

            li {
                padding: 0 20px;
                min-height: 70px;
                background: rgba(64, 146, 196, 0.1);

                &:not(:last-of-type) {
                    border-bottom: 1px solid rgba(109, 110, 113, 0.3);
                }

                div {
                    padding: 12px 0;

                    .dots-wrapper {
                        align-items: center;
                    }
                }
            }
        }

        .viewed {
            background: #ffffff !important;
        }

        .page-notifications-list__control {
            font-weight: bold;
            font-size: 30px;
            line-height: 45px;
            color: #4092C4;
        }

        .page-main-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #524F63;
            display: flex;
            flex-direction: column;
        }

        .time {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #4092C4;
        }

        .remove-notification {
            min-width: 90px;
        }
    }

    a {
        color: #27AEDE;
    }

    ul {
        li {
            &:hover {
                background: #ebebeb !important;
                cursor: pointer;
            }
        }
    }
</style>

<style lang="scss">

    .no_notifications {
        svg {
            path {

            }
        }
    }
   .notifications-list__items{
       span.dots {
           cursor: pointer;

           .remove {
               &:hover {
                   path {
                       fill: #FF1B1B;
                   }
               }
           }
       }
   }
</style>
