<template>
    <div>
        <header class="mb-25">
            <router-link class="navbar-brand" :to="{name: 'auth-login'}">
               <img src="/images/AbleToRecords_Logo_Horizontal.svg" class="branding__logo" alt="logo">
            </router-link>
            <div class="d-flex">
                <div class="right-tools">
                    <div class="log-out" @click="logout" v-if="user">
                        <span class="p-0 log_out_icon" v-html="svg('logOutIcon')"></span>
                        <span class="p-0 log_out_hover" v-html="svg('logOut_hover')"></span>
                        <p>{{ $t('log_out') }}</p>
                    </div>
                </div>
                <div class="right-tools__language dropdown" v-click-outside="() => this.showLanguages = false">
                    <div class="d-flex align-items-center right-tools__content" data-toggle="dropdown" @click="toggleShowLanguages">
                        <div class="languageIcon">
                            <span v-html="svg('navbarLanguageIcon')"></span>
                        </div>
                        <p class="langCode">{{currentLanguage}}</p>
                        <span :class="{'transform':showLanguages}" v-html="svg('arrow_blue')"/>
                    </div>

                    <div class=" switchLnaguage" :class="{'d-block':showLanguages}">
                        <a class="dropdown-item change_language d-block"
                           v-text-ellipsis
                           :title="availableLanguage.full_name.charAt(0).toUpperCase() + availableLanguage.full_name.slice(1)"
                           id="setLang"
                           :class="{'active': availableLanguage.name == currentLanguage }"
                           v-for="availableLanguage in availableLanguages"
                           @click="setLanguage(availableLanguage.name)">
                            {{ availableLanguage.full_name.charAt(0).toUpperCase() + availableLanguage.full_name.slice(1) }}
                        </a>
                    </div>
                </div>
            </div>
        </header>



<!--        <div class="bredcrump">-->
<!--            <div class="bredcrump__item active">-->
<!--                <p class="bredcrump__item&#45;&#45;number">1</p>-->
<!--                <p class="bredcrump__item&#45;&#45;text">{{$t('company_admin_details')}}</p>-->
<!--            </div>-->
<!--            <span v-html="svg('bredcrumpArrow')"></span>-->
<!--            <div class="bredcrump__item">-->
<!--                <p class="bredcrump__item&#45;&#45;number">2</p>-->
<!--                <p class="bredcrump__item&#45;&#45;text">{{$t('company_details')}}</p>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>
<script>
    import ClickOutside from 'vue-click-outside';
    import AuthService    from "~/services/AuthService";
    import UserService from "~/services/UserService";
    import {mapGetters} from "vuex";

    export default{
        name: 'RegHeader',

        data() {
            return {
                AuthService: new AuthService(),
                UserService: new UserService(),
                showLanguages: false,
            }
        },

        computed: {
            ...mapGetters({
                user: 'auth/user',
                availableLanguages: 'lang/availableLanguages',
                currentLanguage: 'lang/currentLanguage',
            }),
        },

        methods:{
            logout: async function () {
                let [result, error] = await this.AuthService.logout();
                if (result) {
                    await this.$store.dispatch('auth/logout');
                    this.$router.push({name: 'auth-login'});
                } else {
                    this.$toast.error(this.$t('something_went_wrong'));
                }
            },

            toggleShowLanguages: function () {
                this.showLanguages = !this.showLanguages
            },
        },
        directives: {
            ClickOutside
        }
    }
</script>
<style lang="scss">
    .mb-25{
        margin-bottom: 25px;
    }

    .right-tools__content {
        padding: 10px 15px;
        border-radius: 5px;
        background: #F2F6FC;
        max-height: 50px;
        border: 1px solid transparent;
        cursor: pointer;
        margin-left: 20px;

        &:hover {
            border: 1px solid rgba(64, 146, 196, 0.7);

            .languageIcon {
                svg {
                    background-image: url(/images/navbarLanguageIconHover.svg);

                    path {
                        fill: none;
                    }
                }
            }
        }
    }
</style>
