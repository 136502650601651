import HttpService from "./HttpService";
import axios from "axios";

export default class DirectoryService extends HttpService{

    /**
     * Get directories
     *
     * @param data
     * @returns {Promise<any>}
     */
    getDirectories(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('get-directories', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(directories => directories.data).catch(err => err)
    }

    /**
     * Get records list
     *
     * @param data
     * @returns {Promise<any>}
     */
    getRecords(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('get-records', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(directories => directories.data).catch(err => err)
    }

    /**
     *
     * @param params
     * @param headers
     * @returns {Promise<any | never>}
     */
    saveRecord(params, headers = {}){

        headers = {...headers,
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('save-record', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(user => [user.data, null]).catch(err => [null, err])
    }

    /**
     * Get directory data
     *
     * @param data
     * @returns {Promise<any>}
     */
    getDirectoryData(id, params = {}, headers = {}){

        headers = {...headers,
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('edit-record/' + id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(directories => directories.data).catch(err =>  err)
    }

    /**
     * Get directory data
     *
     * @param data
     * @returns {Promise<any>}
     */
    getDirectoryHtml(id, params = {}, headers = {}){

        headers = {...headers,
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('get-record-pdf/' + id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(directories => directories.data).catch(err => err)
    }

    /**
     * Get directory field structure
     *
     * @param data
     * @returns {Promise<any>}
     */
    getDirectoryFieldsJson(id, params = {}, headers = {}){

        headers = {...headers,
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('get-directory-json/' + id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(directories => directories.data).catch(err => err)
    }

    /**
     * Delete record
     *
     * @param data
     * @returns {Promise<any>}
     */
    deleteRecord(id, params = {}, headers = {}){

        headers = {...headers,
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('delete-record/' + id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

     /**
     * Delete multiple record
     *
     * @param data
     * @returns {Promise<any>}
     */
    deleteMultipleRecord(params = {}, headers = {}){

        headers = {...headers,
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('delete-record-multiple', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Get record files
     *
     * @param id
     * @param params
     * @param headers
     * @returns {Promise<any | never>}
     */
    getFiles(id, params = {}, headers = {}){

        headers = {...headers,
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('get-record-files/' + id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(result => result.data).catch(err => err)
    }

    /**
     * Save record files
     *
     * @param id
     * @param params
     * @param headers
     * @returns {Promise<any | never>}
     */
    saveFiles(id, params = {}, headers = {}){

        headers = {...headers,
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'multipart/form-data'
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.post('save-record-files/' + id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Delete record files
     *
     * @param id
     * @param params
     * @param headers
     * @returns {Promise<any | never>}
     */
    removeFiles(id, params = {}, headers = {}){

        headers = {...headers,
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('delete-record-files/' + id, params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err =>  [null, err])
    }

    /**
     * Export
     *
     * @param params
     * @param headers
     * @returns {Promise<any | never>}
     */
    export( params = {}, headers = {}, additionalHeaders){

        headers = {...headers,
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('export', params, headers, additionalHeaders);

                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result, null]).catch(err => [null, err])
    }
    /**
     * Get user directory
     *
     * @param params
     * @param headers
     * @returns {Promise<any | never>}
     */
    getUserDirectory(id,  params = {}, headers = {}, additionalHeaders){

        headers = {...headers,
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('get-record/' + id, params, headers, additionalHeaders);

                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(result => result.data).catch(err => err)
    }

    /**
     * Export
     *
     * @param html
     * @returns {Promise<any | never>}
     */
    getPdfResult(html){
        //todo make options dinamic
        return new Promise((resolve, reject) => {
            axios.post(window.ENV.pdfGenerateUrl, {html: html, options: {
                    format: 'A4',
                    scale: 1,
                    pageRanges: ' ',
                    margin: {
                        top: 40,
                        right: 0,
                        bottom: 40,
                        left: 0
                    },
                    displayHeaderFooter: true,
                    headerTemplate: ' ',
                    footerTemplate: '<div style="' +
                        'margin-bottom: -18px;' +
                        'display:flex; ' +
                        'align-items: center; ' +
                        'justify-content:center;' +
                        'height:100% !important; ' +
                        'width: 100%!important;' +
                        'font-weight: 500!important;' +
                        'font-size: 11px!important;' +
                        'line-height: 16px!important;' +
                        'color:#4092C4!important;" ' +
                        'class="pdffootr">' +
                        '<span class="pageNumber"></span>' +
                        '</div>',
                    printBackground: true,
                    landscape: false,
                    width: '',
                    height: ''
}},  { responseType: 'blob' })
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    }


    /**
     * Delete multiple records
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    deleteMultipleRecords(params = {}, headers = {}){

        headers = {...headers,
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('delete-record-multiple', params, headers);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(result => result.data).catch(err => err)
    }
}
