<template>
    <div class="">
        <admin-header
            :headerText="text"
        />
        <slot></slot>
    </div>
</template>


<script>

    import AdminHeader from "~/components/bulkInvite/admins/AdminHeader";
    import AdminSidebar from "~/components/bulkInvite/admins/AdminSidebar";
    import {mapGetters} from "vuex";

    export default {
        name: 'AdminLayout',

        data: function() {
            return{
                mobileIsOpened: false,
                text: '',
                gsuiteOrOffice: '',
                domainData: [],
                orgUnitData: [],
                officeGroup: [],
            }
        },
        components: {
            AdminHeader,
            AdminSidebar,
        },

        computed: {
            ...mapGetters({
                user: 'auth/user'
            }),
        },


        created() {
            this.$root.$on('headerText', (text) => {
                this.text = text;
            });
            this.$root.$on('gsuiteOrOffice', (gsuiteOrOffice) => {
                this.gsuiteOrOffice = gsuiteOrOffice;
            });
            this.$root.$on('domainData', (domainData) => {
                this.domainData = domainData;
            });
            this.$root.$on('orgUnitData', (orgUnitData) => {
                this.orgUnitData = orgUnitData;
            });
            this.$root.$on('officeGroup', (officeGroup) => {
                this.officeGroup = officeGroup;
            });

            this.$store.getters['echo/connectionInstance'].private('user_' + this.user.id)
                .listen('.logout', async (e) => {
                    await this.$store.dispatch('auth/logout');
                    this.$router.push({name: 'auth-login'});
                })
        },

        methods: {
            mobileMenu: function () {
                this.mobileIsOpened = true;
            },

            closeMenu: function () {
                this.mobileIsOpened = false;
            },
            sortByType: function (type) {
                this.$root.$emit('changeSortByType', type);
            },

            setFilterValue: function (value) {
                this.$root.$emit('setFilterValue', value);
            }
        },

        watch: {
            '$route' () {
                this.mobileIsOpened = false;
            }
        }
    }
</script>
<style scoped lang="scss">
    @import "@/abstracts/_variables";
    @import "@/abstracts/_mixins";

   .main{
       background: #f2f6fc;
    }

</style>
