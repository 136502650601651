import * as types   from '~/store/mutation-types';

// state
export const state = {
    selectedHost: '',

    // servers: {
    //     iCloud: 'smtp.mail.me.com',
    //     Microsoft: 'smtp.office365.com',
    //     Yahoo: 'smtp.mail.yahoo.com',
    //     Gmail: 'smtp.gmail.com',
    // }
};

// getters
export const getters = {
    selectedHost: state => state.selectedHost,

    servers: state => state.servers,
};

// mutations
export const mutations = {
    [types.SET_SELECTED_HOST]: (state, value) =>  {
        state.selectedHost = value
    },
};

// actions
export const actions = {
    async setSelectedHost ({commit}, value) {
        commit(types.SET_SELECTED_HOST, value);
    },
}
