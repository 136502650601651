export const ENV = {
    "domain": "recordsystem.dg-datenschutz.de",
    "websiteUrl": "https://recordsystem.dg-datenschutz.de/",
    "pdfGenerateUrl": "https://pdfgenerator.ableto404.com/pdf/generate",
    "legalDocsUrl": "https://willing-able.com/wp-json/legaltech/v1/pdf/1",
    "socketHost": "https://recordsystem.dg-datenschutz.de:6011",
    "defaultColorScheme": {r: 39, g: 174, b: 222},
    "legalDocIds": {
        "privacy_policy": 3,
        "transparency_doc": 909,
        "tech_and_org_measures": 933,
        "user_policy": 935,
        "saas_subscriptio": 70,
        "imprint": 0,
    },
"LEGAL_DOCUMENT_URLS": {
        "privacy_policy": "https://willing-able.com/privacy-policy/",
        "transparency_doc": "https://willing-able.com/transparency-document/",
        "tech_and_org_measures": "https://willing-able.com/technical-and-organizational-measures/",
        "user_policy": "https://willing-able.com/user-agreement/",
        "saas_subscription": "https://willing-able.com/saas-subscription-and-data-processing-agreement/",
        "terms_and_conditions": "https://willing-able.com/gdpr-terms-and-conditions/",
        "list_of_processors": "https://willing-able.com/list-processors/",
        "imprint": "https://willing-able.com/imprint",
        "dpia": "/DPIA.pdf"
    },
"WILLING_URL": "https://willing-able.com"
};
