<template>
    <div style="margin: 15px 15px 0 0">
        <div :class="{'big_icon' : !smallIcon}" class="close_icon" @click="$emit('hide-modal')">
            <img :src="'/images/smtpConfigImages/closeModal.svg'">
        </div>
    </div>
</template>

<script>
    export default {
        name: "closeModal",

        props: [
            'smallIcon'
        ]
    }
</script>

<style lang="scss">

    .close_icon {
        cursor: pointer;
        width: 32px;
        height: 32px;
        margin-left: auto;
        opacity: 60%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: #F4F5F8;
            border-radius: 4px;
            opacity: 85%;
        }

        img {
            width: 16px;
            height: 16px;
        }
    }

    .big_icon {
        width: 36px;
        height: 36px;
        img {
            width: 20px;
            height: 20px;
        }
    }

</style>
