<template>
    <div class="sorts">
        <div v-if="gsuiteOrOffice === 'gsuite'">
            <div class="sort_by">
                <div>
                    <label class="container1 m-0">
                        <input class="radio-blue"
                            v-model="sortBy"
                            type="radio"
                            value="organizationalUnit">
                        <span class="radio-blue-label"></span>
                    </label>
                    <label class="labelText">{{$t('sort_by_organizational_unit')}}</label>
                </div>
                <div>
                    <label class="container1 m-0">
                        <input class="radio-blue"
                            v-model="sortBy"
                            type="radio"
                            name="domain_unit"
                            value="domainName">
                        <span class="radio-blue-label"></span>
                    </label>
                    <label class="labelText">{{$t('sort_by_domain_name')}}</label>
                </div>
            </div>
            <div class="filter">
                <div v-if="sortBy === 'organizationalUnit'" class="accordion">
                    <tree
                        :tree-data="orgUnitData"
                        :selectedUnitsOrDomains="selectedUnitsOrDomains"
                        v-on:set-org-units="selectOrgUnits"/>
                </div>
                <div v-if="sortBy === 'domainName'" class="domain-name">
                    <div v-for="(domains, i) in domainData" class="domain_item">
                        <label class="cursor-pointer mb-0" :for="'domainName' + i">
                            <p>{{domains.domainName}}</p>
                        </label>
                        <label class="container1 m-0">
                            <input type="checkbox"
                                   :id="'domainName' + i"
                                   :value="domains.domainName"
                                   v-model="selectedUnitsOrDomains"
                                   @change="selectedDomain">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="gsuiteOrOffice === 'office'">
            <div class="group">
                <h3>{{$t('groups').toUpperCase()}}</h3>
            </div>
            <div class="filter" v-if="officeGroups.length">
                <div class="domain-name">
                    <div class="domain_item" v-for="(group, i) in officeGroups">
                        <label class="cursor-pointer mb-0" :for="'groupNme' + i"><p>{{group.displayName}}</p></label>
                        <label class="container1 m-0">
                            <input
                                :id="'groupNme' + i"
                                type="checkbox"
                                :value="group.id"
                                v-model="selectedGroups"
                                @change="groupSelected"
                            >
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import Tree from "~/components/bulkInvite/recursiveTree/Tree";

export default {
    name: 'AdminSidebar',

    props: [
        'gsuiteOrOffice',
        'domainData',
        'orgUnitData',
        'officeGroups',
        'filterType',
    ],

    data() {
        return {
            sortBy: this.filterType,
            organizationalUnit: true,
            domainName: false,
            sortByType: '',
            selectedGroups: [],
            selectedUnitsOrDomains: [],
            filterDomainName: '',
            count: 0,
        }
    },

    methods: {
        selectOrgUnits: function (val) {
            this.selectedUnitsOrDomains = val;
            this.$emit('set-filter-data', 'selectedUnitsOrDomains', this.selectedUnitsOrDomains);
        },

        selectedDomain: function () {
            this.$emit('set-filter-data', 'selectedUnitsOrDomains', this.selectedUnitsOrDomains);
        },

        groupSelected: function () {
            this.$emit('set-filter-data', 'selectedGroups', this.selectedGroups);
        },

        changeSortType: function (event) {
            this.sortByType = event.target.value;
            this.selectedUnitsOrDomains = [];
            if (this.sortByType === 'organizationalUnit') {
                this.organizationalUnit = true;
                this.domainName = false;
            } else {
                this.domainName = true;
                this.organizationalUnit = false;
            }
            this.$emit('set-sort-by-type', this.sortByType);
        },

    },

    components: {
        Tree
    },

    watch: {
        selectedGroups: function (val) {
            this.$emit('set-filter-data', 'selectedGroups', val);
        },


        sortBy: function (val) {
            this.$emit('set-filter-data', 'filterType', val);
        },
    }
}
</script>
<style lang="scss">
@import "@/abstracts/_variables";
@import "@/abstracts/_mixins";


.sortByType {
    &:before {
        display: none;
    }
}

.sorts {
    background: #FFFFFF;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 0 23px;
    height: 100%;

    .sort_by {
        background: $mainOpacity;
        border-radius: 0 0 6px 6px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        & > div {
            display: flex;
            align-items: center;

            .container1 {
                span {
                    &.checkmark {
                        position: absolute;
                        top: -10px;
                        left: 0;
                        height: 16px;
                        width: 16px;
                        background-color: #fff;
                        border: 1.5px solid #524F63;
                        border-radius: 50%;

                        &::after {
                            left: 50%;
                            top: 50%;
                            min-width: 8px;
                            max-width: 8px;
                            max-height: 8px;
                            min-height: 8px;
                            background: $mainColor;
                            border-radius: 50%;
                            border: none;
                            transform: translate(-50%, -50%);
                        }
                    }
                }

                input:checked ~ .checkmark {
                    background-color: transparent;
                    border: 1.5px solid $mainColor;
                }
            }

            .labelText {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #524F63;
                margin-left: 5px;
                margin-bottom: 0;
            }
        }
    }

    .group {
        padding: 20px 20px 0 20px;

        h3 {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: $mainColor;
        }

        & + .filter {
            padding-top: 0;
        }
    }

    .filter {
        padding-top: 20%;

        .card {
            border-left: none;
            border-right: none;

            &:first-of-type {
                border-top: none;
            }

            &-header {
                background: none;
                padding: 5px 0 5px 15px;

                h2 {
                    display: flex;

                    button {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: $mainColor;
                        position: relative;

                        &.btn {
                            &::before {
                                content: '';
                                width: 7px;
                                height: 7px;
                                border-top: 7px solid $mainColor;
                                border-left: 7px solid transparent;
                                border-right: 7px solid transparent;
                                position: absolute;
                                left: -10px;
                                top: 15px;
                            }

                            &.collapsed {
                                &::before {
                                    transform: rotate(270deg);
                                }
                            }
                        }

                    }

                    label {
                        top: 8px;
                    }
                }
            }

            .card-body {
                margin-left: 15px;
                display: flex;
                padding: 5px 0 5px 0;
                justify-content: space-between;

                p {
                    margin-bottom: 0;
                    margin-left: 12px;
                }

                .accordion, .card {
                    width: 100%;
                }

                .card, .card-header {
                    border: none;

                    h2 button {
                        font-weight: 500;
                        color: #524F63;

                        &.btn {
                            &::before {
                                content: '';
                                width: 6px;
                                height: 6px;
                                border-top: 6px solid $mainColor;
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                            }
                        }

                    }
                }
            }
        }

        .domain-name {
            padding: 0 10px;

            .domain_item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgba(82, 79, 99, 0.2);
                padding: 10px;

                p {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #524F63;
                    margin-bottom: 0;
                }

                .checkmark {
                    top: -9px !important;
                }
            }
        }

        @include media(992) {
            padding-top: 25px;
        }
    }
}

</style>
