import * as types from '~/store/mutation-types';
import NotificationService from "../../services/NotificationService";

// state
export const state = {
    notifications: null,
};

// getters
export const getters = {
    notifications: state => state.notifications,
};

// mutations
export const mutations = {
    [types.SET_NOTIFICATIONS] : (state, data) => { state.notifications = data.notifications}
};

// actions
export const actions = {
    async getUnreadNotificationsCount ({commit}) {
        try {
            let notifications = new NotificationService();
            const notification = await notifications.getNotificationsCountByStatus({'status': [0]});
            commit(types.SET_NOTIFICATIONS, { notifications: notification })
        }catch (e) {
            console.log('error')
        }
    },

    clearNotificationsData ({commit}) {
        state.notifications = null;
    },
}
