<template>
    <div id="app">
        <vue-snotify></vue-snotify>
        <loading ref="loading"/>
        <loader/>
        <div>
            <transition name="fade" mode="out-in">
                <component :is="layout" >
                    <router-view :key="$route.path"/>
                </component>
            </transition>
            <TheCookieBanner />
        </div>
    </div>
</template>

<script>
    import layouts          from '~/layouts';
    import Loader           from '~/components/Loader';
    import Loading          from '~/components/Loading';
    import TheCookieBanner from "~/components/cookie_banner/TheCookieBanner";

    import { mapGetters }   from "vuex";

    export default {
        el: '#app',

        data: () => ({
            layout: null,
            defaultLayout: 'main'
        }),


        computed: {
            ...mapGetters({
                user: 'auth/user'
            }),

            messagesLoaded: function (){
                if(this.$i18n.messages[this.$store.getters['lang/currentLanguage']]){
                    if(Object.keys(this.$i18n.messages[this.$i18n.locale]).length){
                        return true;
                    }
                }

                return false;
            }
        },

        components:{
            Loader,
            Loading,
            TheCookieBanner
        },

        methods: {
            /**
             * Set the application layout.
             *
             * @param {String} layout
             */
            setLayout(layout) {
                if (!layout || !layouts[layout]) {
                    layout = this.defaultLayout
                }
                this.layout = layouts[layout]
            }
        },

        mounted () {
            this.$loading = this.$refs.loading
        },

        watch: {
            $route: {
                immediate: true,
                async handler(route) {
                    this.layout = layouts[route.meta.layout];
                }
            },
        }

    }
</script>
