import store from '~/store';

export default async function lang() {

    if (!store.getters['timezone/serverTimezone']) {
        try {
            await store.dispatch('timezone/setTimezones');
        } catch (e) {
            return false;
        }
    }

    return true;
}
