<template>
    <div class="tree">
        <div class="tree-list">
            <node-tree
                :node="treeData"
                v-on:add-org-unit="addOrgUnit"
                v-on:remove-org-unit="removeOrgUnit"
            />

        </div>
    </div>
</template>

<script>
    import NodeTree from "./NodeTree";

    export default {
        props: [
            'treeData'
        ],
        components: {
            NodeTree
        },

        data() {
            return {
                selectedOrgUnits: []
            }
        },
        methods: {
            selectAll: function(data){
                this.treeData.children.map(child => {
                    if(child.orgUnitPath == data.parentOrgUnitPath){
                        child.checked = child.children.every(item => item.checked == true);
                    }
                })
            },

            recursive(data, check = true) {
                data.checked = check;
                if(check){
                    this.selectedOrgUnits.push(data.orgUnitPath);
                }
                else{
                    let index = this.selectedOrgUnits.indexOf(data.orgUnitPath);
                    if (index > -1) {
                        this.selectedOrgUnits.splice(index, 1);
                    }
                }
                if (data.children && data.children.length) {
                    data.children.forEach(item => {
                        this.recursive(item, check)
                    })
                }
            },

            addOrgUnit: function (value, data) {
                this.recursive(data, true)
                //this.selectAll(data);
                this.$emit('set-org-units', this.selectedOrgUnits, value);
            },

            removeOrgUnit: function (value, data) {

                this.recursive(data, false)
                // if (data.children && data.children.length) {
                //     data.children.map(item => item.checked = false)
                // }
                // this.selectAll(data);
                this.$emit('set-org-units', this.selectedOrgUnits, value);
            },
        }
    };
</script>
