import SystemService from "~/services/SystemService";
import * as types    from '~/store/mutation-types';
import moment from "moment-timezone";

// state
export const state = {
    serverTimezone: null,
    browserTimezone: null
};

// getters
export const getters = {
    serverTimezone: state => state.serverTimezone,
    browserTimezone: state => state.browserTimezone
};

// mutations
export const mutations = {
    [types.SET_SERVER_TIMEZONE] : (state, data) => { state.serverTimezone = data.serverTimezone},
    [types.SET_BROWSER_TIMEZONE] : (state, data) => { state.browserTimezone = data.browserTimezone}
};

// actions
export const actions = {
    async setTimezones ({commit}) {
        try {
            let systemService = new SystemService();
            const serverTimezone = await systemService.getServerTimezone();
            commit(types.SET_SERVER_TIMEZONE, { serverTimezone: serverTimezone });
            commit(types.SET_BROWSER_TIMEZONE, { browserTimezone: new Date().getTimezoneOffset() });
        }catch (e) {
            console.log(e);
        }
    },
}
