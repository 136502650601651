
/* Login */
const Login = () => import('~/pages/auth/Login').then(m => m.default || m);
const AuthLoginAs = () => import('~/pages/auth/LoginAs').then(m => m.default || m);
const Token = () => import('~/pages/auth/Token').then(m => m.default || m);
const Registration = () => import('~/pages/auth/Registration').then(m => m.default || m);
const AcceptInvitation = () => import('~/pages/auth/AcceptInvitation').then(m => m.default || m);
const CompanyDetails = () => import('~/pages/auth/CompanyDetails').then(m => m.default || m);
const ForgotPassword = () => import('~/pages/auth/password/ForgotPassword').then(m => m.default || m);
const ResetPassword = () => import('~/pages/auth/password/ResetPassword').then(m => m.default || m);
const ChangePassword = () => import('~/pages/auth/ChangePassword').then(m => m.default || m);
const ActivateAccount = () => import('~/pages/auth/ActivateAccount').then(m => m.default || m);
const VerifyEmail = () => import('~/pages/auth/VerifyEmail').then(m => m.default || m);
const Consent = () => import('~/pages/auth/Consent').then(m => m.default || m);
const ConfigureTwoFactor = () => import('~/pages/auth/ConfigureTwoFactor').then(m => m.default || m);

/* Home */
const Home = () => import('~/pages/Home').then(m => m.default || m);

/* Two Factor Settings */
const TwoFactorSettings = () => import('~/pages/twoFactorSettings/TwoFactorSettings.vue').then(m => m.default || m);

/* User Directories */
const All = () => import('~/pages/directories/All').then(m => m.default || m);
const CreateDirectory = () => import('~/pages/directories/Create').then(m => m.default || m);
const EditDirectory = () => import('~/pages/directories/Edit').then(m => m.default || m);
const DirectoryFiles = () => import('~/components/directories/DirectoryFiles').then(m => m.default || m);
import ViewDirectory from "~/pages/directories/View";

/* Export-Import Components */
const ExportImport = () => import('~/pages/exportImport/ExportImport').then(m => m.default || m);

/* User Notifications */
const Notifications = () => import('~/pages/notifications/Notifications').then(m => m.default || m);

/* Suggested Records */
const AllSuggestedRecords = () => import('~/pages/suggestedRecords/All').then(m => m.default || m);
const EditSuggestedRecord = () => import('~/pages/suggestedRecords/Edit').then(m => m.default || m);

/* Prefilled Records */
const AllPrefilledRecords = () => import('~/pages/prefilledRecords/All').then(m => m.default || m);
const EditPrefilledRecord = () => import('~/pages/prefilledRecords/Edit').then(m => m.default || m);
const CreatePrefilledRecord = () => import('~/pages/prefilledRecords/Create').then(m => m.default || m);
const RecordFiles = () => import('~/components/prefilledRecords/RecordFiles').then(m => m.default || m);

/* Prefilled Record Translations */
const AllPrefilledRecordsTranslations = () => import('~/pages/prefilledRecordTranslation/All').then(m => m.default || m);
const EditPrefilledRecordsTranslations = () => import('~/pages/prefilledRecordTranslation/Edit').then(m => m.default || m);

/* User Prefilled Record */
const UserAllPrefilledRecords = () => import('~/pages/userPrefilledRecords/All').then(m => m.default || m);
const UserCreateFromPrefilled = () => import('~/pages/userPrefilledRecords/Create').then(m => m.default || m);

/* Partners */
const AllPartners = () => import('~/pages/partners/All').then(m => m.default || m);
const AddPartner = () => import('~/pages/partners/Add').then(m => m.default || m);
const EditPartner = () => import('~/pages/partners/Edit').then(m => m.default || m);
/*const SystemAdmin = () => import('~/pages/partners/systemAdmin/All').then(m => m.default || m);
const EditSystemAdmin = () => import('~/pages/partners/systemAdmin/Edit').then(m => m.default || m);*/
const EditBilling = () => import('~/pages/partners/billingDetails/Edit').then(m => m.default || m);
const InvoiceHistory = () => import('~/pages/partners/billingDetails/Invoice').then(m => m.default || m);


/* Categories */
const AllCategories = () => import('~/pages/categories/All').then(m => m.default || m);

/* Companies */
const AllCompanies = () => import('~/pages/companies/All').then(m => m.default || m);
const CreateCompany = () => import('~/pages/companies/Create').then(m => m.default || m);
const EditCompany = () => import('~/pages/companies/Edit').then(m => m.default || m);

/* Risk Categories */
const AllRiskCategories = () => import('~/pages/riskCategories/All').then(m => m.default || m);

/* Risks */
const AllRisks = () => import('~/pages/risks/All').then(m => m.default || m);

/* Risks Measures*/
const AllReducingMeasure = () => import('~/pages/risks/reducingMeasure/All').then(m => m.default || m);

/* LoginAs */
const LoginAs = () => import('~/pages/login/LoginAs').then(m => m.default || m);

/* Login History */
const AllHistory = () => import('~/pages/login/All.vue').then(m => m.default || m);

/* DPOS */
const AllDpos = () => import('~/pages/dpos/All').then(m => m.default || m);
const CreateDpo = () => import('~/pages/dpos/Create').then(m => m.default || m);
const EditDpo = () => import('~/pages/dpos/Edit').then(m => m.default || m);
const ViewDpo = () => import('~/pages/dpos/View').then(m => m.default || m);

/* Persons */
const AllPersons = () => import('~/pages/persons/All').then(m => m.default || m);
const CreatePerson = () => import('~/pages/persons/Create').then(m => m.default || m);
const EditPerson = () => import('~/pages/persons/Edit').then(m => m.default || m);

/* Locations */
const AllLocations = () => import('~/pages/locations/All').then(m => m.default || m);
const CreateLocation = () => import('~/pages/locations/Create').then(m => m.default || m);
const EditLocation = () => import('~/pages/locations/Edit').then(m => m.default || m);

/* Users */
const AllUsers = () => import('~/pages/users/All').then(m => m.default || m);
const EditUser = () => import('~/pages/users/Edit').then(m => m.default || m);
const InactiveUsers = () => import('~/pages/users/InactiveUsers').then(m => m.default || m);

/* Invitations */
const AllInvitations = () => import('~/pages/invitations/All').then(m => m.default || m);

/* Testimonials */
const AllRegistrationRequests = () => import('~/pages/registrationRequests/All').then(m => m.default || m);

/* Profile */
const Profile = () => import('~/pages/Profile').then(m => m.default || m);

/* Subscription And Billing */
const ChangeThePlan = () => import('~/pages/subscriptionAndBilling/ChangeThePlan').then(m => m.default || m);
const EditBillingDetails = () => import('~/pages/subscriptionAndBilling/EditBillingDetails').then(m => m.default || m);

/* Category Translations */
const AllCategoryTranslations = () => import('~/pages/adminTranslations/categoryTranslations/All').then(m => m.default || m);
const EditCategoryTranslations = () => import('~/pages/adminTranslations/categoryTranslations/Edit').then(m => m.default || m);
const SingleCategoryTranslations = () => import('~/pages/adminTranslations/categoryTranslations/Single').then(m => m.default || m);

/* Translations */
const AllSystemTranslations = () => import('~/pages/adminTranslations/systemTranslations/All').then(m => m.default || m);
const EditSystemTranslations = () => import('~/pages/adminTranslations/systemTranslations/Edit').then(m => m.default || m);
const SingleSystemTranslations = () => import('~/pages/adminTranslations/systemTranslations/Single').then(m => m.default || m);

/* Risk| Risk Category| Risk Reducing Measure Translations */
const AllRiskTranslations = () => import('~/pages/adminTranslations/riskTranslations/All').then(m => m.default || m);
const EditRiskTranslations = () => import('~/pages/adminTranslations/riskTranslations/Edit').then(m => m.default || m);
const SingleRiskTranslations = () => import('~/pages/adminTranslations/riskTranslations/Single').then(m => m.default || m);

/* Retention Periods */
const ALLRetentionPeriods = () => import('~/pages/retentionPeriods/All').then(m => m.default || m);
const CreateRetentionPeriods = () => import('~/pages/retentionPeriods/Create').then(m => m.default || m);
const EditRetentionPeriods = () => import('~/pages/retentionPeriods/Edit').then(m => m.default || m);

/* Bulk Invite */
const GoogleAll = () => import('~/pages/bulkInvite/GoogleAll').then(m => m.default || m);
const MicrosoftAll = () => import('~/pages/bulkInvite/MicrosoftAll').then(m => m.default || m);
const XLSXAll = () => import('~/pages/bulkInvite/XLSXAll').then(m => m.default || m);
const ImportedList = () => import('~/pages/bulkInvite/ImportedList').then(m => m.default || m);

/* Messages */
const Messages = () => import('~/pages/Messages').then(m => m.default || m);

/* Not active Subscription */
const NoActiveSubscription = () => import('~/pages/auth/subscription/NoActiveSubscription').then(m => m.default || m);
const ConfigurePayment = () => import('~/pages/auth/subscription/ConfigurePayment').then(m => m.default || m);
const SelectPlan = () => import('~/pages/auth/subscription/SelectPlan').then(m => m.default || m);
const ManageResources = () => import('~/pages/auth/subscription/ManageResources').then(m => m.default || m);
const ViewRecordPdf = () => import('~/pages/auth/subscription/ViewPdf').then(m => m.default || m);

/* Error */
const NotFound = () => import('~/pages/NotFound').then(m => m.default || m);

/* Document */
const Downloads = () => import('~/pages/document/Downloads').then(m => m.default || m);
const Policies = () => import('~/pages/document/Policies').then(m => m.default || m);
const ViewDocument = () => import('~/pages/document/View').then(m => m.default || m);

/* User Document */
const UserDownloads = () => import('~/pages/userDocument/Downloads').then(m => m.default || m);
const UserPolicies = () => import('~/pages/userDocument/Policies').then(m => m.default || m);
const UserViewDocument = () => import('~/pages/userDocument/View').then(m => m.default || m);

/* policies */
const AllPolicies = () => import('~/pages/policies/All').then(m => m.default || m);

export default [

    {
        path: '/',
        name: 'home',
        props: true,
        component: Home,
        meta: {
            middleware: ['auth', 'scope:access_all'],
            layout: 'main',
            title: 'home'
        },
    },
    {
        path: '/login/',
        name: 'login',
        redirect: 'auth/login',
    },
    {
        path: '/message/',
        name: 'message',
        component: Messages,
        props: true,
        meta: {
            middleware: 'isGuest',
            layout: 'auth'
        },
    },
    {
        path: '/auth/',
        component: { template: '<router-view/>' },
        name: 'auth',
        children: [
            {
                path: 'login',
                name: 'auth-login',
                props: true,
                component: Login,
                meta: {
                    middleware: 'isGuest',
                    layout: 'auth'
                },
            },
            {
                path: 'registration',
                name: 'auth-registration',
                component: Registration,
                props: true,
                meta: {
                    middleware: 'isGuest',
                    layout: 'registration'
                },
            },
            {
                path: 'invited',
                name: 'auth-invited',
                component: AcceptInvitation,
                props: true,
                meta: {
                    middleware: 'isGuest',
                    layout: 'auth'
                },
            },
            {
                path: 'two-factor-settings',
                name: 'auth-two-factor-settings',
                component: TwoFactorSettings,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:change_password'],
                    layout: 'auth'
                },
            },
            {
                path: 'company-details',
                name: 'auth-company-details',
                component: CompanyDetails,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:fill_company_details'],
                    layout: 'registration'
                },
            },
            {
                path: 'consent',
                name: 'auth-consent',
                component: Consent,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:give_registration_consent'],
                    layout: 'auth'
                },
            },
            {
                path: 'configure-two-factor',
                name: 'auth-configure-two-factor',
                component: ConfigureTwoFactor,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:configure_two_factor'],
                    layout: 'auth'
                },
            },
            {
                path: 'configure-payment',
                name: 'auth-configure-payment',
                component: ConfigurePayment,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:configure_payment'],
                    layout: 'registration'
                },
            },
            {
                path: 'select-plan',
                name: 'auth-select-plan',
                component: SelectPlan,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:configure_payment'],
                    layout: 'registration'
                },
            },
            {
                path: 'manage-resources',
                name: 'auth-manage-resources',
                component: ManageResources,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:configure_payment'],
                    layout: 'registration'
                },
            },
            {
                path: 'view-record-pdf/:id',
                name: 'auth-view-record-pdf',
                component: ViewRecordPdf,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:configure_payment'],
                    layout: 'registration'
                },
            },
            {
                path: 'no-active-subscription',
                name: 'auth-no-active-subscription',
                component: NoActiveSubscription,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:no_active_payment'],
                    layout: 'auth'
                },
            },
            {
                path: 'token',
                name: 'auth-token',
                component: Token,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:set_two_factor_token'],
                    layout: 'auth'
                },
            },
            {
                path: 'change-password',
                name: 'auth-change-password',
                component: ChangePassword,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:change_password'],
                    layout: 'auth'
                },
            },
            {
                path: 'activate-account',
                name: 'auth-activate-account',
                component: ActivateAccount,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:deactivated_by_user'],
                    layout: 'auth'
                },
            },
            {
                path: 'verify-email/:token',
                name: 'auth-verify-email',
                component: VerifyEmail,
                props: true,
                meta: {
                    middleware: ['auth', 'scope:access_all'],
                    layout: 'auth'
                },
            },
            {
                path: 'forgot-password',
                name: 'auth-forgot-password',
                component: ForgotPassword,
                props: true,
                meta: {
                    middleware: 'isGuest',
                    layout: 'auth'
                },
            },
            {
                path: 'password/reset/:token',
                name: 'auth-password-reset',
                component: ResetPassword,
                props: true,
                meta: {
                    middleware: 'isGuest',
                    layout: 'auth'
                },
            },
            {
                path: 'login_as/login/:token',
                name: 'auth-login-as-login',
                component: AuthLoginAs,
                props: true,
                meta: {
                    middleware: 'isGuest',
                    layout: 'auth'
                },
            },
        ]
    },
    {
        path: '/user/',
        component: { template: '<router-view/>' },
        children: [
            {
                path: '/',
                redirect: '/user/home'
            },
            {
                path: 'home',
                name: 'user-home',
                component: Home,
                props: true,
                meta: {
                    middleware: ['auth', 'role:user,13', 'scope:access_all'],
                    layout: 'main',
                    title: 'home'
                },
            },
            {
                path: 'profile/edit',
                name: 'user-profile-edit',
                redirect: 'profile/edit/account-settings',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'account-settings',
                        name: 'user-profile-edit-account-settings',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'privacy-and-security',
                        name: 'user-profile-edit-privacy-and-security',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'g-d-p-r-compliance',
                        name: 'user-profile-edit-g-d-p-r-compliance',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                ]
            },
            {
                path: 'notifications',
                name: 'user-notifications',
                component: Notifications,
                meta: {
                    middleware: ['auth', 'role:user,13', 'scope:access_all'],
                    layout: 'main',
                    title: 'notifications'
                },
            },
            {
                path: 'directory',
                name: 'user-directory',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'user-directory-all',
                        component: All,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all'],
                            layout: 'main',
                            title: 'records'
                        },
                    },
                    {
                        path: 'create/:id',
                        name: 'user-directory-create',
                        component: CreateDirectory,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all'],
                            layout: 'main',
                            title: 'records'
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'user-directory-edit',
                        component: EditDirectory,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all'],
                            layout: 'main',
                            title: 'records'
                        },
                    },
                    {
                        path: 'view/:id',
                        name: 'user-directory-view',
                        component: ViewDirectory,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all'],
                            layout: 'main',
                            title: 'records'
                        },
                    },
                    {
                        path: 'files/:id',
                        name: 'user-directory-files',
                        component: DirectoryFiles,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all'],
                            layout: 'main',
                            title: 'records'
                        },
                    },
                ]
            },
            {
                path: 'prefilled-record',
                name: 'user-prefilled-record',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'user-prefilled-record-all',
                        component: UserAllPrefilledRecords,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all', 'accessLevel:access_all'],
                            layout: 'main',
                            title: 'prefilled_records'
                        },
                    },
                    {
                        path: 'create/:id',
                        name: 'user-prefilled-record-create',
                        component: UserCreateFromPrefilled,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all', 'accessLevel:access_all'],
                            layout: 'main',
                            title: 'prefilled_records'
                        },
                    }
                ]
            },
            {
                path: 'export-import',
                name: 'user-directory-export-import',
                component: ExportImport,
                meta: {
                    middleware: ['auth', 'role:user,13', 'scope:access_all', 'accessLevel:access_all'],
                    layout: 'main',
                    title: 'export-import'
                },

            },
            {
                path: 'document',
                name: 'user-document',
                redirect: 'document/downloads/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'downloads/all',
                        name: 'user-document-download-all',
                        component: UserDownloads,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all'],
                            layout: 'main',
                            title: 'downloads'
                        },
                    },
                    {
                        path: 'policies/all',
                        name: 'user-document-policy-all',
                        component: UserPolicies,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all'],
                            layout: 'main',
                            title: 'policies'
                        },
                    },
                    {
                        path: 'view/:page/:file/:fileName',
                        name: 'user-document-view',
                        component: UserViewDocument,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:user,13', 'scope:access_all'],
                            layout: 'main',
                            title: 'document'
                        },
                    },
                ],
            },
        ]
    },
    {
        path: '/company-admin/',
        component: { template: '<router-view/>' },
        children: [
            {
                path: '/',
                redirect: '/company-admin/home'
            },
            {
                path: 'home',
                name: 'company-admin-home',
                component: Home,
                props: true,
                meta: {
                    middleware: ['auth', 'role:company_admin,10,11,12', 'scope:access_all'],
                    layout: 'main',
                    title: 'home'
                },
            },
            {
                path: 'notifications',
                name: 'company-admin-notifications',
                component: Notifications,
                meta: {
                    middleware: ['auth', 'role:company_admin,10,11,12', 'scope:access_all'],
                    layout: 'main',
                    title: 'notifications'
                },
            },
            {
                path: 'profile/edit',
                name: 'company-admin-profile-edit',
                redirect: 'profile/edit/account-settings',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'account-settings',
                        name: 'company-admin-profile-edit-account-settings',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11,12', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'privacy-and-security',
                        name: 'company-admin-profile-edit-privacy-and-security',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11,12', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'g-d-p-r-compliance',
                        name: 'company-admin-profile-edit-g-d-p-r-compliance',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11,12', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'company-settings',
                        name: 'company-admin-profile-edit-company-settings',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'email-settings',
                        name: 'company-admin-profile-edit-email-settings',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'domain-settings',
                        name: 'company-admin-profile-edit-domain-settings',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'subscription-and-billing',
                        name: 'company-admin-profile-edit-subscription-and-billing',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,11', 'scope:access_all,access_subscription'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'system-security',
                        name: 'company-admin-profile-edit-system-security',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },

                    {
                        path: 'connect-to-cloud',
                        name: 'company-admin-profile-edit-connect-to-cloud',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },

                ]
            },
            {
                path: 'subscription',
                name: 'company-admin-subscription',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'change',
                        name: 'company-admin-subscription-change-the-plan',
                        component: ChangeThePlan,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,11', 'scope:access_all,access_subscription'],
                            layout: 'main',
                            title: 'subscription_and_billing'
                        },
                    },
                    {
                        path: 'edit',
                        name: 'company-admin-subscription-edit-billing-details',
                        component: EditBillingDetails,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,11', 'scope:access_all,access_subscription'],
                            layout: 'main',
                            title: 'subscription_and_billing'
                        },
                    },
                ]
            },
            {
                path: 'records',
                name: 'company-admin-records',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'suggested',
                        name: 'company-admin-record-suggested',
                        component: { template: '<router-view/>' },
                        children: [
                            {
                                path: 'all',
                                name: 'company-admin-record-suggested-all',
                                component: AllSuggestedRecords,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'records'
                                },
                            },
                            {
                                path: 'edit/:id',
                                name: 'company-admin-record-suggested-edit',
                                component: EditSuggestedRecord,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'records'
                                },
                            }
                        ]

                    },
                    {
                        path: 'prefilled',
                        name: 'company-admin-record-prefilled',
                        component: { template: '<router-view/>' },
                        children: [
                            // {
                            //     path: 'all',
                            //     name: 'company-admin-record-prefilled-all',
                            //     component: AllPrefilledRecords,
                            //     props: true,
                            //     meta: {
                            //         middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            //         layout: 'main',
                            //         title: 'prefilled_records'
                            //     },
                            // },
                            {
                                path: 'create/:id',
                                name: 'company-admin-record-prefilled-create',
                                component: CreatePrefilledRecord,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'prefilled_records'
                                },
                            },
                            {
                                path: 'edit/:id',
                                name: 'company-admin-record-prefilled-edit',
                                component: EditPrefilledRecord,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'prefilled_records'
                                },
                            },
                            {
                                path: 'translation',
                                name: 'company-admin-record-prefilled-translation',
                                component: { template: '<router-view/>' },
                                children: [
                                    {
                                        path: 'all',
                                        name: 'company-admin-record-prefilled-translation-all',
                                        component: AllPrefilledRecordsTranslations,
                                        props: true,
                                        meta: {
                                            middleware: ['auth', 'role:company_admin,10,11,12', 'scope:access_all'],
                                            layout: 'main',
                                            title: 'translations'
                                        },
                                    },
                                    {
                                        path: 'edit/:id',
                                        name: 'company-admin-record-prefilled-translation-edit',
                                        component: EditPrefilledRecordsTranslations,
                                        props: true,
                                        meta: {
                                            middleware: ['auth', 'role:company_admin,10,11,12', 'scope:access_all'],
                                            layout: 'main',
                                            title: 'translations'
                                        },
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                path: 'registration-requests',
                redirect: 'registration-requests/all',
                name: 'company-admin-registration-requests',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'company-admin-registration-requests-all',
                        component: AllRegistrationRequests,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'registration_requests'
                        },
                    },
                ]
            },
            {
                path: 'dpo',
                name: 'company-admin-dpos',
                redirect: 'dpo/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'company-admin-dpo-all',
                        component: AllDpos,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'dpo'
                        },
                    },
                    {
                        path: 'create',
                        name: 'company-admin-dpo-create',
                        component: CreateDpo,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'dpo'
                        },
                    },
                    {
                        path: 'view/:id',
                        name: 'company-admin-dpo-view',
                        component: ViewDpo,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'dpo'
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'company-admin-dpo-edit',
                        component: EditDpo,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'dpo'
                        },
                    },
                ]
            },
            {
                path: 'user',
                name: 'company-admin-user',
                redirect: 'user/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all/:userRole',
                        name: 'company-admin-user-all',
                        component: AllUsers,
                        props: { default: true, type: 'created' },
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'users'
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'company-admin-user-edit',
                        component: EditUser,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'users'
                        },
                    },
                    {
                        path: 'inactive',
                        name: 'company-admin-user-inactive',
                        component: InactiveUsers,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'inactive_users'
                        },
                    }
                ]
            },
            {
                path: 'invitation',
                name: 'company-admin-invitation',
                redirect: 'invitation/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'company-admin-invitation-all',
                        component: AllInvitations,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'invitations'
                        },
                    },
                ]
            },
            {
                path: 'document',
                name: 'company-admin-document',
                redirect: 'document/downloads/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'downloads/all',
                        name: 'company-admin-document-download-all',
                        component: Downloads,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'downloads'
                        },
                    },
                    {
                        path: 'policies/all',
                        name: 'company-admin-document-policy-all',
                        component: Policies,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'policies'
                        },
                    },
                    {
                        path: 'view/:page/:file/:fileName',
                        name: 'company-admin-document-view',
                        component: ViewDocument,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'document'
                        },
                    },
                ],
            },
            {
                path: 'person',
                name: 'company-admin-persons',
                redirect: 'person/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'company-admin-persons-all',
                        component: AllPersons,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'persons'
                        },
                    },
                    {
                        path: 'create',
                        name: 'company-admin-persons-create',
                        component: CreatePerson,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'persons'
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'company-admin-persons-edit',
                        component: EditPerson,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'persons'
                        },
                    },
                ]
            },
            {
                path: 'location',
                name: 'company-admin-locations',
                redirect: 'location/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'company-admin-locations-all',
                        component: AllLocations,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'locations'
                        },
                    },

                    {
                        path: 'create',
                        name: 'company-admin-locations-create',
                        component: CreateLocation,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'locations'
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'company-admin-location-edit',
                        component: EditLocation,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'locations'
                        },
                    },
                ]
            },
            {
                path: 'directory',
                name: 'company-admin-directory',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'company-admin-directory-all',
                        component: All,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'records'
                        },
                    },
                    {
                        path: 'create/:id',
                        name: 'company-admin-directory-create',
                        component: CreateDirectory,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'records'
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'company-admin-directory-edit',
                        component: EditDirectory,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'records'
                        },
                    },
                    {
                        path: 'view/:id',
                        name: 'company-admin-directory-view',
                        component: ViewDirectory,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'records'
                        },
                    },
                    {
                        path: 'files/:id',
                        name: 'company-admin-directory-files',
                        component: DirectoryFiles,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'records'
                        },
                    },
                ]
            },
            {
                path: 'prefilled-record',
                name: 'company-admin-prefilled-record',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'company-admin-prefilled-record-all',
                        component: UserAllPrefilledRecords,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'prefilled_records'
                        },
                    },
                    {
                        path: 'create/:id',
                        name: 'company-admin-prefilled-record-create',
                        component: UserCreateFromPrefilled,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'prefilled_records'
                        },
                    }
                ]
            },
            {
                path: 'export-import',
                name: 'company-admin-directory-export-import',
                component: ExportImport,
                meta: {
                    middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                    layout: 'main',
                    title: 'export-import'
                },

            },
            {
                path: 'retention-periods',
                name: 'company-admin-retention-periods',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'company-admin-retention-periods-all',
                        component: ALLRetentionPeriods,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'retention_period'
                        },
                    },
                    {
                        path: 'create/:type',
                        name: 'company-admin-retention-periods-create',
                        component: CreateRetentionPeriods,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'retention_period'
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'company-admin-retention-periods-edit',
                        component: EditRetentionPeriods,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'retention_period'
                        },
                    }
                ]
            },
            {
                path: 'bulk-invite',
                name: 'company-admin-bulk-invite',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'imported-list',
                        name: 'company-admin-bulk-invite-imported-list',
                        component: ImportedList,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main',
                            title: 'bulk-invite'
                        },
                    },
                    {
                        path: 'google',
                        name: 'company-admin-bulk-invite-google',
                        component: GoogleAll,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'adminLayout',
                            title: 'gsuite'
                        },
                    },
                    {
                        path: 'microsoft',
                        name: 'company-admin-bulk-invite-microsoft',
                        component: MicrosoftAll,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'adminLayout',
                            title: 'office365'
                        },
                    },
                    {
                        path: 'excel',
                        name: 'company-admin-bulk-invite-excel',
                        component: XLSXAll,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:company_admin,10,11', 'scope:access_all'],
                            layout: 'main'
                        },
                    },
                ]
            }
        ]
    },
    {
        path: '/admin/',
        component: { template: '<router-view/>' },
        children: [
            {
                path: '/',
                redirect: '/admin/home'
            },
            {
                path: 'home',
                name: 'admin-home',
                props: true,
                component: Home,
                meta: {
                    middleware: ['auth', 'role:admin,1,2,3,4,5,6,7,8,9', 'scope:access_all'],
                    layout: 'main',
                    title: 'home'
                },
            },
            {
                path: 'profile/edit',
                name: 'admin-profile-edit',
                redirect: 'profile/edit/account-settings',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'account-settings',
                        name: 'admin-profile-edit-account-settings',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,3,4,5,6,7,8,9', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'privacy-and-security',
                        name: 'admin-profile-edit-privacy-and-security',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,3,4,5,6,7,8,9', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'g-d-p-r-compliance',
                        name: 'admin-profile-edit-g-d-p-r-compliance',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,3,4,5,6,7,8,9', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'company-settings',
                        name: 'admin-profile-edit-company-settings',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,4,5,7,8', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'email-settings',
                        name: 'admin-profile-edit-email-settings',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,4,5,7,8', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'domain-settings',
                        name: 'admin-profile-edit-domain-settings',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,4,5,7,8', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                    {
                        path: 'system-security',
                        name: 'admin-profile-edit-system-security',
                        component: Profile,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,5,8', 'scope:access_all'],
                            layout: 'main',
                            title: 'settings'
                        },
                    },
                ]
            },
            {
                path: 'notifications',
                name: 'admin-notifications',
                component: Notifications,
                meta: {
                    middleware: ['auth', 'role:admin,1,2,3,4,5,6,7,8,9', 'scope:access_all'],
                    layout: 'main',
                    title: 'notifications'
                },
            },
            {
                path: 'translation',
                name: 'admin-translations',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'category',
                        name: 'admin-translation-category',
                        redirect: 'translations/category/all',
                        component: { template: '<router-view/>' },
                        children: [
                            {
                                path: 'all',
                                name: 'admin-translation-category-all',
                                component: AllCategoryTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                            {
                               path: 'edit/:id',
                               name: 'admin-translation-category-edit',
                               component: EditCategoryTranslations,
                               props: true,
                               meta: {
                                   middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                   layout: 'main',
                                   title: 'translations'
                               },
                           },
                            {
                               path: 'single/:id',
                               name: 'admin-translation-category-single',
                               component: SingleCategoryTranslations,
                               props: true,
                               meta: {
                                   middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                   layout: 'main',
                                   title: 'translations'
                               },
                           },
                       ]
                    },
                    {
                        path: 'system',
                        name: 'admin-translation-system',
                        redirect: 'translations/system/all',
                        component: { template: '<router-view/>' },
                        children: [
                            {
                                path: 'all',
                                name: 'admin-translation-system-all',
                                component: AllSystemTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                            {
                                path: 'edit/:id',
                                name: 'admin-translation-system-edit',
                                component: EditSystemTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                            {
                                path: 'single/:id',
                                name: 'admin-translation-system-single',
                                component: SingleSystemTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                        ]
                    },
                    {
                        path: 'risk',
                        name: 'admin-translation-risk',
                        redirect: 'translations/risk/all',
                        component: { template: '<router-view/>' },
                        children: [
                            {
                                path: 'all',
                                name: 'admin-translation-risk-all',
                                component: AllRiskTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                            {
                                path: 'edit/:id',
                                name: 'admin-translation-risk-edit',
                                component: EditRiskTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                            {
                                path: 'single/:id',
                                name: 'admin-translation-risk-single',
                                component: SingleRiskTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                        ]
                    },
                    {
                        path: 'risk-category',
                        name: 'admin-translation-risk-category',
                        redirect: 'translations/risk-category/all',
                        component: { template: '<router-view/>' },
                        children: [
                            {
                                path: 'all',
                                name: 'admin-translation-risk-category-all',
                                component: AllRiskTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                            {
                                path: 'edit/:id',
                                name: 'admin-translation-risk-category-edit',
                                component: EditRiskTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                            {
                                path: 'single/:id',
                                name: 'admin-translation-risk-category-single',
                                component: SingleRiskTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                        ]
                    },
                    {
                        path: 'risk-reducing-measure',
                        name: 'admin-translation-risk-reducing-measure',
                        redirect: 'translations/risk-reducing-measure/all',
                        component: { template: '<router-view/>' },
                        children: [
                            {
                                path: 'all',
                                name: 'admin-translation-risk-reducing-measure-all',
                                component: AllRiskTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                            {
                                path: 'edit/:id',
                                name: 'admin-translation-risk-reducing-measure-edit',
                                component: EditRiskTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                            {
                                path: 'single/:id',
                                name: 'admin-translation-risk-reducing-measure-single',
                                component: SingleRiskTranslations,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'translations'
                                },
                            },
                        ]
                    },
                ]
            },
            {
                path: 'records',
                name: 'admin-records',
                redirect: 'records/prefilled/all',
                component: {template: '<router-view/>'},
                children: [
                    {
                        path: 'suggested',
                        redirect: 'records/suggested/all',
                        name: 'admin-record-suggested',
                        component: { template: '<router-view/>' },
                        children: [
                            {
                                path: 'all',
                                name: 'admin-record-suggested-all',
                                component: AllSuggestedRecords,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'records'
                                },
                            },
                            {
                                path: 'edit/:id',
                                name: 'admin-record-suggested-edit',
                                component: EditSuggestedRecord,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'records'
                                },
                            }
                        ]

                    },
                    {
                        path: 'prefilled',
                        name: 'admin-prefilled-record',
                        redirect: 'records/prefilled/all',
                        component: { template: '<router-view/>' },
                        children: [
                            {
                                path: 'all',
                                name: 'admin-prefilled-record-all',
                                component: AllPrefilledRecords,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'prefilled_records'
                                },
                            },
                            {
                                path: 'edit/:id',
                                name: 'admin-prefilled-record-edit',
                                component: EditPrefilledRecord,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'prefilled_records'
                                },
                            },
                            {
                                path: 'create/:id',
                                name: 'admin-record-prefilled-create',
                                component: CreatePrefilledRecord,
                                props: true,
                                meta: {
                                    middleware: ['auth', 'role:admin,1,2', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'prefilled_records'
                                },
                            },
                            {
                                path: 'translation',
                                name: 'admin-prefilled-record-translation',
                                component: { template: '<router-view/>' },
                                children: [
                                    {
                                        path: 'all',
                                        name: 'admin-prefilled-record-translation-all',
                                        component: AllPrefilledRecordsTranslations,
                                        props: true,
                                        meta: {
                                            middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                            layout: 'main',
                                            title: 'translations'
                                        },
                                    },
                                    {
                                        path: 'edit/:id',
                                        name: 'admin-prefilled-record-translation-edit',
                                        component: EditPrefilledRecordsTranslations,
                                        props: true,
                                        meta: {
                                            middleware: ['auth', 'role:admin,1,2,3', 'scope:access_all'],
                                            layout: 'main',
                                            title: 'translations'
                                        },
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                path: 'category',
                name: 'admin-categories',
                redirect: 'category/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'admin-categories-all',
                        component: AllCategories,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2', 'scope:access_all'],
                            layout: 'main',
                            title: 'categories'
                        },
                    },
                ]
            },
            {
                path: 'user',
                name: 'admin-user',
                redirect: 'user/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all/:userRole',
                        name: 'admin-user-all',
                        component: AllUsers,
                        props: { default: true, type: 'created' },
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,4,5,7,8', 'scope:access_all'],
                            layout: 'main',
                            title: 'users'
                        },
                    },
                    // {
                    //     path: 'all',
                    //     name: 'admin-user-all',
                    //     component: AllUsers,
                    //     props: { default: true, type: 'created' },
                    //     meta: {
                    //         middleware: ['auth', 'role:admin,1,2,4,5,7,8', 'scope:access_all'],
                    //         layout: 'main',
                    //         title: 'users'
                    //     },
                    // },
                    {
                        path: 'edit/:id',
                        name: 'admin-user-edit',
                        component: EditUser,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,4,5,7,8', 'scope:access_all'],
                            layout: 'main',
                            title: 'users'
                        },
                    },
                    {
                        path: 'inactive',
                        name: 'admin-user-inactive',
                        component: InactiveUsers,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,4,5,7,8', 'scope:access_all'],
                            layout: 'main',
                            title: 'inactive_users'
                        },
                    }
                ]
            },
            {
                path: 'invitation',
                name: 'admin-invitation',
                redirect: 'invitation/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'admin-invitation-all',
                        component: AllInvitations,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,4,5,7,8', 'scope:access_all'],
                            layout: 'main',
                            title: 'invitations'
                        },
                    },
                ]
            },
            {
                path: 'company',
                name: 'admin-companies',
                redirect: 'company/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'admin-company-all',
                        component: AllCompanies,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,4,5,7,8'],
                            layout: 'main',
                            title: 'companies'
                        },
                    },
                    {
                        path: 'create',
                        name: 'admin-company-create',
                        component: CreateCompany,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,4,5,7,8'],
                            layout: 'main',
                            title: 'companies'
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'admin-company-edit',
                        component: EditCompany,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,4,5,7,8'],
                            layout: 'main',
                            title: 'companies'
                        },
                    },
                ]
            },
            {
                path: 'dpo',
                name: 'admin-dpos',
                redirect: 'dpo/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'admin-dpo-all',
                        component: AllDpos,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,4,5,7,8'],
                            layout: 'main',
                            title: 'dpo'
                        },
                    },
                    {
                        path: 'create',
                        name: 'admin-dpo-create',
                        component: CreateDpo,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,4,5,7,8'],
                            layout: 'main',
                            title: 'dpo'
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'admin-dpo-edit',
                        component: EditDpo,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,4,5,7,8'],
                            layout: 'main',
                            title: 'dpo'
                        },
                    },
                ]
            },


            {
                path: 'risk-category',
                name: 'admin-risk-categories',
                redirect: 'risk-category/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'admin-risk-categories-all',
                        component: AllRiskCategories,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2'],
                            layout: 'main',
                            title: 'risks'
                        },
                    },
                ]
            },
            {
                path: 'risks',
                name: 'admin-risks',
                redirect: 'risks/all',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'admin-risks-all',
                        component: AllRisks,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2'],
                            layout: 'main',
                            title: 'risks'
                        },
                    },
                    {
                        path: 'reducing/:id/measure',
                        name: 'admin-risks-reducing-measure',
                        component: AllReducingMeasure,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2'],
                            layout: 'main',
                            title: 'risks'
                        },
                    },
                ]
            },
            {
                path: 'login-as',
                name: 'admin-login',
                redirect: 'login-as/login',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'login',
                        name: 'admin-login-as',
                        component: LoginAs,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,4,5,7,8'],
                            layout: 'main',
                            title: 'login_as'
                        },
                    },
                    {
                        path: 'login-history',
                        name: 'admin-login-history',
                        component: AllHistory,
                        props: true,
                        meta: {
                            middleware: ['auth', 'role:admin,1,2,4,5,7,8'],
                            layout: 'main',
                            title: 'login_as'
                        },
                    },
                ]
            },
            {
                path: 'subscription-partner',
                name: 'subscription-partner',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'subscription-partner-all',
                        component: AllPartners,
                        props: { partner: 'subscription_partner' },
                        meta: {
                            middleware: ['auth', 'role:admin,1', 'scope:access_all'],
                            layout: 'main',
                            title: 'subscription_partners'
                        },
                    },
                    {
                        path: 'add',
                        name: 'subscription-partner-add',
                        component: AddPartner,
                        props: { partner: 'subscription_partner' },
                        meta: {
                            middleware: ['auth', 'role:admin,1', 'scope:access_all'],
                            layout: 'main',
                            title: 'subscription_partners'
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'subscription-partner-edit',
                        component: EditPartner,
                        props: { partner: 'subscription_partner' },
                        meta: {
                            middleware: ['auth', 'role:admin,1', 'scope:access_all'],
                            layout: 'main',
                            title: 'subscription_partners'
                        },
                    },
                ]
            },
            {
                path: 'franchising-partner',
                name: 'franchising-partner',
                component: { template: '<router-view/>' },
                children: [
                    {
                        path: 'all',
                        name: 'franchising-partner-all',
                        component: AllPartners,
                        props: { partner: 'franchise_partner' },
                        meta: {
                            middleware: ['auth', 'role:admin,1', 'scope:access_all'],
                            layout: 'main',
                            title: 'franchising_partners'
                        },
                    },
                    {
                        path: 'add',
                        name: 'franchising-partner-add',
                        component: AddPartner,
                        props: { partner: 'franchise_partner' },
                        meta: {
                            middleware: ['auth', 'role:admin,1', 'scope:access_all'],
                            layout: 'main',
                            title: 'franchising_partners'
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'franchising-partner-edit',
                        component: EditPartner,
                        props: { partner: 'franchise_partner' },
                        meta: {
                            middleware: ['auth', 'role:admin,1', 'scope:access_all'],
                            layout: 'main',
                            title: 'franchising_partners'
                        },
                    },
                    /*{
                        path: 'system-admin',
                        name: 'system-admin',
                        component: { template: '<router-view/>' },
                        children: [
                            {
                                path: 'all',
                                name: 'system-admin-all',
                                component: SystemAdmin,
                                props: { partner: 'franchise_partner' },
                                meta: {
                                    middleware: ['auth', 'role:admin,1', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'franchising_partners'
                                },
                            },
                            {
                                path: 'edit/:id',
                                name: 'system-admin-edit',
                                component: EditSystemAdmin,
                                props: { partner: 'franchise_partner' },
                                meta: {
                                    middleware: ['auth', 'role:admin,1', 'scope:access_all'],
                                    layout: 'main',
                                    title: 'franchising_partners'
                                },
                            },

                        ]
                    },*/
                    {
                        path: 'billing-details',
                        name: 'billing-details',
                        component: EditBilling,
                        props: { partner: 'franchise_partner' },
                        meta: {
                            middleware: ['auth', 'role:admin,1', 'scope:access_all'],
                            layout: 'main'
                        },
                    },
                    {
                        path: 'invoice-history',
                        name: 'invoice-history',
                        component: InvoiceHistory,
                        props: { partner: 'franchise_partner' },
                        meta: {
                            middleware: ['auth', 'role:admin,1', 'scope:access_all'],
                            layout: 'main'
                        },
                    }
                ]
            },
        ]
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        meta: {
            middleware: 'auth',
            layout: 'main',
            title: 'notifications'
        },
    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound,
        props: { page: 'notFound' },
        meta: {
            layout: 'emptyLayout'
        }
    },
    {
        path: '/403',
        name: 'access-forbidden',
        component: NotFound,
        props: { page: 'accessForbidden' },
        meta: {
            layout: 'emptyLayout'
        }
    },
    {
        path: '*',
        name: 'not-found-default',
        redirect: '/404',
        component: { template: '404' },
    }
]
