
<template>
<b-modal ref="pdfViewer"
         modal-class="pdf_viewer"
         :hide-footer="true"
         :hide-header="true"
>
        <div class="pdf-container">
            <div class="pdf-header">
                <div>
                    {{ this.page }} / {{ this.numPages }}
                </div>
                <div id="buttons" class="ui grey three item inverted bottom fixed menu transition hidden">
                    <a class="item item-minus" @click="scale -= scale > 0.2 ? 0.1 : 0">
                        <i class="fa fa-minus"></i>
                    </a>
                    <a class="ui active">
                        {{ formattedZoom }} %
                    </a>
                    <a class="item item-plus" @click="scale += scale < 2 ? 0.1 : 0">
                        <i class="fa fa-plus"></i>
                    </a>
                </div>
                <div @click="closeModal()" class="pdf_icon">
                    <span v-html="svg('close-delete')"></span>
                </div>
            </div>
            <div id="pdfvuer" class="pdf-file-wrapper">
                <div class="pdf-file-wrapper_child">
                    <pdf class="pdf-file" :src="src" v-for="i in numPages" :key="i" :id="i" :page="i"
                         :scale.sync="scale" style="width:100%;margin:40px auto;">
                        <template slot="loading">
                            loading content here...
                        </template>
                    </pdf>
                </div>
            </div>
        </div>

</b-modal>
</template>

<script>
    import pdfvuer from 'pdfvuer'
    import DirectoryService  from "~/services/DirectoryService";


    export default {
        name: "pdfView",

        components: {
            pdf: pdfvuer
        },

        props: [
            'id'
        ],

        data(){
            return {
                page: 1,
                numPages: 1,
                pdfData: undefined,
                errors: [],
                scale: 1,

                DirectoryService:  new DirectoryService(),
                directoryName: "",
                src: null,
                showPdf: false
            }
        },

        created(){
            // this.fetchData();
            // this.loadPdf();
        },

        mounted() {
            document.querySelector('body').addEventListener("keydown",  (e) => {
                if(e.ctrlKey && e.key === 'p'){
                    e.preventDefault();
                }
            });
        },

        computed: {
            formattedZoom () {
                return Number.parseInt(this.scale * 100);
            },
        },

        methods: {
            async fetchData(){
                this.$loader.withoutSidebarLoader(true);
                try{
                    //get user directory data
                    let directoryData = await this.DirectoryService.getUserDirectory(this.id);
                    this.directoryName = directoryData.name;
                    this.directoryId = directoryData.directory_id;
                    // this.loadPdf();
                    //get  pdf html
                    let html = await this.DirectoryService.getDirectoryHtml(this.id);
                    let {data}= await this.DirectoryService.getPdfResult(html);
                    const blob = new Blob([data], { type: 'application/pdf' });
                    this.src = window.URL.createObjectURL(blob);
                    this.showPdf = true;
                }catch (e) {
                }

                this.$loader.withoutSidebarLoader(false);
                this.loadPdf();
                this.$refs.pdfViewer.show();
            },

            show() {
                this.fetchData();
            },

            closeModal() {
                this.$refs.pdfViewer.hide();
                this.page = 1
            },

            async loadPdf(){
                let self = this;
                self.pdfData = pdfvuer.createLoadingTask(self.src);
                self.pdfData.then(pdf => {
                    self.numPages = pdf.numPages;
                    document.getElementById('pdfvuer').onscroll = function(evt) {
                        const pageNumber = getCurrentPageNumber(evt);
                        self.page = pageNumber;
                    }

                    // Get the offset position of the navbar
                    var sticky = document.getElementById('buttons').offsetTop;


                    function getCurrentPageNumber(evt) {
                        const container = evt.target;
                        const contChildren = container.getElementsByClassName('page');
                        const contHalfHeight = getContainerHalfHeight(container);
                        const contTopDistance = container.getBoundingClientRect().top;

                        for(let i = contChildren.length - 1, j = 0; i >= 0; --i, ++j) {
                            const elProps = contChildren[i].getBoundingClientRect();
                            const pageBottom = elProps.bottom - contTopDistance;
                            if (pageBottom < contHalfHeight) {
                                return contChildren.length - j + 1;
                            }
                        }
                        return 1;

                    }

                    function getContainerHalfHeight(container) {
                        const headerHeigth = 54;
                        const contHeight = container.getBoundingClientRect().height - headerHeigth;
                        return contHeight / 2;
                    }
                });
            },

            findPos(obj) {
                return obj.offsetTop;
            },
        },
    }
</script>

<style lang="scss">
    .pdf_viewer {
        .modal-dialog {
            max-width: 100%;
            margin: 0;
            .modal-content {
                background-color: transparent;
                margin-top: 0;
                .modal-body {
                    padding: 0;
                    .pdf_icon {
                        width: 32px;
                        height: 32px;
                        cursor: pointer;
                        svg {
                            width: 16px;
                            height: 16px;
                            path {
                                fill: #fff;
                            }
                        }
                        &:hover {
                            background: rgba(244, 245, 248, 0.2);
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }
    .pdf-container {
        #viewerContainer {
            position: relative !important;
            display: flex;
            justify-content: center;
        }
    }

</style>


<style scoped lang="scss">
    .pdf-container{
        position: relative;
        width: 100%;
        background: rgba(44, 47, 51, 0.8);
        max-height: 100vh;
        display: flex;
        justify-content: center;

        .pdf-file-wrapper{
            width: 100%;
            margin-top: 60px;
            overflow: scroll;

            .pdf-file{
                margin: 0 auto;
                max-width: 90%;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba(255, 255, 255, 0.5);
                border-radius: 6px;
                height: 300px;
            }
            &::-webkit-scrollbar {
                width: 8px;
            }
        }


        .pdf-header{
            display: flex;
            justify-content: space-between;
            padding: 15px 30px;
            position:absolute;
            top:0;
            left: 0;
            width: 100%;
            background-color: #323639;
            color: #ffffff;
        }

        .item{
            &:hover{
                cursor:pointer;
            }

            &-minus{
                display: inline-block;
                margin-right: 5px;
                margin-top: 3px;
            }

            &-plus{
                display: inline-block;
                margin-left: 5px;
            }
        }
    }
</style>
