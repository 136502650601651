    <template>
    <b-modal
            id="login-access-requested"
            modal-class="modal_area"
            :hide-footer="true"
            no-close-on-backdrop
        >
            <div class="col-md-10 m-auto">
                <div class="modal-header py-0">
                    <h4 class="mt-0">{{ $t('access_request') }}</h4>
                </div>
                <div class="modal-body pt-0">
                    <hr />
                    <p>{{ $t('access_requested_from_administration') }}</p>
                </div>
                <div class="modal-footer mt-4">
                    <button class="main-button main-button__disabled" id="reject" @click="reject">{{ $t('reject') }}</button>
                    <button class="main-button main-button__save" @click="accept" id="accept">{{ $t('accept') }}</button>
                </div>
            </div>
        </b-modal>
</template>

<script>
import {mapGetters}     from "vuex";
import LoginAsService   from "~/services/LoginAsService";

export default {
    name: 'AccessRequested',

    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
    },

    data(){
        return{
            LoginAsService: new LoginAsService(),
            loginAsId: null,
        }
    },

    mounted(){
        this.$store.getters['echo/connectionInstance'].private('user_' + this.user.id)
            .listen('.access-requested', async (e) => {
                this.$bvModal.show('login-access-requested');
                this.loginAsId = e.data.loginAsId;

                setTimeout(() => {
                    this.$bvModal.hide('login-access-requested');
                    }, 5*60*1000);
            });
    },

    methods: {
        reject: async function(event){
            this.$loader.cancelButtonLoader(event.target.id, true);

            let [result, error] = await this.LoginAsService.rejectAccess(this.loginAsId);

            this.$loader.cancelButtonLoader(event.target.id, false);

            if(result && result == 1){
                this.$bvModal.hide('login-access-requested');
                this.$toast.success(this.$t('access_request_rejected'));
            }else{
                this.$toast.error(this.$t('something_went_wrong'));
            }

        },

        accept: async function(event){
            this.$loader.buttonLoader(event.target.id, true);

            let [result, error] = await this.LoginAsService.grantAccess(this.loginAsId);

            this.$loader.buttonLoader(event.target.id, false);

            if(result && result == 1){
                this.$bvModal.hide('login-access-requested');
                this.$toast.success(this.$t('access_request_accepted'));
            }else{
                this.$toast.error(this.$t('something_went_wrong'));
            }
        },
    },
}
</script>
<style>

</style>

