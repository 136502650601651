import HttpService from "./HttpService";

export default class AdminService extends HttpService{

    /**
     * Login to users account as emergency
     *
     * @param data
     * @returns {Promise<any>}
     */
    emergencyLogin(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('login-as/emergency-login', params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }
    /**
     * Request access to login
     *
     * @param data
     * @returns {Promise<any>}
     */
    requestAccess(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('login-as/request-access', params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => result.data).catch(err => err)
    }

    /**
     * Request access to login
     *
     * @param data
     * @returns {Promise<any>}
     */
    rejectAccess(id, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('login-as/reject-access/' + id, params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Request access to login
     *
     * @param data
     * @returns {Promise<any>}
     */
    grantAccess(id, params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.post('login-as/grant-access/' + id, params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => [result.data, null]).catch(err => [null, err])
    }

    /**
     * Request access to login
     *
     * @param data
     * @returns {Promise<any>}
     */
    getLoginAsList(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.get('login-as/list', params, headers);
                resolve(result)
            } catch(error) {
                reject(error)
            }

        }).then(result => result.data).catch(err => err)
    }
}
