<template>
    <div class="main newStyle" v-if="user">
        <Sidebar
            :mobileIsOpened="mobileIsOpened"
            @close-menu="closeMenu"
        />

        <div class="content">
            <Navbar
                :mobileIsOpened="mobileIsOpened"
                @mobile-menu="mobileMenu"
            />
            <div class="main-content" :class="{'info' : !info}">

                <subscription-info v-if="(user.type === 'company_admin' || user.role === 'user') && $route.meta.title !== 'settings'" @sub-info="subInfo"/>
                <slot/>
            </div>
        </div>

        <AccessRequested/>
        <NewRoleAssigned/>
        <SubscriptionOverdo/>

      <!-- Payment succeeded modal -->
      <b-modal
          id="payment-success"
          ref="payment-success"
          :hide-header="true"
          modal-class="modal_area payment-success"
          :hide-footer="true"
          no-close-on-backdrop
      >
        <div>
          <div class="modal-body pt-0">
            <span v-html="svg('succeeded-payment')"></span>
            <h4 class="mt-0">{{ $t('payment_succeeded') }}</h4>
              <hr class="payment_line">
            <p>{{ $t('payment_succeeded_info') }}</p>
          </div>
          <div class="payment_button">
            <button class="main-button main-button__save" @click="closeModal">{{ $t('close') }}</button>
          </div>
        </div>
      </b-modal>

      <!-- Payment failed modal -->
      <b-modal
          id="payment-failed"
          ref="payment-failed"
          modal-class="modal_area payment-success"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
      >
        <div>
          <div class="modal-body pt-0">
            <span v-html="svg('succeeded-failed')"></span>
            <h4 class="mt-0">{{ $t('sorry') }}</h4>
            <p>{{ $t('failed_info') }}</p>
          </div>
          <div class="payment_button">
            <button class="main-button main-button__save" @click="$bvModal.hide('payment-failed')">{{ $t('ok') }}</button>
          </div>
        </div>
      </b-modal>

      <!-- Payment details have been updated modal -->
      <b-modal
          id="payment-details-updated"
          ref="payment-details-updated"
          modal-class="modal_area payment-success"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
      >
        <div>
          <div class="modal-body pt-0">
            <span v-html="svg('succeeded-payment')"></span>
            <h4 class="mt-0">{{ $t('payment_details_updated') }}</h4>
            <!--            <p>{{ $t('payment_succeeded_info') }}</p>-->
          </div>
          <div class="payment_button">
            <button class="main-button main-button__save" @click="$refs['payment-details-updated'].hide()">{{ $t('ok') }}</button>
          </div>
        </div>
      </b-modal>

    </div>
</template>

<script>
    import Navbar from "~/components/Navbar";
    import Sidebar from "~/components/Sidebar";
    import AccessRequested from "~/components/loginAs/AccessRequested";
    import NewRoleAssigned from '~/components/loginAs/NewRoleAssigned';
    import SubscriptionOverdo from "~/components/subscriptionAndBilling/SubscriptionOverdo";
    import * as types from '~/store/mutation-types';
    import {mapGetters} from "vuex";
    import SubscriptionInfo from "../components/ui/SubscriptionInfo";

    export default {
        name: 'MainLayout',
        data: function () {
            return {
                mobileIsOpened: false,
                info: '',
                userScopes: []
            }
        },
        components: {
            SubscriptionInfo,
            Sidebar,
            Navbar,
            AccessRequested,
            NewRoleAssigned,
            SubscriptionOverdo
        },

        computed: {
            ...mapGetters({
                user: 'auth/user',
            }),
        },

        created() {
            this.userScopes = [...this.user.scopes];
            this.$store.getters['echo/connectionInstance'].join('active_online');
            this.$store.getters['echo/connectionInstance'].private('user_' + this.user.id)
                .listen('.logout', async (e) => {
                    if (this.$store.getters['auth/affectOnLogout']) {
                        await this.$store.dispatch('auth/logout');
                        this.$router.push({name: 'auth-login'});
                        this.$store.commit('auth/' + types.SET_AFFECT_ON_LOGOUT, {affectOnLogout: true})
                    }
                });

            this.$store.getters['echo/connectionInstance'].private('user_' + this.user.id)
                .listen('.user.logout', async (e) => {
                    // logout user
                    await this.$store.dispatch('auth/getUser');
                })
                .listen('.update_payment_method.success', async (e) => {
                    this.$bvModal.show('payment-details-updated');
                    await this.$store.dispatch('auth/getUser');
                })
                .listen('.subscription.created', async (e) => {
                    if (e.subscription.stripe_status === 'active') {
                        this.$bvModal.show('payment-success');
                        this.$bvModal.hide('change-plan');
                        this.$loader.buttonLoader('retry-payment', false);
                        await this.$store.dispatch('auth/getUser');
                    }
                })
                .listen('.subscription.updated', async (e) => {
                    if (e.subscription.stripe_status === 'active' && e.subscription.previous_status !== 'active') {
                        this.$bvModal.show('payment-success');
                        this.$bvModal.hide('change-plan');
                        this.$loader.buttonLoader('retry-payment', false);
                        await this.$store.dispatch('auth/getUser');
                    }
                })
                .listen('.payment.failed', async (e) => {
                    this.$bvModal.show('payment-failed');
                    this.$bvModal.hide('change-plan');
                    this.$loader.buttonLoader('retry-payment', false);
                });
        },

        methods: {

            subInfo: function(log) {
                this.info = log
                console.log(log)
            },

            mobileMenu: function () {
                this.mobileIsOpened = true;
            },

            closeMenu: function () {
                this.mobileIsOpened = false;
            },

            closeModal: function () {
                this.$bvModal.hide('payment-success');
                this.$bvModal.hide('payment-failed');
                this.redirectAccordingToScope(this.userScopes);
            },
        },

        watch: {
            '$route'() {
                this.mobileIsOpened = false;
            }
        }
    }
</script>
<style lang="scss">
    .payment_button {
        margin-bottom: 45px;

        button {
            height: 40px;
            min-width: auto;
            padding: 0 47px;
        }
    }
    .payment_line {
        width: 40px;
        border: 1px solid #27AEDE;
        margin: 5px auto 30px;
    }

    /*.info {*/
    /*    height: calc(100% - 233px) !important*/
    /*}*/


</style>
