import * as types from '~/store/mutation-types';

// state
export const state = {
    currentPlan: null,
    subscriptionDetails: null,
    subscription: {},
};

// getters
export const getters = {
    currentPlan: state => state.currentPlan,
    subscriptionDetails: state => state.subscriptionDetails,
    subscription: state => state.subscription
};

// mutations
export const mutations = {
    [types.SET_CURRENT_PLAN] : (state, data) => { state.currentPlan = data.currentPlan},
    [types.SET_SUBSCRIPTION_DETAILS] : (state, data) => { state.subscriptionDetails = data.subscriptionDetails},
    [types.SET_SUBSCRIPTION] : (state, payload) => { state.subscription = payload}
};


export const actions = {
     clearSubscriptionData ({commit}) {
        state.currentPlan = null;
        state.subscriptionDetails = null;
    },
    setSubscription ({commit}, payload) {
        commit(types.SET_SUBSCRIPTION, payload);
    },
}
