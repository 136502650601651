import HttpService from "./HttpService";

export default class SystemService extends HttpService{

    /**
     * Get System Translations
     *
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getServerTimezone(params = {}, headers = {}){

        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('system/timezone', params, headers, {}, false);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(partners => partners.data).catch(err => err)
    }

    /**
     * Get language translations
     *
     * @param lang
     * @param params
     * @param headers
     * @returns {Promise<unknown>}
     */
    getLanguageTranslations(lang, params = {}, headers = {}){
        return new Promise(async (resolve, reject) => {
            try {
                let data = await this.get('system/get-translations/' + lang, params, headers, {}, false);
                resolve(data)
            } catch(error) {
                reject(error)
            }

        }).then(partners => partners.data).catch(err => err)
    }
}
