<template>
    <div class="container-fluid parent_content login">
        <div class="m-0 d-flex h-100 overflow-hidden">
            <div class="w-50 img_persons">
                <div class="img_dgd d-flex align-items-center">
                    <div class="login-img-wrapp">
                        <img src="/images/AbleToRecords_Logo_white.svg" class="center" alt="logo_white">
                        <p class="branding-info__desc">{{ $t('registration_subtitle') }}</p>
                    </div>
                </div>
            </div>
            <div class="general_page d-flex align-items-center">
                <div class="col h-100 three_areas d-flex flex-column px-0">
                    <slot/>
                    <auth-footer/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AuthFooter from "~/components/auth/Footer";

    export default {
        name: 'AuthLayout',

        components: {
            AuthFooter
        }
    }
</script>

<style scoped lang="scss">
    @import "@/abstracts/_mixins";

    .img_persons {
        @include media(1000) {
            display: none;
        }
    }

    .general_page {
        width: 50%;
        @include media(1000) {
            width: 100%;
        }
    }
</style>
